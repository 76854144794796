import { IonIcon, IonInput } from '@ionic/react';
import { useUncontrolled } from '@mantine/hooks';
import classNames from 'classnames';

import styles from './pf-input.module.css';

// *** Use React.ComponentProps to get the type of the props ***
type IonInputProps = React.ComponentProps<typeof IonInput>;

/* eslint-disable-next-line */
export interface PfInputProps extends Omit<IonInputProps, 'onChange'> {
  icon?: string;
  isTouched?: boolean;
  onChange?: (value: string | number) => void;
  height?: 'small' | 'large';
}

export function PfInput(props: PfInputProps) {
  const {
    icon,
    isTouched,
    onChange,
    value,
    defaultValue,
    className,
    ...inputProps
  } = props;

  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue: defaultValue as typeof value,
    onChange,
  });

  const isValid = inputProps.errorText === undefined;

  return (
    <div className={styles['input-wrapper']}>
      {props.icon && (
        <div className={styles['input-wrapper__icon']}>
          <IonIcon
            color="light"
            icon={icon}
            size="large"
            slot="start"
          ></IonIcon>
        </div>
      )}
      <IonInput
        className={classNames(
          className,
          { [styles['ion-input--small']]: props.height === 'small' },
          {
            'ion-invalid': isValid === false,
            'ion-touched': isTouched,
          },
          {
            [styles['input--floating']]:
              props.labelPlacement === 'floating' && !props.icon,
          },
          { [styles['input--stacked']]: props.labelPlacement === 'stacked' },
          {
            [styles['ion-input--error']]: props.errorText,
          }
        )}
        style={{
          '--border-radius': props.icon ? '0px' : undefined,
          '--border-color': `var(--ion-color-${
            props.errorText ? 'danger' : 'primary'
          })`,
          minHeight: props.height === 'small' ? 40 : undefined,
        }}
        value={_value}
        onIonInput={(e) => {
          handleChange(e.detail.value || '');
        }}
        {...inputProps}
      ></IonInput>
    </div>
  );
}

export default PfInput;
