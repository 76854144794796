import { useAuth } from '@lidea/shared/data-access/core';
import { useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import styles from './protected-route.module.css';

/* eslint-disable-next-line */
export interface ProtectedRouteProps extends RouteProps {
  children?: React.ReactNode;
  handleProtection?: () => void;
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { token, setRedirectUrl } = useAuth();
  const { children, ...rest } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    if (props.handleProtection && !token) {
      props.handleProtection();
    } else if (!token) {
      setRedirectUrl(pathname);
    }
  }, [token, setRedirectUrl, rest.path, props, pathname]);

  if (props.handleProtection) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
