import { bbox } from '@turf/turf';
import { Map } from 'mapbox-gl';

type Params = {
  geojson?: any;
  map?: Map;
  animate?: boolean;
};

export const zoomOnFeature = (params: Params) => {
  const { animate = true } = params;
  if (params.geojson && params.map) {
    const [x1, y1, x2, y2] = bbox(params.geojson);

    params.map.fitBounds([x1, y1, x2, y2], { padding: 50, animate });
  }
};
