import { MeasureFormValues } from '@lidea/analysis/desktop/shared/ui';
import {
  useMeasures,
  useUpdateMeasure,
  useZoning,
} from '@lidea/shared/data-access/analysis';
import { usePlot } from '@lidea/shared/data-access/plots';
import { ZoningStatus } from '@lidea/shared/util/types';
import { useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMeasuresPageProps {
  plotId: number;
  analysisId: number;
}

export function useMeasuresPage(props: UseMeasuresPageProps) {
  const plot = usePlot(Number(props.plotId));
  const updateMeasure = useUpdateMeasure({
    analysisId: props.analysisId,
    plotId: props.plotId,
  });
  const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
  const zoning = useZoning({ analysisId: props.analysisId, enabled: true });
  const measures = useMeasures({ analysisId: props.analysisId });

  const pointsReady = useMemo(() => {
    return (
      zoning.data?.points?.features
        .map((feature) => {
          return feature.properties.status === ZoningStatus.Ready;
        })
        .filter((statusBool) => statusBool === false).length === 0
    );
  }, [zoning.data]);

  const selectedMeasure = useMemo(() => {
    if (selectedPoint && measures.data) {
      return Object.values(measures.data).find(
        (measure) => measure.point.id === selectedPoint
      );
    }
  }, [measures.data, selectedPoint]);

  const handleSubmitMeasures = (data: MeasureFormValues) => {
    // only send valid value
    const validValues = Object.keys(data).reduce((acc, key) => {
      if (data[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {} as MeasureFormValues);

    if (selectedMeasure) {
      updateMeasure.mutate({
        id: selectedMeasure.id,
        data: validValues,
      });
    }
    if (updateMeasure.isError || updateMeasure.isPaused) {
      return selectedPoint;
    }
  };

  const handlePointClick = (id: number) => {
    setSelectedPoint(id);
  };

  return {
    plot,
    zoning,
    measures,
    updateMeasure,
    selectedPoint,
    setSelectedPoint,
    selectedMeasure,
    handleSubmitMeasures,
    handlePointClick,
    pointsReady,
  };
}

export default useMeasuresPage;
