import { PfInput } from '@lidea/shared/ui/form';
import { UseFormReturnType } from '@mantine/form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MeasureFormValues } from '../measure-form/measure-form';
import styles from './measures-inputs-list.module.css';

type Field = {
  label?: {
    fr: string;
    en: string;
  };
  name: string;
  value: number | string | null;
};
/* eslint-disable-next-line */
export interface MeasuresInputsListProps {
  labelPlacement: 'stacked' | 'floating';
  form: UseFormReturnType<MeasureFormValues>;
  fieldsLabel?: string;
  fields: Field[];
}

export function MeasuresInputsList(props: MeasuresInputsListProps) {
  const { t, i18n } = useTranslation(['analysis'], { useSuspense: false });

  const label = (field: Field, index: number) => {
    if (field.label) {
      return field.label[i18n.language as 'en' | 'fr'];
    } else if (props.fieldsLabel) {
      return `${props.fieldsLabel}`;
    } else {
      return t('analysis:field_label');
    }
  };
  return (
    <div
      className={classNames(styles['container'], {
        [styles['no-gap']]: props.labelPlacement === 'stacked',
      })}
    >
      {props.fields.map((field, index) => (
        <PfInput
          key={index}
          color="primary"
          fill="outline"
          height="small"
          isTouched={props.form.isTouched(field.name)}
          label={label(field, index)}
          labelPlacement={props.labelPlacement}
          step="any"
          type="number"
          {...props.form.getInputProps(field.name)}
        />
      ))}
    </div>
  );
}

export default MeasuresInputsList;
