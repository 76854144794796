import { Filters } from '@lidea/shared/util/types';

import { SelectedFilters } from '../filters-store/filters-store';
import { cleanFilters } from '../utils/clean-filters/clean-filters';
import { privateClient } from './api-client';

export const filtersAPI = {
  getAll: (filters: SelectedFilters) => {
    const cleanedFilters = cleanFilters(filters);

    return privateClient
      .get('filter', {
        searchParams: cleanedFilters,
      })
      .json<Filters>();
  },
};
