import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from './delete-alert.module.css';

/* eslint-disable-next-line */
export interface DeleteAlertProps {
  isOpen: boolean;
  header?: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function DeleteAlert(props: DeleteAlertProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  return (
    <IonAlert
      backdropDismiss={false}
      buttons={[
        {
          text: t('common:cancel'),
          role: 'cancel',
          handler: props.onCancel,
        },
        {
          text: t('common:confirm'),
          role: 'confirm',
          cssClass: styles['delete-dialog__confirm-button'],
          handler: props.onConfirm,
        },
      ]}
      header={props.header || (t('common:warning') as string)}
      isOpen={props.isOpen}
      message={props.message || (t('common:alert_delete_message') as string)}
    ></IonAlert>
  );
}

export default DeleteAlert;
