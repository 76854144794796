import { Layer, Source } from 'react-map-gl';

import styles from './cluster-layer.module.css';

/* eslint-disable-next-line */
export interface ClusterLayerProps {
  data: GeoJSON.FeatureCollection<GeoJSON.Point>;
  clustersMaxZoom?: number;
  pointsMaxZoom?: number;
  layerId?: string;
  clusterColor?: string;
}

export function ClusterLayer(props: ClusterLayerProps) {
  return (
    <Source
      data={props.data}
      id="cluster-data"
      type="geojson"
      // cluster
    >
      <Layer
        filter={['has', 'count']}
        id={props.layerId}
        maxzoom={props.clustersMaxZoom || 6}
        paint={{
          'circle-color': props.clusterColor,
          'circle-opacity': 1,

          'circle-radius': ['min', ['+', 10, ['number', ['get', 'count']]], 25],
        }}
        type="circle"
      />
      <Layer
        filter={['!', ['has', 'count']]}
        id={`${props.layerId}_points`}
        maxzoom={props.pointsMaxZoom || 9}
        paint={{
          'circle-color': props.clusterColor,
          'circle-radius': 8,
        }}
        type="circle"
      />
    </Source>
  );
}

export default ClusterLayer;
