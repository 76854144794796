import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useComments, useCreateComment } from '@lidea/shared/data-access/core';
import { PfInput } from '@lidea/shared/ui/form';
import dayjs from 'dayjs';
import { close } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './comments-modal.module.css';

/* eslint-disable-next-line */
export interface CommentsModalProps {
  plotId?: number;
  analysisId?: number;
  dismiss: (data: any, role: string) => void;
}

export function CommentsModal(props: CommentsModalProps) {
  const { t } = useTranslation(['plot'], { useSuspense: false });
  const [mode, setMode] = useState<'add' | 'display'>('display');
  const [nextComment, setNextComment] = useState('');
  const [nextTitle, setNextTitle] = useState('');

  const comments = useComments({
    plotId: props.plotId,
    analysisId: props.analysisId,
  });
  const createComment = useCreateComment();

  const handleClick = () => {
    if (mode === 'add' && nextComment.length > 0) {
      createComment.mutate({
        plotId: props.plotId,
        analysisId: props.analysisId,
        title: nextTitle,
        content: nextComment,
      });
    }
    if (mode === 'display') {
      setMode('add');
    }
  };

  const resetState = () => {
    setMode('display');
    setNextComment('');
    setNextTitle('');
  };

  useEffect(() => {
    if (createComment.isSuccess) {
      resetState();
    }
  }, [createComment.isSuccess]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('plot:notes_comments')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.dismiss(null, 'cancel')}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {mode === 'add' ? (
          <div className={styles['note-form']}>
            <PfInput
              label={t(`plot:note_title`) as string}
              labelPlacement="floating"
              value={nextTitle}
              onChange={(e) => setNextTitle(e as string)}
            />
            <IonTextarea
              fill="solid"
              label={t('plot:comment_label') as string}
              labelPlacement="floating"
              placeholder={t('plot:comment_placeholder') as string}
              autofocus
              autoGrow
              onIonInput={(e) => setNextComment(e.detail.value || '')}
            ></IonTextarea>
          </div>
        ) : null}

        {mode === 'display'
          ? comments.data?.map((comment) => (
              <IonCard
                key={comment.id}
                className={styles.comment}
                color="primary"
              >
                <IonCardHeader>
                  <IonCardTitle className="ion-text-uppercase">
                    {comment.title}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p style={{ fontSize: 18 }}>« {comment.note} »</p>
                  <p className="ion-text-end">
                    {comment.note_date
                      ? dayjs(comment.note_date).format('DD/MM/YYYY')
                      : null}
                  </p>
                </IonCardContent>
              </IonCard>
            ))
          : null}
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton
            className="ion-padding-horizontal"
            disabled={createComment.isLoading}
            expand="block"
            onClick={handleClick}
          >
            {mode === 'display'
              ? t('plot:add_comment')
              : t('plot:submit_comment')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default CommentsModal;
