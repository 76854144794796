import { IonBadge, IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { ZoningStatus } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

import styles from './plot-item.module.css';

const densityStatusColor = {
  [ZoningStatus.Ready]: 'success',
  [ZoningStatus.InProgress]: 'warning',
  [ZoningStatus.ToBeDone]: 'danger',
};

/* eslint-disable-next-line */
export interface PlotItemProps {
  id: number;
  name: string;
  ownerName: string;
  species: string;
  area: number;
  yieldStatus: ZoningStatus;
  selectable?: boolean;
  selected?: boolean;
  onSelectChange?: (id: number) => void;
}

export function PlotItem(props: PlotItemProps) {
  const { t } = useTranslation(['plot'], { useSuspense: false });
  return (
    <IonItem
      className={styles['ion-item']}
      lines="none"
      routerLink={!props.selectable ? `/search/plots/${props.id}` : undefined}
    >
      {props.selectable ? (
        <IonCheckbox
          aria-label={`Select plot ${props.name}`}
          checked={props.selected}
          slot="start"
          style={{ marginRight: 16 }}
          legacy
          onIonChange={() =>
            props.onSelectChange && props.onSelectChange(props.id)
          }
        />
      ) : null}
      <IonLabel className={styles['ion-label']}>
        <h2>{props.name}</h2>
        <h3>{props.ownerName}</h3>
        <span className={styles['item__status']}>
          {t('plot:status')} :{' '}
          <IonBadge color={densityStatusColor[props.yieldStatus]}>RDT</IonBadge>
        </span>
      </IonLabel>
      <IonLabel className={styles['slot-end']} slot="end">
        <div>{props.species}</div>
        <div>{props.area} ha</div>
      </IonLabel>
    </IonItem>
  );
}

export default PlotItem;
