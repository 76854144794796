import { IonButton, IonIcon, useIonModal } from '@ionic/react';
import { useFilterStore } from '@lidea/shared/data-access/core';
import {
  usePlotsInBounds,
  usePlotsMaxBounds,
} from '@lidea/shared/data-access/plots';
import { Portal } from '@mui/material';
import { log } from 'console';
import { image, key, mapOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import ImageLayer from '../image-layer/image-layer';
import { SatelliteMapModal } from '../satellite-map-modal/satellite-map-modal';
import styles from './satellite-map-control.module.css';

const root = import.meta.env.VITE_API_ROOT;

/* eslint-disable-next-line */
export interface SatelliteMapControlProps {
  plotsInBounds?: any;
  imageryIndex: string;
  setImageryIndex: (value: string) => void;
  checkboxValues: Record<number, boolean>;
  onCheckboxChange?: (id: number, checked: boolean) => void;
  position?: string;
}

export function SatelliteMapControl(props: SatelliteMapControlProps) {
  const { t } = useTranslation(['map'], { useSuspense: false });
  const { current: map } = useMap();
  const [allPlots, setAllPlots] = useState(false);

  const [dateIndices, setDateIndices] = useState<number[]>([]);
  const [dateIndicesChange, setDateIndicesChange] = useState(false);
  
  const infoPlot = props.plotsInBounds?.plots?.features?.map((feature) => ({
    plotId: feature.id,
    plotName: feature.properties?.name,
    rasters: feature.properties?.rasters,
    checked: props.checkboxValues[feature.id] || false,
    coordinates: feature.properties?.bbox?.corners_coords?.coordinates,
  })) || [];

  const colorbar = props.plotsInBounds?.colorbar;

  const checkedPlotsRasters = infoPlot
    .filter((plot) => plot.checked)
    .map((plot) => ({
      plotId: plot.plotId,
      rasters: plot.rasters,
      coordinates: plot.coordinates,
    }));

  const numberOfPlots = infoPlot.length;

  // const handleButtonPrev = (id: number) => {
  //   // TODO: props
  //   setDateIndices((prevIndices) => {
  //     const newIndices = [...prevIndices];
  //     if (infoPlot && infoPlot[id] && infoPlot[id].rasters) {
  //       newIndices[id] =
  //         newIndices[id] > 0
  //           ? newIndices[id] - 1
  //           : infoPlot[id].rasters.length - 1;
  //     }
  //     return newIndices;
  //   });
  // };

  // const handleButtonNext = (id: number) => {
  //   // TODO: props
  //   setDateIndices((prevIndices) => {
  //     const newIndices = [...prevIndices];
  //     if (infoPlot && infoPlot[id] && infoPlot[id].rasters) {
  //       newIndices[id] =
  //         newIndices[id] < infoPlot[id].rasters.length - 1
  //           ? newIndices[id] + 1
  //           : 0;
  //     }
  //     return newIndices;
  //   });
  // };

  const handleDateChange = (id: number, direction: string) => {
    setDateIndicesChange(true);
    setDateIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      if (direction === 'prev') {
        newIndices[id] = Math.max(newIndices[id] - 1, 0);
      } else if (direction === 'next') {
        newIndices[id] = Math.min(newIndices[id] + 1, infoPlot[id].rasters.length - 1);
      }
      return newIndices;
    });
  };

  const [presentSatMap, dismissSatMap] = useIonModal(SatelliteMapModal, {
    // clusterData: props.plotsInBounds.isSuccess ? mapZoom : undefined,
    colorbar: colorbar,
    infoPlot: infoPlot,
    numberOfPlots: numberOfPlots,
    selectedPlot: props.checkboxValues,
    imageryIndex: props.imageryIndex,
    setImageryIndex: props.setImageryIndex,
    onCheckboxChange: props.onCheckboxChange,
    dateIndices: dateIndices,
    setDateIndices: setDateIndices,
    onDateChange: handleDateChange,
    allPlots: allPlots,
    setAllPlots: setAllPlots,
    title: t('plot:satellite_map'),
    dismiss: () => dismissSatMap(),
  });

  useEffect(() => {
    if (!dateIndicesChange) {
      const newDateIndices = infoPlot.map((plot) =>
        plot.rasters && plot.rasters.length > 0 ? plot.rasters.length - 1 : 0
      );
      setDateIndices((prevIndices) => {
        if (JSON.stringify(prevIndices) !== JSON.stringify(newDateIndices)) {
          return newDateIndices;
        }
        return prevIndices;
      });
    }
  }, [infoPlot]);

  return (
    <>
      <Portal
        container={
          map
            ?.getContainer()
            .getElementsByClassName(
              `mapboxgl-ctrl-${props.position || 'top-right'}`
            )[0]
        }
      >
        <IonButton
          className={`${styles['container']} mapboxgl-ctrl`}
          data-testid="open-sat-map"
          onClick={() => {
            presentSatMap({ cssClass: 'page-modal' });
          }}
        >
          <IonIcon
            aria-label="open sat map"
            icon={mapOutline}
            slot="icon-only"
          />
          <span style={{ marginRight: '10px' }}></span>
          {t('map:tools')}
        </IonButton>
      </Portal>
      {dateIndices &&
        (allPlots
          ? infoPlot.map((plot, id) => {
              if (plot && plot.rasters && plot.rasters[dateIndices[id]]) {
                return (
                  <ImageLayer
                    key={id}
                    coordinates={plot.coordinates}
                    id={plot.plotId.toString()}
                    imageUrl={`${root}${
                      plot.rasters[dateIndices[id]].url || ''
                    }`}
                  />
                );
              }
            })
          : checkedPlotsRasters.map((plot, id) => {
              if (plot && plot.rasters && plot.rasters[dateIndices[id]]) {
                return (
                  <ImageLayer
                    key={id}
                    coordinates={plot.coordinates}
                    id={plot.plotId.toString()}
                    imageUrl={`${root}${
                      plot.rasters[dateIndices[id]].url || ''
                    }`}
                  />
                );
              }
            }))}
    </>
  );
}

export default SatelliteMapControl;
