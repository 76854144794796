import { privateClient } from '@lidea/shared/data-access/core';
import {
  Heatmap,
  ListIndexes,
  Measure,
  Measures,
  Zoning,
} from '@lidea/shared/util/types';

export const zoningApi = {
  getImageryIndexes: () => {
    return privateClient.get('list_indexes/').json<ListIndexes>();
  },
  getHeatmaps: (params: { plotId: number; imageryIndex: string }) => {
    return privateClient
      .get(`plots/${params.plotId}/heatmaps`, {
        searchParams: { type: params.imageryIndex },
      })
      .json<Heatmap[]>();
  },
  getZoning: (analysisId: number) => {
    return privateClient.get(`analysis/${analysisId}/zoning`).json<Zoning>();
  },
  generateZoning: (
    imageryIndex: string,
    imageDate: string,
    analysisId: number,
    zoneNumber: string
  ) => {
    const searchParams: any = {
      date: imageDate,
      analysis: analysisId.toString(),
      type: imageryIndex,
    };

    if (zoneNumber !== '-1') {
      searchParams.zone = zoneNumber;
    }

    return privateClient
      .get('zonage', {
        searchParams,
      })
      .json<Zoning>();
  },
  getMeasures: (analysisId: number) => {
    return privateClient
      .get(`analysis/${analysisId}/measures`)
      .json<Measures>();
  },
  updateMeasure: (props: {
    id: number;
    data: { [key: string]: number | string | null };
  }) => {
    return privateClient
      .patch(`measures/${props.id}/`, { json: props.data })
      .json<Measure>();
  },
  uploadCropPicture: (props: {
    measureId: number;
    field: string;
    picture: File;
  }) => {
    const formData = new FormData();
    formData.append('image', props.picture);
    formData.append('field', props.field);
    return privateClient
      .post(`measures/${props.measureId}/upload/`, { body: formData })
      .json();
  },
};
