import { plotQuery } from '@lidea/shared/data-access/plots';
import { AnalysisType } from '@lidea/shared/util/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseAnalysisProps {
  plotId: number;
  analysisId: number;
}

export function useAnalysis(props: UseAnalysisProps) {
  const plot = useQuery(plotQuery(props.plotId));

  const analyzes = useMemo(
    () => plot.data?.properties.analyzes || [],
    [plot.data]
  );

  const analysis = useMemo(() => {
    if (analyzes.length) {
      const currentAnalysis = analyzes.filter(
        (a) => a.id === Number(props.analysisId)
      )[0];

      return {
        analysis: currentAnalysis,
        densityAnalysis: analyzes.find((a) => a.number === AnalysisType.Density),
        analysisType: currentAnalysis.number,
      };
    }
    return;
  }, [props.analysisId, analyzes]);

  return analysis;
}

export default useAnalysis;
