import { useFilterStore } from '@lidea/shared/data-access/core';
import {
  usePlotsInBounds,
  usePlotsMaxBounds,
} from '@lidea/shared/data-access/plots';
import { MobilePage } from '@lidea/shared/ui/layout';
import {
  ClusterLayer,
  GeolocateControl,
  PfMap,
  PlotsLayer,
  SatelliteMapControl,
  SatelliteMapModal,
  StyleControl,
} from '@lidea/shared/ui/map';
import {
  PlotFeatureCollection,
  PlotsClusterFeatureCollection,
} from '@lidea/shared/util/types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef, NavigationControl } from 'react-map-gl';
import { useHistory } from 'react-router-dom';

import styles from './map-tab.module.css';

const PLOTS_LAYER_ID = 'plots-layer';
const CLUSTER_LAYER_ID = 'cluster-layer';

/* eslint-disable-next-line */
export interface MapTabProps {}

export function MapTab(props: MapTabProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const mapRef = useRef<MapRef>(null);
  const history = useHistory();

  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapBounds, setMapBounds] = useState<mapboxgl.LngLatBounds | null>(
    null
  );
  const [mapZoom, setMapZoom] = useState(0);
  const [imageryIndex, setImageryIndex] = useState(undefined);
  const [checkboxValues, setCheckboxValues] = useState<Record<number, boolean>>(
    {}
  );
  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const plotsMaxBounds = usePlotsMaxBounds();
  const plotsInBounds = usePlotsInBounds({
    selectedFilters,
    bounds: mapBounds,
    cluster: mapZoom < 9,
    zoom: mapZoom,
    type: imageryIndex,
  });

  const handleCheckboxChange = (plotId: number, checked?: boolean) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [plotId]: !prevValues[plotId],
    }));
  };

  const handleTypeMap = (value: string) => {
    setImageryIndex(value);
  };

  const handleMapIdle = (e: mapboxgl.MapboxEvent) => {
    setMapBounds(e.target.getBounds());
    setMapZoom(e.target.getZoom());
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const handleMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const feature = e.features?.[0];
    if (!feature) {
      return;
    }

    switch (feature.layer.id) {
      case PLOTS_LAYER_ID:
        if (!imageryIndex) {
          history.push(`/search/plots/${feature.id}`);
        }
        handleCheckboxChange(feature.id as number);
        break;

      case CLUSTER_LAYER_ID:
      case `${CLUSTER_LAYER_ID}_points`: {
        mapRef.current
          ?.getMap()
          .fitBounds(JSON.parse(feature.properties?.bbox) as any, {
            padding: 50,
            animate: false,
          });
        break;
      }
    }
  };

  useEffect(() => {
    if (plotsMaxBounds.data && mapLoaded) {
      mapRef.current?.getMap().fitBounds(plotsMaxBounds.data.bbox as any, {
        padding: 10,
        maxZoom: 15,
        animate: false,
      });
    }
  }, [mapLoaded, plotsMaxBounds.data]);

  return (
    <MobilePage>
      <PfMap
        ref={mapRef}
        interactiveLayerIds={[
          PLOTS_LAYER_ID,
          CLUSTER_LAYER_ID,
          `${CLUSTER_LAYER_ID}_points`,
        ]}
        onClick={handleMapClick}
        onIdle={handleMapIdle}
        onLoad={handleMapLoad}
        // TODO: remove this when we extract satmap button in a control component
        id="edit-plot-map"
      >
        <StyleControl />

        {mapZoom > 9 &&
        plotsInBounds.data?.plots?.features?.some(
          (feature) =>
            feature.properties?.name && feature.properties.name.trim() !== ''
        ) ? (
          <SatelliteMapControl
            checkboxValues={checkboxValues}
            imageryIndex={imageryIndex}
            plotsInBounds={plotsInBounds.data}
            position="top-left"
            setImageryIndex={handleTypeMap}
            onCheckboxChange={handleCheckboxChange}
          />
        ) : null}

        <NavigationControl position="top-right" />

        <GeolocateControl position="bottom-right" />

        {plotsInBounds.isSuccess ? (
          <PlotsLayer
            data={plotsInBounds.data.plots as unknown as PlotFeatureCollection}
            id={PLOTS_LAYER_ID}
            minZoom={9}
          />
        ) : null}
        {plotsInBounds.isSuccess ? (
          <ClusterLayer
            clusterColor="#ED7D31"
            clustersMaxZoom={9}
            data={plotsInBounds.data as PlotsClusterFeatureCollection}
            layerId={CLUSTER_LAYER_ID}
          />
        ) : null}
      </PfMap>
    </MobilePage>
  );
}

export default MapTab;
