import { IonToggle } from '@ionic/react';
import { Portal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import styles from './style-control.module.css';

/* eslint-disable-next-line */
export interface StyleControlProps {}

export function StyleControl(props: StyleControlProps) {
  const { t } = useTranslation(['map'], { useSuspense: false });
  const { current: map } = useMap();
  const [checked, setChecked] = useState(false);

  const handleChange = (event: CustomEvent) => {
    setChecked(event.detail.checked);
  };

  useEffect(() => {
    const m = map?.getMap();
    if (checked) {
      m?.setStyle('mapbox://styles/mapbox/outdoors-v12');
    } else {
      m?.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
    }
  }, [checked]);

  return (
    <Portal
      container={
        map?.getContainer().getElementsByClassName('mapboxgl-ctrl-top-left')[0]
      }
    >
      <div className={`${styles['container']} mapboxgl-ctrl`}>
        <IonToggle labelPlacement="end" onIonChange={handleChange}>
          {t('map:change_style')}
        </IonToggle>
      </div>
    </Portal>
  );
}

export default StyleControl;
