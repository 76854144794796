import { Layer, Source } from 'react-map-gl';

/* eslint-disable-next-line */
export interface ImageLayerProps {
  imageUrl: string;
  coordinates: number[][];
  id?: string;
}

export function ImageLayer(props: ImageLayerProps) {
  const sourceId = props.id
    ? `image-data-${props.id}-${props.imageUrl}`
    : `image-data`;
  return (
    <Source
      key={sourceId}
      coordinates={props.coordinates}
      id={props.id}
      type="image"
      url={props.imageUrl}
    >
      <Layer id={props.id} type="raster" />
    </Source>
  );
}

export default ImageLayer;
