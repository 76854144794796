import { IonProgressBar } from '@ionic/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { memo } from 'react';

import styles from './query-loading-bar.module.css';

/* eslint-disable-next-line */
export interface QueryLoadingBarProps {}

export const QueryLoadingBar = memo(() => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const isLoading = isFetching > 0 || isMutating > 0;

  return isLoading ? (
    <IonProgressBar type="indeterminate"></IonProgressBar>
  ) : null;
});

export default QueryLoadingBar;
