import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { listOutline, mapOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './mobile-tabs-layout.module.css';

/* eslint-disable-next-line */
export interface MobileTabsLayoutProps {
  children?: React.ReactNode;
}

export function MobileTabsLayout(props: MobileTabsLayoutProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  return (
    <IonReactRouter>
      <IonTabs className={styles['container']}>
        <IonRouterOutlet>
          {/* START: routes */}
          {props.children}
          {/* END: routes */}
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton href="/map" tab="tab1">
            <IonIcon aria-hidden="true" icon={mapOutline} />
            <IonLabel>{t('common:map')}</IonLabel>
          </IonTabButton>
          <IonTabButton href="/search" tab="tab2">
            <IonIcon aria-hidden="true" icon={listOutline} />
            <IonLabel>{t('common:plots')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
}

export default MobileTabsLayout;
