import { Note } from '@lidea/shared/util/types';

import { privateClient } from './api-client';

type GetAllParams = {
  plotId?: number;
  analysisId?: number;
};

type CreateData = {
  plotId?: number;
  analysisId?: number;
  title: string;
  content: string;
};

export const commentsApi = {
  getAll: (params: GetAllParams) => {
    const searchParams = new URLSearchParams();
    if (params.plotId) {
      searchParams.set('plot', params.plotId.toString());
    }
    if (params.analysisId) {
      searchParams.set('analysis', params.analysisId.toString());
    }
    return privateClient
      .get('notes', {
        searchParams,
      })
      .json<Note[]>();
  },
  create: (data: CreateData) => {
    return privateClient
      .post('notes/', {
        json: {
          plot: data.plotId,
          analysis: data.analysisId,
          title: data.title,
          note: data.content,
        },
      })
      .json();
  },
};
