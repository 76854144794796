import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close, eyeOffOutline } from 'ionicons/icons'; // Nouvelle icône ajoutée
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './modal-page.module.css';

/* eslint-disable-next-line */
export interface ModalPageProps {
  children?: React.ReactNode;
  title: string;
  onClose: () => void;
  eyeOff?: boolean;
  isHidden?: boolean;
  setIsHidden?: (value: boolean) => void;
  headerExtension?: JSX.Element;
}

export function ModalPage(props: ModalPageProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });

  const handleButtonClick = () => {
    props.setIsHidden && props.setIsHidden(!props.isHidden);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle style={{ fontSize: '18px' }}>{props.title}</IonTitle>
          <IonButtons slot="end">
            {props.eyeOff && (
              <IonTitle
                className={styles.buttonSat}
                onClick={handleButtonClick}
              >
                {t('common:visualize')}
              </IonTitle>
            )}
            <IonButton onClick={props.onClose}>
              <IonIcon
                aria-label="close modal"
                icon={close}
                slot="icon-only"
              ></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {props.headerExtension}
      </IonHeader>
      <IonContent className="ion-padding">{props.children}</IonContent>
    </IonPage>
  );
}
export default ModalPage;
