import { Filters, FiltersOptions } from '@lidea/shared/util/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { filtersAPI } from '../../apis/filters-api';
import useFilterStore from '../../filters-store/filters-store';
import { filterKeys } from './../../keys/filter-keys';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function useFilters() {
  const { i18n } = useTranslation([], { useSuspense: false });

  const selectedFilters = useFilterStore((state) => state.selectedFilters);

  const locale = useMemo(() => i18n.language as 'fr' | 'en', [i18n.language]);
  const filters = useQuery<Filters>({
    queryKey: filterKeys.search(selectedFilters),
    queryFn: () => filtersAPI.getAll(selectedFilters),
    keepPreviousData: true,
  });

  // const contractCode = filters.data?.protocol.map(item => item.contract_code) ;
  // const uniqueContractCode = Array.from(new Set(contractCode));
  
  const filtersAsOptions = useMemo<FiltersOptions>(() => {
    if (!filters.data) {
      return {};
    }

    return (Object.entries(filters.data) as Entries<Filters>).reduce(
      (acc, curr) => {
        const [key, values] = curr;

        switch (key) {
          case 'country': {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: (Object.entries(values) as Entries<typeof values>).map(
                  (country) => ({
                    label: country[1],
                    value: country[0],
                  })
                ),
              },
            };
          }

          case 'country_manager':
          case 'manager': {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: values.map((item) => ({
                  label: `${item.first_name} ${item.last_name}`,
                  value: item.id,
                })),
              },
            };
          }
          case 'farm': {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: values.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              },
            };
          }

          case 'contract_code': {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: values.map((item) => ({
                  label: item[`name_${locale}`],
                  value: item.id,
                })),
              },
            };
          }

          // case 'protocol':
          case 'species_group':
          case 'generation':
          case 'variety':
          case 'species': {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: values.map((item) => ({
                  label: item[`name_${locale}`],
                  value: item.id,
                })),
              },
            };
          }

          default: {
            return {
              ...acc,
              [key]: {
                label: key,
                value: key,
                options: values.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              },
            };
          }
        }
      },
      {}
    );
  }, [filters.data, locale]);

  return { ...filters, filtersAsOptions };
}

export default useFilters;
