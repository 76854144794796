import { plotKeys } from '@lidea/shared/data-access/plots';
import { Measures } from '@lidea/shared/util/types';
import { NotificationProps } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { zoningApi } from './../../apis/zoning-api';
import { analysisKeys } from './../../keys/analysis-keys';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseUpdateMeasureProps {
  analysisId: number;
  plotId: number;
}

export function useUpdateMeasure(props: UseUpdateMeasureProps) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['updateMeasure'],
    mutationFn: zoningApi.updateMeasure,
    onMutate: ({ id, data }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(analysisKeys.measures(props.analysisId));

      // Snapshot the previous measures
      const previousMeasures = queryClient.getQueryData<Measures>(
        analysisKeys.measures(props.analysisId)
      );
      if (!previousMeasures || !previousMeasures[id]) {
        return;
      }

      const nextMeasure = previousMeasures[id];

      // loop through each key-value pair in the input object
      for (const [inputKey, inputValue] of Object.entries(data)) {
        // loop through each field in the dynamic array
        nextMeasure.dynamic.forEach((dynamic, dI) => {
          for (const [index, dynamicField] of dynamic.fields.entries()) {
            // if the field name matches the input key, update the field value
            if (dynamicField.name === inputKey) {
              nextMeasure.dynamic[dI].fields[index].value = inputValue;
              break; // break out of the loop since we found the match
            }
          }
        });

        if (nextMeasure.static) {
          // loop through each field in the static array
          for (const [index, staticField] of nextMeasure.static.entries()) {
            // if the field name matches the input key, update the field value
            if (staticField.name === inputKey) {
              nextMeasure.static[index].value = inputValue;
              break; // break out of the loop since we found the match
            }
          }
        }
      }

      // Optimistically update to the new value
      queryClient.setQueryData<Measures>(
        analysisKeys.measures(props.analysisId),
        (lastMeasures) => {
          if (lastMeasures) {
            const newMeasures = {
              ...lastMeasures,
              [id]: nextMeasure,
            };
            return newMeasures;
          }
          return;
        }
      );

      const notification: NotificationProps = {
        message: `Measure ${nextMeasure.id} was updated`,
        color: 'green',
      };

      // Return a context object with the snapshotted value
      return { previousMeasures, notification };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: analysisKeys.measures(props.analysisId),
      });
      queryClient.invalidateQueries({
        queryKey: analysisKeys.zoning(props.analysisId),
      });
      queryClient.invalidateQueries({
        queryKey: plotKeys.detail(props.plotId),
      });
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        analysisKeys.measures(props.analysisId),
        context?.previousMeasures
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: analysisKeys.measures(props.analysisId),
      });
    },
  });

  return mutation;
}

export default useUpdateMeasure;
