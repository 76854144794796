import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreatePlotData } from '../use-create-plot/use-create-plot';
import { plotsApi } from './../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseUpdateParamsPlotProps {}

export type UpdateParamsPlotData = Partial<CreatePlotData>;

interface MutationProps {
  plotId: number;
  data: UpdateParamsPlotData;
}

export function useUpdateParamsPlot() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['updateParamsPlot'],
    mutationFn: ({ plotId, data }: MutationProps) =>
      plotsApi.updateParams(plotId, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: plotKeys.detail(data.id),
      });
      queryClient.invalidateQueries({
        queryKey: plotKeys.inBound(),
      });
    },
  });

  return mutation;
}

export default useUpdateParamsPlot;
