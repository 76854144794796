import { AnalysisStep, ZoningStatus } from '@lidea/shared/util/types';

export const getAnalysisStep = (
  status: ZoningStatus = ZoningStatus.ToBeDone
) => {
  switch (status) {
    case ZoningStatus.ToBeDone:
      return AnalysisStep.Zoning;
    case ZoningStatus.InProgress:
      return AnalysisStep.Measures;
    case ZoningStatus.Ready:
      return AnalysisStep.Measures;
  }
};
