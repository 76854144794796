import { useMutation, useQueryClient } from '@tanstack/react-query';

import { commentsApi } from './../../apis/comments-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCreateComment {}

export function useCreateComment() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: commentsApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries(['comments']);
    },
  });

  return mutation;
}

export default useCreateComment;
