import { SatzonesFeatureCollection } from '@lidea/shared/util/types';
import { Layer, Source } from 'react-map-gl';

/* eslint-disable-next-line */
export interface ZoningLayerProps {
  data: SatzonesFeatureCollection;
  id?: string;
}

const polygonOutlineLayerStyle = {
  type: 'line' as const,
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export function ZoningLayer(props: ZoningLayerProps) {
  return (
    <Source data={props.data} id="zoning-data" type="geojson">
      <Layer
        id={props.id}
        paint={{
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5,
        }}
        type="fill"
      />
      <Layer
        id={props.id ? `${props.id}_outline` : undefined}
        {...polygonOutlineLayerStyle}
      />
    </Source>
  );
}

export default ZoningLayer;
