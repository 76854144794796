import { Network } from '@capacitor/network';
import { IonButton, IonIcon, useIonToast } from '@ionic/react';
import {
  analysisKeys,
  useUploadCropPicture,
} from '@lidea/shared/data-access/analysis';
import { usePlot } from '@lidea/shared/data-access/plots';
import { PrevNextButtons } from '@lidea/shared/ui/core';
import {
  MeasureDynamicFields,
  MeasureStaticFields,
} from '@lidea/shared/util/types';
import {
  FileButton,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, usePrevious } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { cameraOutline, imageOutline } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MeasuresInputsList from './../measures-inputs-list/measures-inputs-list';
import DynamicFields from './dynamic-fields/dynamic-fields';
import styles from './measure-form.module.css';
import OfflineAlert from './offline-alert/offline-alert';

const imageProcessingLoader = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontWeight: 900,
      padding: 16,
      textAlign: 'center',
      color: 'black',
    }}
  >
    <p>Les images sont en cours de traitement, veuillez patienter</p>
    <Loader color="green" size="md" />
  </div>
);

export type MeasureFields = {
  static?: MeasureStaticFields;
  dynamic?: MeasureDynamicFields;
};

export type MeasureFormValues = {
  [key: string]: number | string | null;
};

/* eslint-disable-next-line */
export interface MeasureFormProps {
  plotId: string;
  analysisId: string;
  measureId: number;
  title?: string;
  fields: MeasureFields;
  coordinates?: string;
  submitExpand?: 'block' | 'full';
  onPrevious?: () => void;
  onNext?: () => void;
  onSubmit: (event: MeasureFormValues) => void;
  i18n: {
    submit: string;
    back?: string;
  };
}

export function MeasureForm(props: MeasureFormProps) {
  const plot = usePlot(Number(props.plotId));
  const speciesGroup = plot.data?.properties.species_group.name_fr;
  const { dynamic, static: staticFields } = props.fields;
  const filteredStaticFields =
    staticFields?.filter((field) => field.name !== 'FEC') || [];
  const { i18n } = useTranslation([], { useSuspense: false });
  const { t } = useTranslation(['analysis', 'plot'], { useSuspense: false });

  const [present] = useIonToast();
  const [networkConnected, setNetworkConnected] = useState(true);
  const [offlineDialogOpened, offlineDialogHandlers] = useDisclosure();

  const queryClient = useQueryClient();
  const uploadCropPicture = useUploadCropPicture();

  const validateValue = (value, label, min, max) => {
    const parsedValue = parseFloat(value);
    if (parsedValue < min || parsedValue > max) {
      const message = `${t(`plot:${label}`)} ${t('plot:between')} ${min} ${t(
        'plot:and'
      )} ${max}`;
      notifications.show({ color: 'red', message });
      return message;
    }
  };

  const validations = speciesGroup === 'Tournesol'
    ? {
        // STATIC
        PERTES: (value) => validateValue(value, 'PERTES', 0, 100),
        NBCM: (value) => validateValue(value, 'NBCM', 0, 15),

        // DYNAMIC
        NBG: (value) => validateValue(value, 'NBG', 0, 3000),
        DCAP: (value) => validateValue(value, 'DCAP', 0, 40),

        NBGP: (value) => validateValue(value, 'NBGP', 0, 1000),
        NBGP1: (value) => validateValue(value, 'NBGP1', 0, 1000),
        NBGP2: (value) => validateValue(value, 'NBGP2', 0, 1000),
        NBGP3: (value) => validateValue(value, 'NBGP3', 0, 1000),
        NBGP4: (value) => validateValue(value, 'NBGP4', 0, 1000),
        NBGP5: (value) => validateValue(value, 'NBGP5', 0, 1000),
        NBGP6: (value) => validateValue(value, 'NBGP6', 0, 1000),
        NBGP7: (value) => validateValue(value, 'NBGP7', 0, 1000),
        NBGP8: (value) => validateValue(value, 'NBGP8', 0, 1000),
        NBGP9: (value) => validateValue(value, 'NBGP9', 0, 1000),
        NBGP10: (value) => validateValue(value, 'NBGP10', 0, 1000),

        FEC: (value) => validateValue(value, 'FEC', 0, 100),
        FEC1: (value) => validateValue(value, 'FEC1', 0, 100),
        FEC2: (value) => validateValue(value, 'FEC2', 0, 100),
        FEC3: (value) => validateValue(value, 'FEC3', 0, 100),
        FEC4: (value) => validateValue(value, 'FEC4', 0, 100),
        FEC5: (value) => validateValue(value, 'FEC5', 0, 100),
        FEC6: (value) => validateValue(value, 'FEC6', 0, 100),
        FEC7: (value) => validateValue(value, 'FEC7', 0, 100),
        FEC8: (value) => validateValue(value, 'FEC8', 0, 100),
        FEC9: (value) => validateValue(value, 'FEC9', 0, 100),
        FEC10: (value) => validateValue(value, 'FEC10', 0, 100),
      }
    : {
        // STATIC
        NBEP1: (value) => validateValue(value, 'NBEP1', 1, 50),
        NBEP2: (value) => validateValue(value, 'NBEP2', 0, 50),
        
        // DYNAMIC
        NBG1: (value) => validateValue(value, 'NBG1', 1, 50),
        NBG2: (value) => validateValue(value, 'NBG2', 0, 50),
    };

  const initialValues = useMemo<MeasureFormValues>(() => {
    const staticFields =
      props.fields.static?.reduce<MeasureFormValues>((acc, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {}) || {};

    const dynamicFields =
      dynamic?.reduce<MeasureFormValues>((acc, field) => {
        field.fields.forEach((f) => {
          acc[f.name] = f.value;
        });

        return acc;
      }, {}) || {};

    return {
      ...staticFields,
      ...dynamicFields,
    };
  }, [dynamic, props.fields.static]);

  const form = useForm<MeasureFormValues>({
    initialValues,
    validate: validations,
  });

  const prevInitialValues = usePrevious(initialValues);

  // const form = useForm<MeasureFormValues>({
  //   initialValues,
  //   validate: {},
  // });

  const handleCameraApp = async (file: File | null, dynamicName: string) => {
    if (!file) {
      return;
    }

    // check if network is connected
    if (networkConnected) {
      // yes -> upload picture
      uploadCropPicture.mutate({
        measureId: Number(props.measureId),
        field: dynamicName,
        picture: file,
      });
    } else {
      // no -> save picture and show alert dialog
      const fileExtension = file.name.split('.').pop();
      const fileName = `LIDEA_${dynamicName}_${dayjs().format(
        'ddd_MMM_YYYY_HH_mm'
      )}.${fileExtension}`;
      try {
        FileSaver.saveAs(file, fileName);
        present({
          message: t('analysis:picture_saved')!,
          duration: 1500,
        });
      } catch (error) {
        console.error('Unable to write file', error);
      }
      offlineDialogHandlers.open();
    }
  };

  const handleSubmit = (values: MeasureFormValues) => {
    props.onSubmit(values);
  };
  useEffect(() => {
    // set initial network status
    Network.getStatus().then((status) => {
      setNetworkConnected(status.connected);
    });

    // listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });
  }, []);

  useEffect(() => {
    if (uploadCropPicture.isSuccess) {
      // invalidate measure query
      queryClient.invalidateQueries({
        queryKey: analysisKeys.measures(Number(props.analysisId)),
      });
    }
  }, [props.analysisId, queryClient, uploadCropPicture]);

  // on initialValues change, update form values
  useEffect(() => {
    if (
      prevInitialValues &&
      JSON.stringify(prevInitialValues) !== JSON.stringify(initialValues)
    ) {
      const dynamicFields =
        dynamic?.reduce<MeasureFormValues>((acc, field) => {
          field.fields.forEach((f) => {
            acc[f.name] = f.value;
          });

          return acc;
        }, {}) || {};

      // only update values for dynamic fields
      form.setValues({
        ...form.values,
        ...dynamicFields,
      });
    }
  }, [dynamic, form, initialValues, prevInitialValues]);

  return (
    <>
      <form
        className={styles['form']}
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        {props.title ? (
          <div className={classNames(styles['form__header'])}>
            <div className={classNames(styles['form__header__title'])}>
              <h3>{props.title}</h3>
              <PrevNextButtons
                onNext={props.onNext}
                onPrev={props.onPrevious}
              />
            </div>

            {props.coordinates ? (
              <div style={{ fontSize: 12 }}>{props.coordinates}</div>
            ) : null}
          </div>
        ) : null}

        <div className={classNames(styles['form__body'])}>
          {speciesGroup === 'Mais' ? (
            filteredStaticFields?.length ? (
              <div className={styles['static-fields']}>
                <MeasuresInputsList
                  fields={filteredStaticFields}
                  form={form}
                  labelPlacement="stacked"
                />
              </div>
            ) : null
          ) : staticFields?.length ? (
            <div className={styles['static-fields']}>
              <MeasuresInputsList
                fields={staticFields}
                form={form}
                labelPlacement="stacked"
              />
            </div>
          ) : null}

          {dynamic?.length ? (
            <Grid className={styles['dynamic-fields']}>
              {dynamic
                ?.filter(
                  (group) =>
                    speciesGroup === 'Tournesol' && group.name === 'NBG'
                )
                .map((group, index) => (
                  <Grid.Col
                    key={index}
                    span={12}
                    style={{ position: 'relative' }}
                  >
                    <LoadingOverlay
                      loader={imageProcessingLoader}
                      overlayOpacity={0.9}
                      visible={group.image_processing === true}
                    />
                    <h3>{group.label?.fr}</h3>
                    {/* Add Image input 
                    If offline mode, show alert dialog, then open camera app
                    If online mode, open camera app then upload picture
                */}
                    {group.image_processing !== null ? (
                      <Stack spacing={0}>
                        <FileButton
                          accept="image/*"
                          capture="environment"
                          onChange={(e) => handleCameraApp(e, group.name)}
                        >
                          {(props) => (
                            <IonButton
                              aria-label={t('analysis:take_crop_picture')!}
                              expand="block"
                              style={{ flexGrow: 1 }}
                              {...props}
                              disabled={
                                uploadCropPicture.isLoading ||
                                group.image_processing === true
                              }
                            >
                              <IonIcon
                                aria-hidden="true"
                                icon={cameraOutline}
                                slot="icon-only"
                              ></IonIcon>
                            </IonButton>
                          )}
                        </FileButton>
                        <FileButton
                          accept="image/*"
                          onChange={(e) => handleCameraApp(e, group.name)}
                        >
                          {(props) => (
                            <IonButton
                              aria-label={t('analysis:take_crop_picture')!}
                              expand="block"
                              style={{ flexGrow: 1 }}
                              {...props}
                              disabled={
                                uploadCropPicture.isLoading ||
                                group.image_processing === true
                              }
                            >
                              <IonIcon
                                aria-hidden="true"
                                icon={imageOutline}
                                slot="icon-only"
                              ></IonIcon>
                            </IonButton>
                          )}
                        </FileButton>
                      </Stack>
                    ) : null}
                  </Grid.Col>
                ))}
            </Grid>
          ) : null}

          {dynamic?.length ? (
            <Grid className={styles['dynamic-fields-col']}>
              {dynamic
                ?.filter(
                  (group) =>
                    !(speciesGroup === 'Tournesol' && group.name === 'NBG')
                )
                .map((group, index) => (
                  <Grid.Col
                    key={index}
                    span={
                      12 /
                      ((dynamic?.length || 1) -
                        (speciesGroup === 'Tournesol' ? 1 : 0))
                    }
                    style={{ position: 'relative' }}
                  >
                    <LoadingOverlay
                      loader={imageProcessingLoader}
                      overlayOpacity={0.9}
                      visible={group.image_processing === true}
                    />
                    <h3>{group.label?.fr}</h3>
                    {/* Add Image input 
                    If offline mode, show alert dialog, then open camera app
                    If online mode, open camera app then upload picture
                */}
                    {group.image_processing !== null ? (
                      <Group spacing={0}>
                        <FileButton
                          accept="image/*"
                          capture="environment"
                          onChange={(e) => handleCameraApp(e, group.name)}
                        >
                          {(props) => (
                            <IonButton
                              aria-label={t('analysis:take_crop_picture')!}
                              expand="block"
                              style={{ flexGrow: 1 }}
                              {...props}
                              disabled={
                                uploadCropPicture.isLoading ||
                                group.image_processing === true
                              }
                            >
                              <IonIcon
                                aria-hidden="true"
                                icon={cameraOutline}
                                slot="icon-only"
                              ></IonIcon>
                            </IonButton>
                          )}
                        </FileButton>
                        <FileButton
                          accept="image/*"
                          onChange={(e) => handleCameraApp(e, group.name)}
                        >
                          {(props) => (
                            <IonButton
                              aria-label={t('analysis:take_crop_picture')!}
                              expand="block"
                              style={{ flexGrow: 1 }}
                              {...props}
                              disabled={
                                uploadCropPicture.isLoading ||
                                group.image_processing === true
                              }
                            >
                              <IonIcon
                                aria-hidden="true"
                                icon={imageOutline}
                                slot="icon-only"
                              ></IonIcon>
                            </IonButton>
                          )}
                        </FileButton>
                      </Group>
                    ) : null}
                    <DynamicFields
                      fields={group.fields}
                      fieldsLabel={
                        group.fieldsLabel[i18n.language as 'en' | 'fr']
                      }
                      form={form}
                    />
                  </Grid.Col>
                ))}
            </Grid>
          ) : null}
        </div>

        <div className={styles['form__buttons']}>
          {props.i18n.back ? (
            <IonButton color="danger" routerLink={`/plots/${props.plotId}`}>
              {props.i18n.back}
            </IonButton>
          ) : null}
          <IonButton expand={props.submitExpand} type="submit">
            {props.i18n.submit}
          </IonButton>
        </div>
        <input style={{ display: 'none' }} type="submit" />
      </form>

      <OfflineAlert
        isOpen={offlineDialogOpened}
        onClose={offlineDialogHandlers.close}
      />
    </>
  );
}

export default MeasureForm;
