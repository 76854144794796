import { useQuery } from '@tanstack/react-query';

import { commentsApi } from './../../apis/comments-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCommentsProps {
  plotId?: number;
  analysisId?: number;
}

export function useComments(props: UseCommentsProps) {
  const query = useQuery({
    queryKey: ['comments', props],
    queryFn: () => commentsApi.getAll(props),
  });

  return query;
}

export default useComments;
