import { useQuery } from '@tanstack/react-query';

import { zoningApi } from './../../apis/zoning-api';
import { analysisKeys } from './../../keys/analysis-keys';

interface UseZoningParams {
  analysisId: number | null;
  enabled: boolean;
}

export const zoningQuery = (params: UseZoningParams) => ({
  queryKey: analysisKeys.zoning(params.analysisId as number),
  queryFn: () => zoningApi.getZoning(params.analysisId as number),
  enabled: params.analysisId !== null && params.enabled,
});

export function useZoning(params: UseZoningParams) {
  const query = useQuery(zoningQuery(params));

  return query;
}

export default useZoning;
