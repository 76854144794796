import { Network } from '@capacitor/network';
import { IonButton, IonContent, IonToggle, useIonToast } from '@ionic/react';
import {
  useAnalysis,
  useGenerateZoning,
  useHeatmaps,
  useImageryIndexes,
  useZoning,
} from '@lidea/shared/data-access/analysis';
import { usePlot } from '@lidea/shared/data-access/plots';
import { SheetModal } from '@lidea/shared/ui/core';
import { PfSelect, SelectImageSlider } from '@lidea/shared/ui/form';
import { MobilePage } from '@lidea/shared/ui/layout';
import { PlotMap, PlotMapType } from '@lidea/shared/ui/map';
import { ColorScale } from '@lidea/shared/ui/map';
import { AnalysisType } from '@lidea/shared/util/types';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import MeasuresToolbar from '../../components/measures-toolbar/measures-toolbar';
import PlotToolbar from '../../components/plot-toolbar/plot-toolbar';
import styles from './zoning.module.css';

/* eslint-disable-next-line */
export interface ZoningProps
  extends RouteComponentProps<{
    id: string;
    analysisId: string;
  }> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = import.meta.env.VITE_API_ROOT;

const zoneNumber = [
  {
    value: '-1',
    label: 'Zonage recommandé',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '2',
    label: '2',
  },
];

export function Zoning(props: ZoningProps) {
  const { id, analysisId } = props.match.params;
  const canGenerate = props.location.search.includes('generate=true');
  const { t } = useTranslation(['common', 'plot', 'analysis', 'map'], {
    useSuspense: false,
  });
  const [networkConnected, setNetworkConnected] = useState(true);
  const [present] = useIonToast();
  const [selectedImageryIndex, setSelectedImageryIndex] =
    useState<string>('NDVI');
  const [selectedZoneNumber, setSelectedZoneNumber] = useState('-1');
  const [mapType, setMapType] = useState<PlotMapType>(PlotMapType.Heatmap);
  const initialBreakpoint = 150 / window.innerHeight; // Sinon 280
  const [checked, setChecked] = useState(false);

  const plot = usePlot(Number(id));
  const analysis = useAnalysis({
    plotId: Number(id),
    analysisId: Number(analysisId),
  });
  const { imageryIndexes, imageryIndexesAsOptions } = useImageryIndexes();
  const {
    heatmapsAsOptions,
    selectedHeatmap,
    setSelectedHeatmap,
    selectedHeatmapFeature,
    colorBar,
  } = useHeatmaps({
    plotId: Number(id),
    imageryIndex: selectedImageryIndex,
  });
  const colorbar = colorBar.map((feature) => feature.legend);

  const generateZoning = useGenerateZoning({ plotId: Number(id) });

  const densityZoning = useZoning({
    analysisId: Number(analysis?.densityAnalysis?.id),
    enabled: analysis?.analysisType === AnalysisType.Yield,
  });

  const handleLegend = (event: CustomEvent) => {
    setChecked(event.detail.checked);
  };

  const mapProps = useMemo(() => {
    if (mapType === PlotMapType.Zoning && densityZoning.data) {
      return {
        type: PlotMapType.Zoning,
        zoning: densityZoning.data.satzones,
      };
    } else {
      return {
        type: PlotMapType.Heatmap,
        coordinates: selectedHeatmapFeature?.corners_coords.coordinates,
        image: `${root}${selectedHeatmapFeature?.affine}`,
      };
    }
  }, [mapType, selectedHeatmapFeature, densityZoning]);

  const handleSelectImageryIndex = (index: string) => {
    setMapType(PlotMapType.Heatmap);
    setSelectedImageryIndex(index);
    setSelectedHeatmap(undefined);
  };

  const handleSelectHeatmap = (index: string) => {
    setMapType(PlotMapType.Heatmap);
    setSelectedHeatmap(index);
  };

  const handleSelectDensityHeatmap = useCallback(() => {
    if (analysis?.densityAnalysis) {
      setSelectedImageryIndex(analysis.densityAnalysis.zoning.index);
      setSelectedHeatmap(analysis.densityAnalysis.zoning.raster_date);
    }
  }, [analysis, setSelectedHeatmap]);

  const handleSelectDensityZoning = () => {
    if (densityZoning.data) {
      setMapType(PlotMapType.Zoning);
    }
  };

  const handleGenerateZoning = () => {
    if (selectedHeatmap) {
      generateZoning.mutate({
        imageDate: selectedHeatmap,
        imageryIndex: selectedImageryIndex,
        analysisId: Number(analysisId),
        zoneNumber: selectedZoneNumber,
      });
    } else {
      present({
        message: t('analysis:image_required'),
        duration: 1500,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
          },
        ],
      });
    }
  };

  // init selected imagery index
  useEffect(() => {
    if (analysis && analysis.analysisType && imageryIndexes.data) {
      if (analysis.analysisType === AnalysisType.Yield) {
        // If in yield analysis we select the index that was used in density analysis
        handleSelectDensityHeatmap();
      } else {
        // If in density analysis we select the first index
        setSelectedImageryIndex(imageryIndexes.data.list_indexes[0]);
      }
    }
  }, [analysis, handleSelectDensityHeatmap, imageryIndexes.data]);

  // redirect to measures page when zoning is generated
  useEffect(() => {
    if (generateZoning.isSuccess) {
      props.history.push(`/search/plots/${id}/analysis/${analysisId}/measures`);
    }
  }, [analysisId, generateZoning.isSuccess, props.history, id]);

  useEffect(() => {
    // set initial network status
    Network.getStatus().then((status) => {
      setNetworkConnected(status.connected);
    });

    // listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });
  }, []);

  return (
    <MobilePage
      defaultBackHref={`/search/plots/${id}`}
      headerExtension={
        <>
          <PlotToolbar plotId={Number(id)} />
          {analysis?.analysisType === AnalysisType.Yield && (
            <MeasuresToolbar
              status={analysis?.analysis.status}
              title={t(`plot:yield_analysis`)}
            />
          )}
        </>
      }
    >
      <PlotMap plot={plot.data} {...mapProps}>
        {selectedImageryIndex !== 'RGB' && (
          <>
            <div className={`${styles['container-legend']} mapboxgl-ctrl`}>
              <IonToggle
                checked={checked}
                labelPlacement="end"
                onIonChange={handleLegend}
              >
                {t('map:legend')}
              </IonToggle>
            </div>

            {checked && (
              <div
                className={`${styles['container-color-scale']} mapboxgl-ctrl`}
                style={{
                  maxHeight: '200px',
                  overflowY: 'hidden',
                  overflowX: 'hidden',
                  width: '180px',
                  position: 'relative',
                  bottom: '150px',
                }}
              >
                <ColorScale
                  colorGradient={colorbar[0].color}
                  hetero={selectedHeatmapFeature?.heterogeneity}
                  max={colorbar[0].max}
                  min={colorbar[0].min}
                  mobile={false}
                />
              </div>
            )}
          </>
        )}
      </PlotMap>

      <SheetModal
        breakpoints={[initialBreakpoint, canGenerate ? 0.75 : 0.5]}
        initialBreakpoint={initialBreakpoint}
        locationRegex={/^\/search\/plots\/(\w+)\/analysis\/(\w+)\/zoning$/}
      >
        <IonContent>
          <SelectImageSlider
            options={heatmapsAsOptions}
            value={selectedHeatmap}
            onChange={handleSelectHeatmap}
          />

          <div className={classNames(styles['zoning-tools'], 'ion-padding')}>
            {imageryIndexesAsOptions.length ? (
              <PfSelect
                data-testid="select-image-index"
                defaultValue={imageryIndexesAsOptions[0].value}
                label={t('analysis:image_indexes_placeholder') as string}
                options={imageryIndexesAsOptions}
                value={selectedImageryIndex}
                wrapperClassName={styles['select-wrapper']}
                onChange={handleSelectImageryIndex}
              />
            ) : (
              <div />
            )}

            {canGenerate ? (
              <PfSelect
                ariaLabel={t('analysis:select_zones_placeholder') as string}
                interface="popover"
                options={zoneNumber}
                placeholder={t('analysis:select_zones_placeholder') as string}
                wrapperClassName={styles['select-wrapper']}
                onChange={setSelectedZoneNumber}
              />
            ) : null}

            {analysis?.analysisType === AnalysisType.Yield ? (
              <IonButton
                expand="block"
                size="large"
                onClick={handleSelectDensityZoning}
              >
                {t('analysis:select_density_heatmap')}
              </IonButton>
            ) : null}

            {canGenerate && selectedImageryIndex !== 'RGB' ? (
              <IonButton
                disabled={generateZoning.isLoading || !networkConnected}
                expand="block"
                size="large"
                onClick={handleGenerateZoning}
              >
                {t('analysis:generate_zoning')}
              </IonButton>
            ) : null}
          </div>
        </IonContent>
      </SheetModal>
    </MobilePage>
  );
}

export default Zoning;
