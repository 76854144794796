import { Geolocation, Position } from '@capacitor/geolocation';
import { Motion } from '@capacitor/motion';
import { Map, Marker } from 'mapbox-gl';

enum MapboxGeolocateState {
  Waiting = 'waiting',
  Active = 'active',
  Disabled = 'disabled',
}

// mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-waiting mapboxgl-ctrl-geolocate-active
export class MapboxGeolocateControl {
  _map: Map | undefined;
  _container: HTMLElement | undefined;
  _marker: Marker | undefined;
  button: HTMLButtonElement | undefined;
  spanIcon: HTMLSpanElement | undefined;

  _heading = 0;

  isGeolocating = false;
  watchId: string | undefined;

  onAdd(map: Map) {
    this._map = map;
    // control container
    this._container = document.createElement('div');

    // control button
    this.button = document.createElement('button');
    this.button.className = 'mapboxgl-ctrl-geolocate';
    this.button.ariaLabel = 'Find my location';
    this.button.addEventListener('click', () => {
      if (this.isGeolocating) {
        this.clearWatch();
        if (this.button) {
          this.button.className = 'mapboxgl-ctrl-geolocate';
        }
        this._marker?.remove();
        this.isGeolocating = false;
      } else {
        this.setButtonState(MapboxGeolocateState.Waiting);
        this.geolocate();
      }
    });

    // control icon
    this.spanIcon = document.createElement('span');
    this.spanIcon.className = 'mapboxgl-ctrl-icon';
    this.spanIcon.title = 'Find my location';
    this.spanIcon.ariaHidden = 'true';

    // add to dom
    this.button.appendChild(this.spanIcon);
    this._container.appendChild(this.button);
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);

    this._map = undefined;
  }

  setButtonState(state: MapboxGeolocateState) {
    if (this.button) {
      switch (state) {
        case MapboxGeolocateState.Waiting:
          this.button.className = `mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-waiting`;
          break;
        case MapboxGeolocateState.Active:
          this.button.className = `mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-active`;
          break;
        case MapboxGeolocateState.Disabled:
          this.button.className = `mapboxgl-ctrl-geolocate`;
          this.button.disabled = true;
          break;
        default:
          break;
      }
    }
  }

  async geolocate() {
    let coordinates: Position | undefined;

    try {
      coordinates = await Geolocation.getCurrentPosition();
      this.setButtonState(MapboxGeolocateState.Active);
      this.isGeolocating = true;
    } catch (error) {
      this.setButtonState(MapboxGeolocateState.Disabled);
      return;
    }

    Motion.addListener('orientation', (e) => {
      this._heading = e.alpha;
      this.updateMarkerRotation();
    });

    const uel = document.createElement('div');
    uel.className =
      'mapboxgl-user-location mapboxgl-marker mapboxgl-marker-anchor-center mapboxgl-user-location-show-heading';
    const locationDot = document.createElement('div');
    locationDot.classList.add('mapboxgl-user-location-dot');
    uel.appendChild(locationDot);

    const locationHeading = document.createElement('div');
    locationHeading.classList.add('mapboxgl-user-location-heading');
    uel.appendChild(locationHeading);

    if (this._map) {
      this._marker = new Marker({
        element: uel,
        rotationAlignment: 'map',
        pitchAlignment: 'map',
      })
        .setLngLat([coordinates.coords.longitude, coordinates.coords.latitude])
        .addTo(this._map);

      // fly to user location
      this._map.flyTo({
        center: [coordinates.coords.longitude, coordinates.coords.latitude],
        zoom: 15,
        bearing: this._heading,
      });

      try {
        this.watchId = await Geolocation.watchPosition(
          { enableHighAccuracy: true },
          (position, err) => {
            if (position) {
              this._marker?.setLngLat([
                position.coords.longitude,
                position.coords.latitude,
              ]);
            }
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  updateMarkerRotation() {
    if (this._marker) {
      this._marker.setRotation(this._heading);
    }
  }

  async clearWatch() {
    if (this.watchId) {
      await Geolocation.clearWatch({ id: this.watchId });
      this.watchId = undefined;
    }
  }
}

export default MapboxGeolocateControl;
