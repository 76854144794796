import { Summary } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

import styles from './analysis-summary.module.css';

/* eslint-disable-next-line */
export interface AnalysisSummaryProps {
  summary: Summary;
}

export function AnalysisSummary(props: AnalysisSummaryProps) {
  const { i18n } = useTranslation([], { useSuspense: false });
  const locale = i18n.language as 'fr' | 'en';

  return (
    <ul className={styles['container']}>
      {props.summary.map((item, index) => (
        <li key={index}>
          <span>
            {item.label[locale]} : {item.value || '-'}
          </span>
          {item.theoric ? <span>(th: {item.theoric})</span> : null}
        </li>
      ))}
    </ul>
  );
}

export default AnalysisSummary;
