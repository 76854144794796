import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { zoningApi } from '../../apis/zoning-api';

export const useUploadCropPicture = () => {
  const { t } = useTranslation(['analysis'], { useSuspense: false });
  const mutation = useMutation({
    mutationKey: ['uploadCropPicture'],
    mutationFn: zoningApi.uploadCropPicture,
    onMutate: () => {
      return {
        notification: {
          message: t('analysis:upload_success'),
          color: 'green',
        },
      };
    },
  });

  return mutation;
};

export default useUploadCropPicture;
