// eslint-disable-next-line @typescript-eslint/no-unused-vars
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Network } from '@capacitor/network';
import { IonApp, useIonToast } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
import { LoginModal } from '@lidea/auth/mobile/feature-modal';
import { MapTab } from '@lidea/search/mobile/feature-map-tab';
import { zoningApi } from '@lidea/shared/data-access/analysis';
import { ProtectedRoute } from '@lidea/shared/feature-protected-route';
import { MobileTabsLayout } from '@lidea/shared/ui/layout';
import { useDisclosure, useQueue } from '@mantine/hooks';
import { NotificationProps, Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MutationCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';

import styles from './app.module.css';
import { ErrorBoundaryComponent } from './components/error-boundary/error-boundary';
// import ErrorBoundary from './components/error-boundary/error-boundary';
import ReloadPrompt from './components/reload-prompt/reload-prompt';
import EditPlot from './pages/edit-plot/edit-plot';
import ListTab from './pages/list-tab/list-tab';
import Measures from './pages/measures/measures';
import PlotDetails from './pages/plot-details/plot-details';
import Zoning from './pages/zoning/zoning';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // disable the Sentry SDK for development
  enabled:
    process.env.NODE_ENV !== 'test' && import.meta.env.MODE !== 'development',
});

setupIonicReact({
  mode: 'md',
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// Create a client

// set a default mutation function so that paused mutations can resume after a page reload

export function App() {
  const { t, i18n } = useTranslation(['common'], { useSuspense: false });
  const [isModalOpen, modalHandlers] = useDisclosure(false);
  const [present] = useIonToast();
  const { state, add, update } = useQueue<NotificationProps>({
    initialValues: [],
    limit: 1,
  });

  const queryClient = useMemo(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          networkMode: 'offlineFirst',
          cacheTime: Infinity,
        },
      },
      mutationCache: new MutationCache({
        onSuccess: (_, __, context: any) => {
          if (context && context.notification) {
            add(context.notification);
          } else {
            add({
              message: 'Mutation completed successfully',
              color: 'green',
            });
          }
        },
        onError: (error: any) => {
          add({
            message: error.message || 'Mutation failed',
            color: 'red',
          });
        },
      }),
    });

    queryClient.setMutationDefaults(['updateMeasure'], {
      mutationFn: zoningApi.updateMeasure,
    });

    return queryClient;
  }, []);

  const handleProtection = () => {
    modalHandlers.open();
  };

  const handleLoginModalClose = () => {
    modalHandlers.close();
  };

  useEffect(() => {
    if (state[0]?.message) {
      present({
        message: state[0]?.message as string,
        duration: 1500,
        onDidDismiss: () => {
          update((prev) => prev.filter((n) => n.message !== state[0]?.message));
        },
      });
    }
  }, [state]);

  useEffect(() => {
    Network.addListener('networkStatusChange', (status) => {
      add({
        message: status.connected ? t('common:online') : t('common:offline'),
      });
    });
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      document.documentElement.setAttribute('lang', lng);
    });
    document.documentElement.setAttribute('lang', i18n.language);

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={async () => {
        const networkStatus = await Network.getStatus();
        if (networkStatus.connected) {
          // resume mutations after initial restore from localStorage was successful
          queryClient.resumePausedMutations();
        }
      }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      <Notifications />
      <ReloadPrompt />
      <Sentry.ErrorBoundary fallback={ErrorBoundaryComponent}>
        <IonApp>
          <MobileTabsLayout>
            {/* START: routes */}
            <ProtectedRoute
              handleProtection={handleProtection}
              path="/map"
              exact
            >
              <MapTab />
            </ProtectedRoute>

            <ProtectedRoute
              component={EditPlot}
              handleProtection={handleProtection}
              path="/search/plots/:id/edit"
              exact
            ></ProtectedRoute>

            <ProtectedRoute
              component={Zoning}
              handleProtection={handleProtection}
              path="/search/plots/:id/analysis/:analysisId/Zoning"
              exact
            ></ProtectedRoute>

            <ProtectedRoute
              component={Measures}
              handleProtection={handleProtection}
              path="/search/plots/:id/analysis/:analysisId/measures"
              exact
            ></ProtectedRoute>

            <ProtectedRoute
              component={PlotDetails}
              handleProtection={handleProtection}
              path="/search/plots/:id"
              exact
            ></ProtectedRoute>

            <ProtectedRoute
              handleProtection={handleProtection}
              path="/search"
              exact
            >
              <ListTab />
            </ProtectedRoute>

            <Route path="/" exact>
              <Redirect to="/map" />
            </Route>
            {/* END: routes */}
          </MobileTabsLayout>
          <LoginModal close={handleLoginModalClose} isOpen={isModalOpen} />
        </IonApp>
      </Sentry.ErrorBoundary>
    </PersistQueryClientProvider>
  );
}

export default App;
