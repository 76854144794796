import { plotKeys } from '@lidea/shared/data-access/plots';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { zoningApi } from '../../apis/zoning-api';

export interface UseGenerateZoningProps {
  plotId: number;
} 
export interface GenerateZoningMutationData {
  imageryIndex: string;
  imageDate: string;
  analysisId: number;
  zoneNumber: string;
}

export function useGenerateZoning(props: UseGenerateZoningProps) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: GenerateZoningMutationData) =>
      zoningApi.generateZoning(
        data.imageryIndex,
        data.imageDate,
        data.analysisId,
        data.zoneNumber
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: plotKeys.detail(props.plotId),
      });
    },
  });

  return mutation;
}

export default useGenerateZoning;
