import { SelectedFilters } from '@lidea/shared/data-access/core';
import { PlotPageResult } from '@lidea/shared/util/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { plotsApi } from '../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// get page query param in url
const getPageQueryParam = (url: string) => {
  const params = new URL(url).searchParams;
  const page = params.get('page');

  return page;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseInfinitePlotsProps {
  selectedFilters: SelectedFilters;
  enabled?: boolean;
}

export function useInfinitePlots(props: UseInfinitePlotsProps) {
  const infiniteQuery = useInfiniteQuery<PlotPageResult>({
    queryKey: plotKeys.search(props.selectedFilters),
    queryFn: ({ pageParam = 1 }) =>
      plotsApi.getAll(props.selectedFilters, pageParam),
    getNextPageParam: (lastPage) =>
      lastPage.next ? getPageQueryParam(lastPage.next) : undefined,
    getPreviousPageParam: (prevPage) =>
      prevPage.previous ? getPageQueryParam(prevPage.previous) : undefined,
    enabled: props.enabled,
  });

  return infiniteQuery;
}

export default useInfinitePlots;
