import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import classNames from 'classnames';
import { funnelOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './search-toolbar.module.css';

type IonSearchbarProps = React.ComponentProps<typeof IonSearchbar>;
type IonButtonProps = React.ComponentProps<typeof IonButton>;

/* eslint-disable-next-line */
export interface SearchToolbarProps {
  onSearchTermChange?: IonSearchbarProps['onIonInput'];
  onFilterClick?: IonButtonProps['onClick'];
  filtersCount?: number;
}

export function SearchToolbar(props: SearchToolbarProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const filtersCount = props.filtersCount || 0;
  return (
    <IonToolbar>
      <IonSearchbar
        className={classNames(styles.searchbar, 'ion-padding-start')}
        placeholder={t('common:searchbar_placeholder') as string}
        onIonInput={props.onSearchTermChange}
      ></IonSearchbar>
      <IonButtons slot="end">
        <IonButton onClick={props.onFilterClick}>
          <IonIcon
            aria-label="open filters"
            color="primary"
            icon={funnelOutline}
            slot="icon-only"
          ></IonIcon>
        </IonButton>
        {filtersCount > 0 && (
          <IonBadge
            className={styles['filters-count']}
            color="warning"
            style={{ marginLeft: 12 }}
          >
            {filtersCount}
          </IonBadge>
        )}
      </IonButtons>
    </IonToolbar>
  );
}

export default SearchToolbar;
