import { IonToggle } from '@ionic/react';
import { Portal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import styles from './draw-control-toggle.module.css';

/* eslint-disable-next-line */
export interface DrawControlToggleProps {
  position?: string;
  setIsChecked: (checked: boolean) => void;
}

export function DrawControlToggle(props: DrawControlToggleProps) {
  const { t } = useTranslation(['plot'], { useSuspense: false });
  const { current: map } = useMap();

  const handleChange = (event: CustomEvent) => {
    props.setIsChecked(event.detail.checked);
  };

  return (
    <Portal
      container={
        map
          ?.getContainer()
          .getElementsByClassName(
            `mapboxgl-ctrl-${props.position || 'top-right'}`
          )[0]
        // document && document.getElementsByClassName('mapboxgl-ctrl-top-right')[0]
      }
    >
      <div className={`${styles['container']} mapboxgl-ctrl`}>
        <IonToggle labelPlacement="end" onIonChange={handleChange}>
          {t('plot:modify_border')}
        </IonToggle>
      </div>
    </Portal>
  );
}

export default DrawControlToggle;
