import { Farm } from './filters.types';

export enum ZoningStatus {
  Ready = '#80FF00',
  InProgress = '#FFA500',
  ToBeDone = '#FF0000',
}

export enum AnalysisType {
  Density = '1',
  Yield = '2',
}

export enum AnalysisStep {
  Zoning = 'zoning',
  Measures = 'measures',
}

export type Summary = {
  label: { en: string; fr: string };
  value: number | string | null;
  theoric?: number | null;
}[];

export type BaseAnalysis = {
  id: number;
  plot: {
    id: number;
    variety: {
      id: number;
      name: string;
      a: number;
      b: number;
      PMG: number;
      PMD: number;
      theoric_density: number;
      species: {
        id: number;
        name: string;
        fieldname_1_1: string;
        fieldname_2_1: string;
        fieldname_2_2: string;
        fieldname_2_3: string;
        group: number;
      };
    };
  };
  status: ZoningStatus;
  zoning: {
    id: number;
    raster_date: string;
    index: string;
  };
  summary: Summary;
};

export type YieldAnalysis = BaseAnalysis & {
  number: AnalysisType.Yield;
};

export type Protocol = {
  id: number;
  sowing_date: string;
  sowing_date_label: string;
  contract_code: string;
  irrigation: boolean;
  contract_area: number;
  NFML: number;
};

export type PlotFeatureProperties = {
  name: string;
  rasters: string[];
  bbox: number[][];
  species_group: { id: number; name: string };
  species: { id: number; name_fr: string; name_en: string };
  variety: { id: number; name_fr: string; name_en: string };
  generation: { id: number; name_fr: string; name_en: string };
  color: string;
  farm: Farm;
  manager: { id: number; first_name: string; last_name: string };
  area: number;
  protocol: Protocol;
  analyzes: [YieldAnalysis];
  estimatedYield: number | null;
  computed_yield: number | null;
  yield_doses: number | null;
  objYield: number | null;
  percentYield: number | null;
  is_merged: boolean;
  active: boolean;
  //colorbar?: {min: number, max: number, gradient: string[]};
  //coordinates: number[][];
};

export type PlotFeature = GeoJSON.Feature<
  GeoJSON.MultiPolygon,
  PlotFeatureProperties
> & {
  id: number;
};

// export type PlotFeature2 = GeoJSON.Feature<
//   GeoJSON.MultiPolygon,
//   PlotFeatureProperties
// > & {
//   type: 'FeatureCollection';
//   features: Array<PlotFeature>;
// };

export type PlotFeatureCollection = {
  features: any;
  plots: { type: 'FeatureCollection'; features: Array<PlotFeature> };
  colorbar?: { min: number; max: number; color: string[] };
  // plots: Array<PlotFeature2>;
  // colorbar: {min: number, max: number, color: string[]};
};

export type PlotsClusterProperties = {
  name?: string;
  rasters?: string[];
  count?: number;
  bbox?: number[];
  //colorbar?: string[];
};

export type PlotsClusterFeature = GeoJSON.Feature<
  GeoJSON.Point,
  PlotsClusterProperties
>;

export type PlotsClusterFeatureCollection = {
  plots: { type: 'FeatureCollection'; features: Array<PlotFeature> };
  colorbar?: { min: number; max: number; color: string[] };
  // plots: Array<PlotsClusterFeature>;
  // colorbar: {min: number, max: number, color: string[]};
};

export type PlotPageResult = {
  count: number;
  next: string | null;
  previous: string | null;
  results: PlotFeatureCollection;
};

export type PlotRowData = {
  id: number;
  name: string;
  farm: string;
  area: number;
  species: string;
  variety: string;
  satellite_map: ZoningStatus;
  yield_analysis: ZoningStatus;
  yield: number;
};

export type PlotsTableData = PlotRowData[];

export type AnalysisStatuses = [
  {
    id: number;
    plot: number;
    status: 'Running' | 'Finished';
  }
];

export type ImportGeometryFile = {
  id: number;
  file: string;
  file_type: string;
  geom: GeoJSON.MultiPolygon;
};
