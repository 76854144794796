import { IonBadge, IonItem, IonTitle, IonToolbar } from '@ionic/react';
import { ZoningStatus } from '@lidea/shared/util/types';

import styles from './measures-toolbar.module.css';

/* eslint-disable-next-line */
export interface MeasuresToolbarProps {
  title: string;
  status?: ZoningStatus;
}

export function MeasuresToolbar(props: MeasuresToolbarProps) {
  return (
    <IonToolbar>
      <IonTitle slot="start">{props.title}</IonTitle>
      <IonItem lines="none" slot="end">
        <IonBadge
          className={styles.status}
          style={{ backgroundColor: props.status }}
        >
          {' '}
        </IonBadge>
      </IonItem>
    </IonToolbar>
  );
}

export default MeasuresToolbar;
