import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import styles from './color-scale.module.css';
import chroma from 'chroma-js';

export interface ColorScaleProps {
  mobile?: boolean;
  min: number;
  max: number;
  hetero?: number;
  colorGradient: string[];
}
 
export function ColorScale(props: ColorScaleProps) {
  const { t } = useTranslation(['map', 'plot'], { useSuspense: false });
  const canvasRefMobile = useRef(null);
  const canvasRefDesktop = useRef(null);

  const drawColorScale = (ctx, height) => {
    const scale = chroma.scale(props.colorGradient).domain([props.min , props.max]).mode('lab').colors(500);
    const scaleReverse = scale.reverse();
    scaleReverse.forEach((color, i) => {
      ctx.fillStyle = color;
      ctx.fillRect(0, i * (height / 500), 25, height / 500); 
    });
  };

  useEffect(() => {
    const ctxMobile = canvasRefMobile.current?.getContext('2d');
    if (ctxMobile) {
      drawColorScale(ctxMobile, 180);
    }
  }, [props.colorGradient, props.min, props.max]); 

  useEffect(() => {
    const ctxDesktop = canvasRefDesktop.current?.getContext('2d');
    if (ctxDesktop) {
      drawColorScale(ctxDesktop, 110);
    }
  }, [props.colorGradient, props.min, props.max]);  

  //const scaleValues = [props.min, props.max / 4, props.max / 2, (props.max * 3) / 4, props.max];
  let scaleValues;
  if (props.min === -1 && props.max === 0) {
    scaleValues = [props.min, (props.min * 3) / 4, props.min / 2, props.min / 4, props.max];
  } else {
    scaleValues = [props.min, props.max / 4, props.max / 2, (props.max * 3) / 4, props.max];
  }
  const reversedScaleValues = [...scaleValues].reverse();

  return (
    <div className={styles['container']}>
      {props.mobile == true ? (
        <>
          <div className={styles['title-echelle-mobile']}>
              {t('map:satellite_map')}
          </div>

          {props.hetero && (
            <div className={styles['hetero-mobile']}>
              <strong>{t('plot:heterogeneity_index')}</strong> : {props.hetero}
            </div>
          )}

          <div className={styles['echelle-mobile']}>
            <div className={styles['scale-values-mobile']}>
              {reversedScaleValues.map((value, index) => (
                <div key={index}>{value === 0 ? value : value < 1 ? value.toFixed(2) : value}</div>
              ))}
            </div>

            <div className={styles['color-mobile']}>
              <canvas ref={canvasRefMobile} width={25} height={180} />
            </div>
          </div>
        </>
      ):(
        <>
          <div className={styles['title-echelle-desktop']}>
              {t('map:satellite_map')}
          </div>

          {props.hetero && (
            <div className={styles['hetero-desktop']}>
              <strong>{t('plot:heterogeneity_index')}</strong> : {props.hetero}
            </div>
          )}

          <div className={styles['echelle-desktop']}>
            <div className={styles['scale-values-desktop']}>
              {reversedScaleValues.map((value, index) => (
                <div key={index}>{value === 0 ? value : value < 1 ? value.toFixed(2) : value}</div>
              ))}
            </div>

            <div className={styles['color-desktop']}>
              <canvas ref={canvasRefDesktop} width={25} height={110} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ColorScale;

