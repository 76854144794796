import { IonButton, useIonModal } from '@ionic/react';
import { MeasureSummary } from '@lidea/shared/util/types';
import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import CommentsModal from './../comments-modal/comments-modal';
import styles from './point-modal.module.css';

/* eslint-disable-next-line */
export interface PointModalProps {
  analysisId: number;
  pointNumber?: number;
  onClickInput: () => void;
  summary: MeasureSummary;
}

export function PointModal(props: PointModalProps) {
  const { t, i18n } = useTranslation(['common'], { useSuspense: false });
  const [presentComments, dismissComments] = useIonModal(CommentsModal, {
    analysisId: props.analysisId,
    dismiss: (data: string, role: string) => dismissComments(data, role),
  });

  const locale = i18n.language as 'fr' | 'en';

  return (
    <div className="inner-content ion-padding">
      <h2>Point {props.pointNumber}</h2>

      <div
        style={{
          marginBottom: 8,
        }}
      >
        {props.summary.map((measure) => (
          <div key={measure.label.fr}>
            {measure.label[locale]} : {measure.value || '-'}{' '}
            <span> {measure.theoric ? `th: ${measure.theoric}` : null}</span>
          </div>
        ))}
      </div>

      <Grid gutter="xs">
        <Grid.Col span={6}>
          <IonButton expand="block" onClick={props.onClickInput}>
            {t('common:input')}
          </IonButton>
        </Grid.Col>
        <Grid.Col span={6}>
          <IonButton
            color="secondary"
            expand="block"
            onClick={() => {
              presentComments({
                cssClass: 'page-modal',
              });
            }}
          >
            {t('common:comment')}
          </IonButton>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default PointModal;
