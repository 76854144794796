import { IonBadge, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { FiltersOptions } from '@lidea/shared/util/types';
import { caretDown } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

type Values = FiltersOptions[keyof FiltersOptions];

export type FilterProps = Values & {
  selected: number;
  onClick: () => void;
};

export const Filter = ({ label, value, selected, onClick }: FilterProps) => {
  const { t } = useTranslation(['plot'], { useSuspense: false });
  return (
    <IonItem
      color="primary"
      data-testid={value}
      lines="none"
      style={{ '--border-radius': '4px' }}
      button
      onClick={onClick}
    >
      <IonLabel>{t(`plot:${label}`)}</IonLabel>
      {selected > 0 ? (
        <IonBadge color="warning" slot="end">
          {selected}
        </IonBadge>
      ) : (
        <IonIcon icon={caretDown} slot="end"></IonIcon>
      )}
    </IonItem>
  );
};
