import 'dayjs/locale/fr';

import { IonButton, IonIcon } from '@ionic/react';
import { PfInput, PfSelect } from '@lidea/shared/ui/form';
import { ProtocolFormValues } from '@lidea/shared/util/types';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { chevronDown } from 'ionicons/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './protocol-form.module.css';

const protocolFields = ['contract_area'];

/* eslint-disable-next-line */
export interface ProtocolFormProps {
  initialValues?: ProtocolFormValues;
  contractCode?: string;
  onSubmit: (event: ProtocolFormValues) => void;
  onBack?: () => void;
  onAdd?: (event: ProtocolFormValues) => void;
  onCancel?: () => void;
  i18n: {
    confirm: string;
    cancel: string;
    back?: string;
    add?: string;
  };
}

export function ProtocolForm(props: ProtocolFormProps) {
  const { t } = useTranslation(['common', 'plot'], { useSuspense: false });
  const initialValues = useMemo(() => {
    if (props.initialValues) {
      return {
        ...props.initialValues,
        contract_code:
          props.contractCode || props.initialValues.contract_code || undefined,
        sowing_date: props.initialValues.sowing_date
          ? dayjs(props.initialValues.sowing_date).toDate()
          : undefined,
      };
    }
    return { contract_code: props.contractCode };
  }, [props.initialValues, props.contractCode]);

  const form = useForm<ProtocolFormValues>({
    initialValues,
  });

  const handleSubmit = (values: ProtocolFormValues, isAdd: boolean) => {
    const formattedValues = {
      ...values,
      sowing_date: values.sowing_date
        ? dayjs(values.sowing_date).format('YYYY-MM-DD')
        : undefined,
    };
    if (isAdd && props.onAdd) {
      // Add null check for props.onAdd
      props.onAdd(formattedValues);
    } else {
      props.onSubmit(formattedValues);
    }
  };

  return (
    <form
      className={styles['container']}
      onSubmit={form.onSubmit((values) => {
        handleSubmit(values, props.isAdd);
      })}
    >
      <div className={styles['fields-container']}>
        <DatePickerInput
          label={t('plot:sowing_date') as string}
          locale="fr"
          placeholder={t(`plot:select_date_placeholder`) as string}
          rightSection={<IonIcon icon={chevronDown} />}
          styles={{
            label: {
              fontSize: 'inherit',
              paddingLeft: 16,
              color: 'inherit',
            },
            input: {
              borderColor: 'var(--ion-color-primary)',
              height: 40,
              backgroundColor: 'transparent',
              color: 'inherit',
            },
          }}
          valueFormat="D MMMM, YYYY"
          {...form.getInputProps('sowing_date')}
        />

        <PfInput
          fill="outline"
          height="small"
          label={t(`plot:contract_code`) as string}
          labelPlacement="stacked"
          placeholder={t(`plot:input_placeholder`) as string}
          {...form.getInputProps('contract_code')}
        />

        <PfInput
          fill="outline"
          height="small"
          label={t(`plot:NFML`) as string}
          labelPlacement="stacked"
          placeholder={t(`plot:input_placeholder`) as string}
          {...form.getInputProps('NFML')}
        />

        <PfSelect
          label={t('plot:irrigation') as string}
          options={[
            {
              value: true,
              label: t('common:yes'),
            },
            {
              value: false,
              label: t('common:no'),
            },
          ]}
          placeholder={t(`plot:select_placeholder`) as string}
          {...form.getInputProps('irrigation')}
        />

        {protocolFields.map((field) => (
          <PfInput
            key={field}
            fill="outline"
            height="small"
            label={t(`plot:${field}`) as string}
            labelPlacement="stacked"
            placeholder={t(`plot:input_placeholder`) as string}
            step="any"
            type="number"
            {...form.getInputProps(field)}
          />
        ))}
      </div>
      {props.i18n.add && (
        <div className={styles['add-buttons-container']}>
          <IonButton color="warning" type="submit">
            {props.i18n.add}
          </IonButton>
        </div>
      )}

      <div className={styles['buttons-container']}>
        <input style={{ display: 'none' }} type="submit" />

        <IonButton color="danger" onClick={props.onCancel}>
          {props.i18n.cancel}
        </IonButton>
        {props.onBack ? (
          <IonButton color="medium" onClick={props.onBack}>
            {props.i18n.back}
          </IonButton>
        ) : null}
        <IonButton color="success" type="submit">
          {props.i18n.confirm}
        </IonButton>
      </div>
    </form>
  );
}

export default ProtocolForm;
