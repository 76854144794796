import { useMutation, useQueryClient } from '@tanstack/react-query';

import { plotsApi } from './../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseResetAnalysisProps {
  plotId: number;
}

export function useResetAnalysis(props: UseResetAnalysisProps) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: plotsApi.resetAnalysis,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: plotKeys.detail(props.plotId),
      });
    },
  });

  return mutation;
}

export default useResetAnalysis;
