import { Network } from '@capacitor/network';
import { PointsFeatureCollection } from '@lidea/shared/util/types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';

import styles from './points-markers.module.css';

/* eslint-disable-next-line */
export interface PointsMarkersProps {
  points: PointsFeatureCollection;
  validatedPoints?: number[];
  mobile?: boolean;
  onPointClick: (id: number) => void;
  selectedPointId?: number;
}

export function PointsMarkers(props: PointsMarkersProps) {
  const [networkConnected, setNetworkConnected] = useState(true);

  useEffect(() => {
    // set initial network status
    Network.getStatus().then((status) => {
      setNetworkConnected(status.connected);
    });

    // listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });
  }, []);

  return (
    <>
      {props.points.features.map((point) => (
        <Marker
          key={point.id}
          anchor="center"
          latitude={point.geometry.coordinates[1]}
          longitude={point.geometry.coordinates[0]}
          onClick={() => props.onPointClick(point.id)}
        >
          <button
            aria-label={`point ${point.properties.zone}`}
            className={classNames(styles['marker'], 'marker', {
              [styles['marker--selected']]: props.selectedPointId === point.id,
            })}
            style={{
              backgroundColor: 
                !networkConnected  && 
                props.mobile &&
                props.validatedPoints?.includes(point.id) ?
                "#0000FF" :
                point.properties.status
            }}
          >
            {point.properties.zone}
          </button>
        </Marker>
      ))}
    </>
  );
}

export default PointsMarkers;
