import { useQuery } from '@tanstack/react-query';

import { plotKeys } from '../../keys/plot-keys';
import { plotsApi } from './../../apis/plots-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCanMergePlotsProps {
  plotsIds: number[];
  all: boolean;
}

export function useCanMergePlots(props: UseCanMergePlotsProps) {
  const query = useQuery({
    queryKey: plotKeys.canMerge(props.plotsIds),
    queryFn: () =>
      plotsApi.canMerge({
        plotsIds: props.plotsIds,
        all: props.all,
      }),
      enabled: props.plotsIds.length === 0 && props.all === false ? false : true,
    });

  return query;
}

export default useCanMergePlots;
