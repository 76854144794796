import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreatePlotData } from '../use-create-plot/use-create-plot';
import { plotsApi } from './../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseUpdatePlotProps {}

export type UpdatePlotData = Partial<CreatePlotData>;

interface MutationProps {
  plotId: number;
  data: UpdatePlotData; 
}

export function useUpdatePlot() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updatePlot'],
    mutationFn: ({ plotId, data }: MutationProps) =>
      plotsApi.update(plotId, data),
    onSuccess: (data) => {      
      queryClient.invalidateQueries({
        queryKey: plotKeys.detail(data.success),
      });

      queryClient.invalidateQueries({
        queryKey: plotKeys.inBound(),
      });
    },
  });

  return mutation;
}

export default useUpdatePlot;
