import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { zoningApi } from '../../apis/zoning-api';
import { analysisKeys } from './../../keys/analysis-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMeasuresProps {
  analysisId: number;
}

export const measuresQuery = (analysisId: number) => ({
  queryKey: analysisKeys.measures(analysisId),
  queryFn: () => zoningApi.getMeasures(analysisId),
});

export function useMeasures(props: UseMeasuresProps) {
  const [poll, setPoll] = useState(false);
  const query = useQuery({
    ...measuresQuery(props.analysisId),
    refetchInterval: poll ? 2000 : false,
  });

  useEffect(() => {
    if (!query.data) {
      return;
    }

    // get all dynamic measures
    const dynamics = Object.values(query.data)
      .map((measure) => measure.dynamic)
      .flat();

    // if one of dynamic measure has image_processing == true, set poll to true
    setPoll(dynamics.some((dynamic) => dynamic.image_processing));
  }, [query.data]);

  return query;
}

export default useMeasures;
