import {
  MeasureForm,
  MeasureFormValues,
} from '@lidea/analysis/desktop/shared/ui';
import { ModalPage } from '@lidea/shared/ui/layout';
import { Measure } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

interface MeasureModalProps {
  analysisId: string;
  plotId: string;
  measure: Measure;
  zone: number;
  onSubmit: (data: MeasureFormValues) => void;
  dismiss: () => void;
}

const MeasureModal: React.FC<MeasureModalProps> = (props) => {
  const { t } = useTranslation(['common', 'plot', 'analysis'], {
    useSuspense: false,
  });

  return (
    <ModalPage
      title={`${t(`plot:yield_analysis`)} - Point ${props.zone}`}
      onClose={props.dismiss}
    >
      {props.measure ? (
        <MeasureForm
          analysisId={props.analysisId}
          fields={{
            static: props.measure.static,
            dynamic: props.measure.dynamic,
          }}
          i18n={{
            submit: t('common:validate'),
          }}
          measureId={props.measure.id}
          plotId={props.plotId}
          submitExpand="block"
          onSubmit={props.onSubmit}
        />
      ) : null}
    </ModalPage>
  );
};

export default MeasureModal;
