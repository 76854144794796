import { PlotFeatureCollection } from '@lidea/shared/util/types';
import { Expression } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

/* eslint-disable-next-line */
export interface PlotsLayerProps {
  data: PlotFeatureCollection;
  id?: string;
  minZoom?: number;
  outline?: boolean;
  colorPolygon?: string;
  activePlotIds?: number[];
}

const polygonOutlineLayerStyle = {
  type: 'line' as const,
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export function PlotsLayer(props: PlotsLayerProps) {
  const { colorPolygon, activePlotIds,  ...restProps } = props;
  const polygonLayerStyle = {
    type: 'fill' as const,
    paint: {
      'fill-color': colorPolygon || ['get', 'color'] as Expression,
      'fill-opacity': 0.5,
    },
  };
  
  return (
    <Source data={props.data} id="plots-data" type="geojson">

      {!props.outline ? (
        <Layer id={props.id} minzoom={props.minZoom} {...polygonLayerStyle} />
      ) : null}
      <Layer
        id={`${props.id}_outline`}
        minzoom={props.minZoom}
        {...polygonOutlineLayerStyle}
      />
      {!props.outline ? (
        <Layer
          id={`${props.id}_symbol`}
          layout={{
            'text-field': [
              'format',
              ['string', ['get', 'name']],
              { 'font-scale': 0.7 },
            ],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          }}
          paint={{
            'text-color': '#fff',
            'text-halo-color': '#000',
            'text-halo-width': 1.2,         
          }}
          minzoom={props.minZoom}
          type="symbol"
        />
      ) : null}
    </Source>
  );
}

PlotsLayer.defaultProps = {
  id: 'plots-layer',
  minZoom: 0,
};

export default PlotsLayer;


