import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { SelectedFilters } from '@lidea/shared/data-access/core';
import { Filters, FiltersOptions } from '@lidea/shared/util/types';

import { Filter } from './filter/filter';

/* eslint-disable-next-line */
export interface SearchFiltersProps {
  filters: FiltersOptions;
  selectedFilters: SelectedFilters;
  onFilterClick: (filter: keyof Filters) => void;
}

// type that extend SearchFiltersProps['filters'][0] with onClick

export function SearchFilters(props: SearchFiltersProps) {
  return (
    <IonGrid>
      <IonRow>
        {Object.values(props.filters).map((filter) => (
          <IonCol key={filter.value} size="12" size-sm="6">
            <Filter
              {...filter}
              selected={props.selectedFilters[filter.value]?.length || 0}
              onClick={() => props.onFilterClick(filter.value)}
            />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
}

export default SearchFilters;
