import { IonContent, IonPage } from '@ionic/react';

import MobileHeader from './../mobile-header/mobile-header';

/* eslint-disable-next-line */
export interface MobilePageProps {
  children?: React.ReactNode;
  headerExtension?: JSX.Element;
  defaultBackHref?: string;
  backHref?: string;
}

export function MobilePage(props: MobilePageProps) {
  return (
    <IonPage>
      <MobileHeader
        defaultBackHref={props.defaultBackHref}
        headerExtension={props.headerExtension}
      />

      <IonContent>{props.children}</IonContent>
    </IonPage>
  );
}

export default MobilePage;
