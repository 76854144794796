import { useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import styles from './reload-prompt.module.css';

/* eslint-disable-next-line */
export interface ReloadPromptProps {}

export function ReloadPrompt(props: ReloadPromptProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const [present] = useIonToast();
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__';

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r && r.update();
      } else {
        // eslint-disable-next-line prefer-template,no-console
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
    },
  });

  useEffect(() => {
    const close = () => {
      setOfflineReady(false);
      setNeedRefresh(false);
    };

    if (offlineReady) {
      present({
        message: t('common:offline_ready'),
        duration: 3000,
        buttons: [
          {
            role: 'cancel',
            text: 'Ok',
            handler: close,
          },
        ],
      });
    }

    if (needRefresh) {
      present({
        message: t('common:new_version'),
        buttons: [
          {
            role: 'cancel',
            text: t('common:close'),
            handler: close,
          },
          {
            text: t('common:refresh'),
            handler: () => {
              updateServiceWorker(true);
            },
          },
        ],
      });
    }
  }, [
    needRefresh,
    offlineReady,
    present,
    setNeedRefresh,
    setOfflineReady,
    t,
    updateServiceWorker,
  ]);

  return null;
}

export default ReloadPrompt;
