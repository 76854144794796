import {
  cleanFilters,
  privateClient,
  SelectedFilters,
} from '@lidea/shared/data-access/core';
import {
  AnalysisStatuses,
  ImportGeometryFile,
  PlotFeature,
  PlotFeatureCollection,
  PlotPageResult,
  PlotsClusterFeatureCollection,
  YieldMap,
} from '@lidea/shared/util/types';

import { CreatePlotData } from '../hooks/use-create-plot/use-create-plot';
import { UpdateParamsPlotData } from '../hooks/use-update-params-plot/use-update-params-plot';
import { UpdatePlotData } from '../hooks/use-update-plot/use-update-plot';


export const plotsApi = {
  create: (data: CreatePlotData) => {
    return privateClient.post(`plots/`, { json: data }).json<PlotFeature>();
  },
  getAll: (filters: SelectedFilters, page = 1, sort?: string) => {
    const cleanedFilters = cleanFilters(filters);

    return privateClient
      .get('plots', {
        searchParams: {
          ...cleanedFilters,
          page: page ? page : 1,
          ordering: sort,
        },
      })
      .json<PlotPageResult>();
  },
  getMaxBounds: () => {
    return privateClient.get('plotmaxbounds').json<{ bbox: number[] }>();
  },
  getInBounds: (
    filters: SelectedFilters,
    bounds: mapboxgl.LngLatBounds,
    cluster: boolean,
    zoom: number,
    type?: string
  ) => {
    const cleanedFilters = cleanFilters(filters);

    // format bounds as xmin,ymin,xmax,ymax string
    const formattedBounds = bounds.toArray().flat();

    return privateClient
      .get('plotmap', {
        searchParams: {
          ...cleanedFilters,
          bbox: formattedBounds.join(','),
          cluster,
          zoom,
          type,
        },
      })
      .json<PlotsClusterFeatureCollection | PlotFeatureCollection>();
  },
  getOne: (id: number) => {
    return privateClient.get(`plots/${id}`).json<PlotFeature>();
  },
  update: (id: number, data: UpdatePlotData) => {
    return privateClient
      .post(`modify/`, {
        json: {
          plot_id: id,
          ...data,
        },
      })
      .json<PlotFeature>();
  },
  updateParams: (id: number, data: UpdateParamsPlotData) => {
    return privateClient
      .patch(`plots/${id}/`, { json: data })
      .json<PlotFeature>();
  },
  delete: (id: number) => {
    return privateClient.delete(`plots/${id}/`).json();
  },
  group: (ids: number[]) => {
    return privateClient.post(`plots/group`, { json: ids }).json();
  },
  ungroup: (id: number) => {
    return privateClient.post(`plots/ungroup`, { json: id }).json();
  },
  getAnalysisStatuses: (id: number) => {
    return privateClient
      .get(`requeststatus`, {
        searchParams: { plot: id },
      })
      .json<AnalysisStatuses>();
  },
  resetAnalysis: (props: { analysisId: number }) => {
    return privateClient.get(`analysis/${props.analysisId}/reset`).json();
  },
  getYieldMap: (props: { plotId: number }) => {
    return privateClient.get(`plots/${props.plotId}/yieldmap`).json<YieldMap>();
  },
  canMerge: (props: { plotsIds: number[]; all: boolean }) => {
    return privateClient
      .get('merge', {
        searchParams: {
          plots: `[${props.plotsIds.join(',')}]`,
          all: props.all,
        },
      })
      .json<{ can_be_merged: string }>();
  },
  merge: (props: {
    plotsIds: number[];
    forceMerge?: boolean;
    all: boolean;
  }) => {
    return privateClient
      .post('merge/', {
        json: {
          plots: props.plotsIds,
          all: props.all,
          force_merge: props.forceMerge,
        },
      })
      .json<{ merged_plot: number }>();
  },
  unmerge: (props: { plotId: number }) => {
    return privateClient
      .get('unmerge', {
        searchParams: { plot: props.plotId },
      })
      .json<{ success: boolean }>();
  },
  synthesis: (props: { plotsIds: number[]; all: boolean; selectedFilters?: SelectedFilters;  }) => {
    return privateClient
      .post('synthesis/', {
        json: {
          plots: props.all ? [] : props.plotsIds,
          all: props.all,
          selectedFilters: props.selectedFilters,
        },
      })
      .blob();
  },
  import: (props: { file: File; type: 'shp' | 'kml' }) => {
    const formData = new FormData();
    formData.append('file', props.file);
    formData.append('file_type', props.type);
    return privateClient
      .post('import/', {
        body: formData,
      })
      .json<ImportGeometryFile>();
  },
};
