import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SearchFilters } from '@lidea/search/shared/ui-filters';
import { PfTypeahead } from '@lidea/search/shared/ui-pf-typeahead';
import { useFilters, useFilterStore } from '@lidea/shared/data-access/core';
import { Filters } from '@lidea/shared/util/types';
import { close } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './filters-modal.module.css';

/* eslint-disable-next-line */
export interface FiltersModalProps {
  isOpen: boolean;
  onWillDismiss?: () => void;
  modalClassName?: string;
  typeaheadModalClassName?: string;
  confrmButtonColor?: string;
}

export function FiltersModal({confrmButtonColor = 'secondary', ...props}: FiltersModalProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [typeaheadState, setTypeaheadState] = useState<{
    filter: keyof Filters | null;
    isOpen: boolean;
  }>({
    filter: null,
    isOpen: false,
  });

  const selectFilter = useFilterStore((state) => state.selectFilter);
  const resetFilters = useFilterStore((state) => state.resetFilters);
  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const { filtersAsOptions, ...filters } = useFilters();

  const closeModal = () => {
    modalRef.current?.dismiss();
  };

  const closeTypeaheadModal = () => {
    setTypeaheadState({
      isOpen: false,
      filter: null,
    });
  };

  const handleFilterClick = (filter: keyof Filters) => {
    setTypeaheadState({
      isOpen: true,
      filter,
    });
  };

  const handleResetFilters = () => {
    resetFilters();
    closeModal();
  };

  const handleSelectionChange = (items: string[]) => {
    if (!typeaheadState.filter) {
      return;
    }

    selectFilter({
      filter: typeaheadState.filter,
      value: items,
    });

    closeTypeaheadModal();
  };

  return (
    <>
      <IonModal
        ref={modalRef}
        className={props.modalClassName}
        isOpen={props.isOpen}
        onWillDismiss={props.onWillDismiss}
        // style={{bottom: '76px'}}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('common:filters')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={props.onWillDismiss}>
                <IonIcon
                  aria-label="close modal"
                  icon={close}
                  slot="icon-only"
                ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          {filters.isSuccess && (
            <SearchFilters
              filters={filtersAsOptions}
              selectedFilters={selectedFilters}
              onFilterClick={handleFilterClick}
            />
          )}
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <IonButton
                    color={confrmButtonColor}
                    expand="block"
                    fill="solid"
                    onClick={closeModal}
                  >
                    {t('common:validate')}
                  </IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton
                    color="danger"
                    expand="block"
                    fill="solid"
                    onClick={handleResetFilters}
                  >
                    {t('common:reset')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonModal>

      <IonModal
        className={props.typeaheadModalClassName}
        isOpen={typeaheadState.isOpen}
        onWillDismiss={closeTypeaheadModal}
      >
        {typeaheadState.filter && filtersAsOptions ? (
          <PfTypeahead
            confrmButtonColor={confrmButtonColor}
            items={filtersAsOptions[typeaheadState.filter]?.options || []}
            selectedItems={selectedFilters[typeaheadState.filter] || []}
            title={t(`plot:${typeaheadState.filter}`) as string}
            onSelectionCancel={closeTypeaheadModal}
            onSelectionChange={handleSelectionChange}
          />
        ) : null}
      </IonModal>
    </>
  );
}

export default FiltersModal;
