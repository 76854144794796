import { useLocalStorage } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { login as loginAction } from '../actions/login';

export type LoginState = {
  isFetching: boolean;
  isError: boolean;
  error?: string;
};
export const useAuth = () => {
  const queryClient = useQueryClient();
  const [token, setToken, removeValue] = useLocalStorage<string | null>({
    key: 'token',
    defaultValue: null,
    getInitialValueInEffect: false,
  });
  const [redirectUrl, setRedirectUrl, removeRedirectUrl] = useLocalStorage<
    string | null
  >({
    key: 'redirectUrl',
    defaultValue: null,
  });

  const [loginState, setLoginState] = useState<LoginState>({
    isFetching: false,
    isError: false,
  });

  const login = useCallback(async (email: string, password: string) => {
    try {
      setLoginState({ isFetching: true, isError: false });
      const res = await loginAction(email, password);
      setLoginState({ isFetching: false, isError: false });
      setToken(res.token);
      queryClient.refetchQueries();
    } catch (error: any) {
      const serverMessage = await error.response.json();

      const errorKey = Object.keys(serverMessage)[0];
      setLoginState({ isFetching: false, isError: true, error: errorKey });
    }
  }, []);

  const logout = useCallback(() => {
    removeValue();
  }, []);

  return {
    token,
    login,
    loginState,
    logout,
    redirectUrl,
    setRedirectUrl,
    removeRedirectUrl,
    setToken,
  };
};
