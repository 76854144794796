import { useIonModal } from '@ionic/react';
import { PlotDetailsSheet } from '@lidea/plots/mobile/shared/ui';
import { usePlot, useUpdateParamsPlot, useUpdatePlot } from '@lidea/shared/data-access/plots';
import { MobilePage } from '@lidea/shared/ui/layout';
import { PlotMap, PlotMapType } from '@lidea/shared/ui/map';
import { ProtocolFormValues, YieldDetailsValues } from '@lidea/shared/util/types';
import { RouteComponentProps } from 'react-router-dom';

import PlotToolbar from '../../components/plot-toolbar/plot-toolbar';
import CommentsModal from './../../components/comments-modal/comments-modal';
import EditProtocolModal from './../../components/edit-protocol-modal/edit-protocol-modal';
import YieldDetailsModal from './../../components/yield-details-modal/yield-details-modal';

/* eslint-disable-next-line */
export interface PlotDetailsProps
  extends RouteComponentProps<{
    id: string;
  }> {}

export function PlotDetails(props: PlotDetailsProps) {
  const plotId = Number(props.match.params.id);
  const plot = usePlot(plotId);
  const updatePlot = useUpdatePlot();
  const updateParamsPlot = useUpdateParamsPlot();

  const handleProtocolSubmit = (e: ProtocolFormValues) => {
    updateParamsPlot.mutate({
      plotId: plotId,
      data: { protocol: e },
    });

    dismissProtocol(null, 'cancel');
  };

  const handleYieldDetailsSubmit = (e: YieldDetailsValues) => {
    updatePlot.mutate({ plotId: plotId, data: e });
    dismissProtocol(null, 'cancel');
  };

  const [presentProtocol, dismissProtocol] = useIonModal(EditProtocolModal, {
    plot: plot.data,
    onSubmit: handleProtocolSubmit,
    dismiss: (data: ProtocolFormValues | null, role: string) =>
      dismissProtocol(data, role),
  });
  const [presentComments, dismissComments] = useIonModal(CommentsModal, {
    plotId,
    dismiss: (data: string, role: string) => dismissComments(data, role),
  });
  const [presentYieldDetails, dismissYieldDetails] = useIonModal(YieldDetailsModal, {
    plot: plot.data,
    onSubmit: handleYieldDetailsSubmit,
    dismiss: (data: YieldDetailsValues | null, role: string) =>
      dismissYieldDetails(data, role),
  });

  return (
    <MobilePage
      defaultBackHref="/search"
      headerExtension={<PlotToolbar plotId={plotId} />}
    >
      <PlotMap plot={plot.data} type={PlotMapType.Plot} />

      <PlotDetailsSheet
        plotId={plotId}
        yieldAnalysis={plot.data?.properties.analyzes[0]}
        yieldDetails={{
          computed_yield: plot.data?.properties.computed_yield,
          estimatedYield: plot.data?.properties.estimatedYield,
          yield_doses: plot.data?.properties.yield_doses,
          objYield: plot.data?.properties.objYield,
          percentYield: plot.data?.properties.percentYield,
        }}
        onOpenComments={() =>
          presentComments({
            cssClass: 'page-modal',
          })
        }
        onOpenYieldDetails={() =>
          presentYieldDetails({
            cssClass: 'page-modal',
          })
        }
        onProtocolEdit={() =>
          presentProtocol({
            cssClass: 'page-modal',
          })
        }
      />
    </MobilePage>
  );
}

export default PlotDetails;
