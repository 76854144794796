import { useQuery } from '@tanstack/react-query';

import { plotsApi } from '../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

export const plotQuery = (id: number) => ({
  queryKey: plotKeys.detail(id),
  queryFn: () => plotsApi.getOne(id),
});

export function usePlot(id: number) {
  const query = useQuery(plotQuery(id));

  return query;
}

export default usePlot;
