import { useMutation, useQueryClient } from '@tanstack/react-query';

import { plotKeys } from '../../keys/plot-keys';
import { plotsApi } from './../../apis/plots-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMergePlotsProps {}

export function useMergePlots() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: plotsApi.merge,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: plotKeys.searches(), 
      });
      queryClient.invalidateQueries({
        queryKey: plotKeys.inBound(),
      });
    },
  });

  return mutation;
}

export default useMergePlots;
