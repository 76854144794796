import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw, { MapboxDrawControls } from '@mapbox/mapbox-gl-draw';
import { DrawCreateEvent, DrawUpdateEvent, DrawDeleteEvent } from '@mapbox/mapbox-gl-draw';
import { useEffect, useMemo } from 'react';
import { useControl, useMap } from 'react-map-gl';
import * as turf from '@turf/turf';
 
/* eslint-disable-next-line */
export interface DrawControlProps {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  limit?: number;
  onLoad?: (draw: MapboxDraw) => void;
  onCreate?: (e: DrawCreateEvent) => void;
  onUpdate?: (e: DrawUpdateEvent) => void;
  onDelete?: (e: DrawDeleteEvent) => void;
  onDeleteLine?: (e: DrawDeleteEvent) => void;
  onModeChange?: (mode: string) => void;
  initialPolygon?: GeoJSON.Feature<GeoJSON.MultiPolygon>;
  controls?: MapboxDrawControls;
  data?: GeoJSON.FeatureCollection<GeoJSON.Point>;
}

export function DrawControl(props: DrawControlProps) {
  const { current: map } = useMap();
  const draw = useMemo(
    () =>
      new MapboxDraw({
        boxSelect: false,
        controls: {
          point: false,
          line_string: false,
          combine_features: false,
          uncombine_features: false,
          ...props.controls,
        },
      }),
    []
  );

  useControl(() => draw, {
    position: props.position,
  });

  useEffect(() => {
    props.onLoad?.(draw);
  }, [props.onLoad]);

  // useEffect(() => {
  //   // Ajouter une icône de curseur personnalisée à la carte
  //   map?.on('load', () => {
  //   (map as mapboxgl.Map)?.addSource('cursor', {
  //       type: 'geojson',
  //       data: {
  //         type: 'Point',
  //         coordinates: [0, 0],
  //       },
  //     });
  //     (map as mapboxgl.Map)?.addLayer({
  //       id: 'cursor',
  //       type: 'circle',
  //       source: 'cursor',
  //       paint: {
  //         'circle-radius': 10,
  //         'circle-color': '#000',
  //       },
  //     });
  //   });
  
  //   const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
  //     if (draw.getMode() === 'draw_polygon') {
  //       let point = turf.point([e.lngLat.lng, e.lngLat.lat]);
  //       const polygonFeatures = props.data?.features;
  //       if (polygonFeatures) {
  //         let nearestParcel = null;
  //         let shortestDistance = Infinity;
  //         polygonFeatures.forEach((feature) => {
  //           const polygon = feature.geometry;
  //           const line = turf.polygonToLine(polygon);
  //           const nearestPoint = turf.nearestPointOnLine(line, point);
  //           const distance = turf.distance(point, nearestPoint, { units: 'meters' });
  //           if (distance < shortestDistance) {
  //             shortestDistance = distance;
  //             nearestParcel = feature;
  //           }
  //         }); 
  //         if (nearestParcel && shortestDistance < 50) {
  //           // Déplacer le point sur la bordure du polygone
  //           const line = turf.polygonToLine(nearestParcel.geometry);
  //           const nearestPoint = turf.nearestPointOnLine(line, point);
  //           point = nearestPoint;
  //           // Mettre à jour l'emplacement de l'icône du curseur
  //           if (map?.getSource('cursor')) {
  //             map.getSource('cursor').setData(point);
  //           }
  //           console.log(`Le point a été déplacé sur la bordure de la parcelle ${nearestParcel.properties?.name}.`);
  //         } else if (nearestParcel) {
  //           console.log(`La parcelle la plus proche du point est ${nearestParcel.properties?.name} avec une distance de ${shortestDistance} mètres.`);
  //         }
  //       }
  //     }
  //   };
  
  //   map?.on('mousemove', handleMouseMove);
  
  //   return () => {
  //     map?.off('mousemove', handleMouseMove);
  //   };
  // }, [props.data]);
  
  
  

  useEffect(() => {
    const onDrawCreate = (e: DrawCreateEvent) => {
      props.onCreate?.(e);
      // if (props.limit && draw.getAll().features.length > props.limit) {
      //   draw.delete(e.features[0].id as string);
      // } else {
      //   props.onCreate?.(e);
      // }
    };
    map?.on('draw.create', onDrawCreate);

    const onDrawUpdate = (e: DrawUpdateEvent) => {
      props.onUpdate?.(e);
    };
    map?.on('draw.update', onDrawUpdate);

    const onDrawDelete = (e: DrawDeleteEvent) => {
      props.onDelete?.(e);
    };
    map?.on('draw.delete', onDrawDelete);

    const onDrawDeleteLine = (e: DrawDeleteEvent) => {
      props.onDeleteLine?.(e);
    };
    map?.on('draw.delete', onDrawDelete);

    return () => {
      map?.off('draw.create', onDrawCreate);
      map?.off('draw.update', onDrawUpdate);
      map?.off('draw.delete', onDrawDelete);
      map?.off('draw.deleteLine', onDrawDeleteLine);
    };
  }, [props]);


  useEffect(() => {
    const onModeChange = () => {
      props.onModeChange?.(draw.getMode());
    };
    map?.on('draw.modechange', onModeChange);

    return () => {
      map?.off('draw.modechange', onModeChange);
    };
  }, [props]);

  useEffect(() => {
    const onDrawDrag = (e: any) => {
      if (e.features[0].geometry.type === 'Polygon') {
        draw.delete(e.features[0].id);
        draw.add(e.features[0]);
      }
    };
    map?.on('draw.drag', onDrawDrag);
  
    return () => {
      map?.off('draw.drag', onDrawDrag);
    };
  }, [props]);

  useEffect(() => {
    if (props.initialPolygon) {
      draw.deleteAll()
      draw.add(props.initialPolygon);
      
    }
  }, [props.initialPolygon]);

  return null;
}

export default DrawControl;
