import { useMemo } from 'react';

import useZoning from '../use-zoning/use-zoning';

interface UsePointProps {
  analysisId: number;
  pointId: number | null;
}

export function usePoint(props: UsePointProps) {
  const query = useZoning({
    analysisId: props.analysisId,
    enabled: true,
  });

  const point = useMemo(
    () =>
      query.data?.points?.features.find((point) => point.id === props.pointId),
    [query.data, props.pointId]
  );

  return point;
}

export default usePoint;
