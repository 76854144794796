import { publicClient } from './api-client';

export interface Credentials {
  email: string;
  password: string;
}

export const authenticationAPI = {
  login: (credentials: Credentials) => {
    return publicClient
      .post('token/', {
        json: credentials,
      })
      .json<{ token: string }>();
  },
};
