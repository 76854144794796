import { useTranslation } from 'react-i18next';

import { ZoningType } from '../plot-map/plot-map';
import styles from './analysis-legend.module.css';

/* eslint-disable-next-line */
export interface AnalysisLegendProps {
  legend: { zone: number; color: string; value?: number; percent?: number }[];
  zoningType?: ZoningType;
}

export function AnalysisLegend(props: AnalysisLegendProps) {
  const { t } = useTranslation(['map'], { useSuspense: false });

  return (
    <div className={styles['container']}>
      <div className={styles['legend__title']}>
        {props.zoningType === 'yield'
          ? t('map:yield_legend_title')
          : t('map:zoning_legend_title')}
      </div>
      {props.legend.map((item) => {
        let label = `Zone ${item.zone}`;
        if (item.value) {
          label = `${label} (${item.value})`;
        }

        if (props.zoningType === 'yield') {
          label = `${label.replace(')', '')} qx/ha)`;
        }

        if (item.percent) {
          label = `${label} (${item.percent}%)`;
        }

        return (
          <div key={item.zone} className={styles['legend-item']}>
            <div
              className={styles.legendColor}
              style={{
                backgroundColor: item.color,
                height: 15,
                width: 15,
                borderRadius: '50%',
              }}
            />
            <div className={styles.legendZone}>{label}</div>
          </div>
        );
      })}
    </div>
  );
}

export default AnalysisLegend;
