import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import { AnalysisLoader } from '@lidea/shared/ui/core';
import { getAnalysisColor, getAnalysisStep } from '@lidea/shared/util/core';
import { AnalysisStep, ZoningStatus } from '@lidea/shared/util/types';
import { LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import styles from './analysis-card.module.css';

/* eslint-disable-next-line */
export interface AnalysisCardProps {
  title?: string;
  children?: React.ReactNode;
  dataTestid?: string;
  color?: string;
  status?: ZoningStatus;
  analysisId?: number;
  plotId?: number;
  loading?: boolean;
  disabled?: boolean;
}

export function AnalysisCard(props: AnalysisCardProps) {
  const { t } = useTranslation(['common', 'plot'], { useSuspense: false });
  const step = getAnalysisStep(props.status);

  return (
    <IonCard
      className={styles['container']}
      color={
        props.color || getAnalysisColor(props.status || ZoningStatus.InProgress)
      }
      data-testid={props.dataTestid || 'analysis-card'}
      routerLink={
        !props.disabled && !props.loading
          ? `/search/plots/${props.plotId}/analysis/${
              props.analysisId
            }/${step}${step === AnalysisStep.Zoning ? '?generate=true' : ''}`
          : undefined
      }
    >
      {props.title ? (
        <IonCardHeader>
          <IonCardTitle
            style={{
              textTransform: 'uppercase',
            }}
          >
            {props.title}
          </IonCardTitle>
        </IonCardHeader>
      ) : null}

      <IonCardContent>{props.children}</IonCardContent>
      <LoadingOverlay
        color="#fff"
        data-testid="loading-overlay"
        loader={<AnalysisLoader />}
        opacity={1}
        visible={!!props.loading}
      />
    </IonCard>
    
  );
}

export default AnalysisCard;
