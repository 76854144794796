import { SelectedFilters } from '@lidea/shared/data-access/core';
import { PlotPageResult } from '@lidea/shared/util/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { plotsApi } from '../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

interface UsePlotsProps {
  selectedFilters: SelectedFilters;
  page: number;
  sort?: string;
}
export function usePlots(props: UsePlotsProps) {
  const plots = useQuery<PlotPageResult>({
    queryKey: plotKeys.paginated({
      filters: props.selectedFilters,
      page: props.page,
      sort: props.sort,
    }),
    queryFn: () => plotsApi.getAll(props.selectedFilters, props.page, props.sort),
  });

  const plotsAsOptions = useMemo(() => {
    if (!plots.data) {
      return [];
    }
    return plots.data.results.features.map((p) => ({
      value: p.id as number,
      label: p.properties.name,
    }));
  }, [plots.data]);

  return { ...plots, plotsAsOptions };
}

export default usePlots;
