import { IonIcon } from '@ionic/react';
import { usePlot } from '@lidea/shared/data-access/plots';
import { ModalPage } from '@lidea/shared/ui/layout';
import classNames from 'classnames';
import { call } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './plot-info-modal-content.module.css';

/* eslint-disable-next-line */
export interface PlotInfoModalProps {
  plotId: number;
  dissmiss: () => void;
}

export function PlotInfoModal(props: PlotInfoModalProps) {
  const { t, i18n } = useTranslation(['common'], { useSuspense: false });
  const locale = i18n.language as 'fr' | 'en';

  const plot = usePlot(props.plotId);
  const farmerName = plot.data?.properties.farm.name

  const tel = plot.data?.properties.farm.owner_phone;

  const email = plot.data?.properties.farm.owner_email;

  return (
    <ModalPage title={t('plot:info_modal_title')} onClose={props.dissmiss}>
      <div>
        <hr className={styles.separator} />

        <div className={classNames(styles.group, styles['owner'])}>
          <h3>{farmerName}</h3>
          <a href={`tel:${tel}`}>
            <span>{tel}</span> <IonIcon icon={call} />
          </a>
          <a href={`mailto:${email}`}>{email}</a>
        </div>

        <div className={styles.group}>
          <h3>{plot.data?.properties.farm.name}</h3>
          <div>{plot.data?.properties.farm.adress}</div>
          <div>
            {plot.data?.properties.farm.postal_code} -{' '}
            {plot.data?.properties.farm.country}
          </div>
        </div>

        <hr className={styles.separator} />

        <div className={styles.group}>
          <h3>{plot.data?.properties.species[`name_${locale}`]}</h3>
          <div>{plot.data?.properties.variety[`name_${locale}`]}</div>
          <div>{plot.data?.properties.generation[`name_${locale}`]}</div>
        </div>
      </div>
    </ModalPage>
  );
}

export default PlotInfoModal;
