import { Filters } from '@lidea/shared/util/types';
import { useMemo } from 'react';
import { create } from 'zustand';

export type SelectedFilters = {
  [key in keyof Filters]?: string[];
} & {
  search_query?: string;
};

export type FiltersState = {
  selectedFilters: SelectedFilters;
};

type Actions = {
  selectFilter: (filter: { filter: keyof Filters; value: string[] }) => void;
  resetFilters: () => void;
  setSearchQuery: (query: string) => void;
};

export const useFilterStore = create<FiltersState & Actions>((set) => ({
  selectedFilters: {},
  selectFilter: (filter: { filter: string; value: string[] }) =>
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        [filter.filter]: filter.value,
      },
    })),
  setSearchQuery: (query: string) =>
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        search_query: query,
      },
    })),
  resetFilters: () => set({ selectedFilters: {} }),
}));

export const useSelectedFilters = () => {
  const selectedFilters = useFilterStore((state) => state.selectedFilters);

  const selectedFiltersCount = useMemo(() => {
    return Object.values(selectedFilters).reduce((acc, curr) => {
      if (Array.isArray(curr)) {
        return acc + curr.length;
      } else {
        return acc;
      }
    }, 0);
  }, [selectedFilters]);

  return { selectedFilters, selectedFiltersCount };
};

export default useFilterStore;
