import { IonAlert } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface OfflineAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const OfflineAlert: React.FC<OfflineAlertProps> = (props) => {
  const { t } = useTranslation(['analysis', 'common'], { useSuspense: false });
  return (
    <IonAlert
      buttons={[
        {
          text: t('common:close'),
          role: 'confirm',
          handler: props.onClose,
        },
      ]}
      header={t('analysis:offline')!}
      isOpen={props.isOpen}
      message={t('analysis:offline_hint')!}
    />
  );
};

export default OfflineAlert;
