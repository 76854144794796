import { ProtocolForm } from '@lidea/plots/shared/ui';
import { ModalPage } from '@lidea/shared/ui/layout';
import { PlotFeature, ProtocolFormValues } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

interface EditProtocolModalProps {
  plot: PlotFeature;
  dismiss: (data: any, role: string) => void;
  onSubmit: (e: ProtocolFormValues) => void;
}

const EditProtocolModal: React.FC<EditProtocolModalProps> = (props) => {
  const { t } = useTranslation(['common', 'plot'], { useSuspense: false });

  return (
    <ModalPage
      title={t('plot:edit_protocol')}
      onClose={() => props.dismiss(null, 'cancel')}
    >
      <ProtocolForm
        i18n={{
          confirm: t('common:validate'),
          cancel: t('common:cancel'),
        }}
        initialValues={props.plot.properties.protocol}
        onCancel={() => props.dismiss(null, 'cancel')}
        onSubmit={props.onSubmit}
      />
    </ModalPage>
  );
};

export default EditProtocolModal;
