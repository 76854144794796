import { ZoningStatus } from '@lidea/shared/util/types';

export const getAnalysisColor = (status: ZoningStatus) => {
  switch (status) {
    case ZoningStatus.ToBeDone:
      return 'danger';
    case ZoningStatus.InProgress:
      return 'warning';
    case ZoningStatus.Ready:
      return 'success';
  }
};
