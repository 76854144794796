import {
  CheckboxCustomEvent,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import styles from './pf-typeahead.module.css';

type Item = {
  value: string | number;
  label: string;
};

/* eslint-disable-next-line */
export interface PfTypeaheadProps {
  items: Item[];
  selectedItems: string[];
  title?: string;
  onSelectionCancel?: () => void;
  onSelectionChange?: (items: string[]) => void;
  confrmButtonColor?: string;
  limit?: number;
}

export function PfTypeahead({
  confrmButtonColor = 'secondary',
  ...props
}: PfTypeaheadProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<string[]>([
    ...props.selectedItems,
  ]);

  const isChecked = (value: string | number) => {
    return workingSelectedValues.find((item) => item === value) !== undefined;
  };

  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const resetChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange([]);
    }
  };

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues);
    }
  };

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        props.items.filter((item) => {
          return item.label.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail;

    if (checked) {
      setWorkingSelectedValues([...workingSelectedValues, value]);
    } else {
      setWorkingSelectedValues(
        workingSelectedValues.filter((item) => item !== value)
      );
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={cancelChanges}>
              <IonIcon icon={close} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            className={styles.searchbar}
            onIonInput={searchbarInput}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light">
        <IonList className={styles.list}>
          <Virtuoso
            data={filteredItems}
            itemContent={(index, item) => (
              <IonItem key={item.value}>
                <IonCheckbox
                  checked={isChecked(item.value)}
                  data-testid={item.value}
                  disabled={
                    !isChecked(item.value) &&
                    props.limit !== undefined &&
                    workingSelectedValues.length >= props.limit
                  }
                  value={item.value}
                  onIonChange={checkboxChange}
                >
                  {item.label}
                </IonCheckbox>
              </IonItem>
            )}
            totalCount={50}
          />
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <IonButton
                  color={confrmButtonColor}
                  expand="block"
                  fill="solid"
                  onClick={confirmChanges}
                >
                  {t('common:validate')}
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  color="danger"
                  expand="block"
                  fill="solid"
                  onClick={resetChanges}
                >
                  {t('common:reset')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default PfTypeahead;
