import { useQuery } from '@tanstack/react-query';

import { plotsApi } from '../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsePlotsMaxBoundsProps {}

export function usePlotsMaxBounds() {
  const query = useQuery({
    queryKey: plotKeys.maxBounds(),
    queryFn: () => plotsApi.getMaxBounds(),
  });

  return query;
}

export default usePlotsMaxBounds;
