import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonPopover,
} from '@ionic/react';
import { useId } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { Fr, Gb } from 'react-flags-select';

import styles from './lang-select.module.css';
/* eslint-disable-next-line */
export interface LangSelectProps {
  onSelect: (code: string) => void;
  locale: string;
  showSelectedLabel?: boolean;
  showOptionLabel?: boolean;
  fill?: 'outline' | 'solid';
}

const options = [
  {
    value: 'en',
    label: 'English',
    flag: <Gb fontSize={32} />,
  },
  {
    value: 'fr',
    label: 'Français',
    flag: <Fr fontSize={32} />,
  },
];
export function LangSelect(props: LangSelectProps) {
  const uuid = useId();
  const [selected, setSelect] = useState(props.locale);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === selected);
  }, [selected]);

  const handleLangSelect = async (locale: string) => {
    setSelect(locale);
    props.onSelect(locale);
  };

  useEffect(() => {
    setSelect(props.locale);
  }, [props.locale]);

  return (
    <>
      <IonButton
        className={styles['select-button']}
        color="light"
        expand="block"
        fill={props.fill}
        id={`lang-popover-button-${uuid}`}
      >
        {selectedOption?.flag}{' '}
        {props.showSelectedLabel && <span>{selectedOption?.label}</span>}
      </IonButton>
      <IonPopover
        alignment="center"
        className={styles['popover']}
        trigger={`lang-popover-button-${uuid}`}
        dismissOnSelect
      >
        <IonContent>
          <IonList>
            {options.map((option) => (
              <IonItem
                key={option.value}
                button={true}
                detail={false}
                lines="none"
                onClick={() => handleLangSelect(option.value)}
              >
                {option.flag}{' '}
                {props.showOptionLabel && <span>{option.label}</span>}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
}

LangSelect.defaultProps = {
  showSelectedLabel: true,
  showOptionLabel: true,
};

export default LangSelect;
