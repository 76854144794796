import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  heatmapQueryOptions,
  measuresQuery,
  useImageryIndexes,
  zoningQuery,
} from '@lidea/shared/data-access/analysis';
import { useFilterStore } from '@lidea/shared/data-access/core';
import { plotQuery, useInfinitePlots } from '@lidea/shared/data-access/plots';
import { PlotFeature, ZoningStatus } from '@lidea/shared/util/types';
import { useDisclosure } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { close } from 'ionicons/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Step1 from './components/step-1/step-1';
import Step2 from './components/step-2/step-2';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = import.meta.env.VITE_API_ROOT;

export const OFFLINE_PLOTS_STORAGE_KEY = 'offline_plots';

async function preloadImages(sources: string[]) {
  await Promise.all(
    sources.map((source) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        // we need to set crossOrigin to anonymous to avoid opaque responses in sw cache
        img.crossOrigin = 'anonymous';
        img.onload = function () {
          resolve();
        };
        img.src = source;
      });
    })
  );
}

/* eslint-disable-next-line */
export interface OfflineProps {
  onFinished: () => void;
  dismiss: () => void;
}

export function Offline(props: OfflineProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const queryClient = useQueryClient();
  const [loadingOpened, loadingHandlers] = useDisclosure();
  const [loadingMessage, setLoadingMessage] = useState(
    'Téléchargement des données'
  );
  const [selectedPlots, setSelectedPlots] = useState<number[]>([]);

  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const infinitePlots = useInfinitePlots({
    selectedFilters: { search_query: selectedFilters.search_query },
  });
  const { imageryIndexes } = useImageryIndexes();
  const selectPlotsSatImagesList = useMemo(() => {
    if (!imageryIndexes.data || !selectedPlots.length) {
      return [];
    }
    // for each selected plot, add an entry for each imagery index
    return selectedPlots.flatMap((plotId) => {
      return imageryIndexes.data?.list_indexes.map((imageryIndex) => {
        return { plotId, imageryIndex };
      });
    });
  }, [imageryIndexes, selectedPlots]);

  const [step, setStep] = useState(1);

  // reduce plots pages to a single array
  const plots = useMemo(() => {
    if (!infinitePlots.data) {
      return [];
    }
    return infinitePlots.data.pages.reduce((acc, page) => {
      return [...acc, ...page.results.features];
    }, [] as PlotFeature[]);
  }, [infinitePlots.data]);

  const selectedPlotsFeatureCollection = useMemo(() => {
    if (!plots.length) {
      return null;
    }

    return {
      type: 'FeatureCollection' as const,
      features: plots.filter((plot) => selectedPlots.includes(plot.id)),
    };
  }, [plots, selectedPlots]);

  const handleSelectChange = (id: number) => {
    setSelectedPlots((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const getData = async () => {
    if (!selectedPlots.length) {
      return;
    }
    if (infinitePlots.isSuccess) {
      loadingHandlers.open();
      // Get ids of analyzes to download
      // We only want to download data for analyzes that are not to be done
      const analysisIds = selectedPlots.reduce((acc, curr) => {
        const nextIds =
          plots
            .find((plot) => plot.id === curr)
            ?.properties.analyzes.filter(
              (analysis) => analysis.status !== ZoningStatus.ToBeDone
            )
            .map((analysis) => analysis.id) || [];

        return [...acc, ...nextIds];
      }, [] as number[]);

      // Get plots queries
      const plotsQueries = selectedPlots.map((id) => {
        return queryClient.fetchQuery(plotQuery(id));
      });

      // Get zonings queries
      const zoningsQueries = analysisIds.map((id) => {
        return queryClient.fetchQuery(
          zoningQuery({ analysisId: id, enabled: true })
        );
      });

      // Get measures queries
      const measuresQueries = analysisIds.map((id) => {
        return queryClient.fetchQuery(measuresQuery(id));
      });

      // Get sat images
      const satImagesQueries = selectPlotsSatImagesList.map((item) => {
        return queryClient.fetchQuery(
          heatmapQueryOptions({
            plotId: item.plotId,
            imageryIndex: item.imageryIndex,
          })
        );
      });

      const queries = [...plotsQueries, ...zoningsQueries, ...measuresQueries];

      // Wait for all queries to be resolved
      try {
        await Promise.all(queries);

        // Preload sat images
        const satImages = await Promise.all(satImagesQueries);
        const flatSatImages = satImages.flat();
        const sources = flatSatImages.map((image) => root + image.affine);
        await preloadImages(sources);
      } catch (error) {
        console.error(error);
      }

      window.localStorage.setItem(
        OFFLINE_PLOTS_STORAGE_KEY,
        JSON.stringify(selectedPlots)
      );
      setStep(2);
      setLoadingMessage('Téléchargement des cartes');
    }
  };

  const handleFinish = () => {
    loadingHandlers.close();
    props.onFinished();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('common:offline_modal_title')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.dismiss}>
              <IonIcon icon={close} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {step === 1 ? (
        <Step1
          fetchNextPage={infinitePlots.fetchNextPage}
          hasNextPage={infinitePlots.hasNextPage}
          plots={plots}
          selectedPlots={selectedPlots}
          onCancel={props.dismiss}
          onConfirm={getData}
          onSelectChange={handleSelectChange}
        />
      ) : null}
      {step === 2 && selectedPlotsFeatureCollection ? (
        <Step2
          plots={selectedPlotsFeatureCollection}
          onFinished={handleFinish}
        />
      ) : null}
      <IonLoading
        isOpen={loadingOpened}
        message={loadingMessage}
        spinner="circles"
      />
    </IonPage>
  );
}

export default Offline;
