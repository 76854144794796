import { IonButton } from '@ionic/react';
import { PfInput } from '@lidea/shared/ui/form';
import { useForm } from '@mantine/form';
import { key, person } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './login-form.module.css';

export type LoginFormValues = {
  email: string;
  password: string;
};

/* eslint-disable-next-line */
export interface LoginFormProps {
  onSubmit: (event: LoginFormValues) => void;
  withIcons?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  error?: string;
}

export function LoginForm(props: LoginFormProps) {
  const { t } = useTranslation(['common', 'auth'], { useSuspense: false });
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : t('auth:email_invalid'),
      password: (value) =>
        value.length > 0 ? null : t('auth:password_required'),
    },
  });

  const handleSubmit = (values: LoginFormValues) => {
    props.onSubmit(values);
  };

  const error = (
    <div className={styles['form__error']}>
      {props.error ? props.error : t('common:unknown_error')}
    </div>
  );
  return (
    <form
      className={styles['form']}
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <PfInput
        color="primary"
        errorText={form.errors.email as string}
        fill="outline"
        icon={props.withIcons ? person : undefined}
        isTouched={form.isTouched('email')}
        label={t('auth:email_label') as string}
        labelPlacement="floating"
        placeholder="john.doe@gmail.com"
        type="email"
        {...form.getInputProps('email')}
      />

      <PfInput
        color="primary"
        errorText={form.errors.password as string}
        fill="outline"
        icon={props.withIcons ? key : undefined}
        isTouched={form.isTouched('password')}
        label={t('auth:password_label') as string}
        labelPlacement="floating"
        placeholder="*********"
        type="password"
        {...form.getInputProps('password')}
      />

      <div className={styles['form__footer']}>
        {props.isError ? error : null}
        <input
          disabled={props.isFetching}
          style={{ display: 'none' }}
          type="submit"
        />
        <IonButton
          disabled={props.isFetching}
          expand="block"
          size="large"
          type="submit"
        >
          {t('auth:login_button')}
        </IonButton>
      </div>
    </form>
  );
}

export default LoginForm;
