import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonList,
  IonRow,
  IonSearchbar,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import { PlotItem } from '@lidea/search/mobile/shared/ui';
import { useFilterStore } from '@lidea/shared/data-access/core';
import { PlotFeature, ZoningStatus } from '@lidea/shared/util/types';
import { useDebouncedState, usePrevious } from '@mantine/hooks';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import HowToModal from '../how-to-modal/how-to-modal';
import styles from './step-1.module.css';

interface FooterProps {
  context?: {
    hasNextPage?: boolean;
  };
}

/* eslint-disable-next-line */
export interface Step1Props {
  plots?: PlotFeature[];
  onSelectChange: (id: number) => void;
  selectedPlots: number[];
  onConfirm: () => void;
  onCancel: () => void;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

export function Step1(props: Step1Props) {
  const { t, i18n } = useTranslation(['common', 'plot'], {
    useSuspense: false,
  });
  const [searchQuery, setSearchQuery] = useDebouncedState('', 200);
  const previousSearchQuery = usePrevious(searchQuery);
  const setSearchQueryFilter = useFilterStore((state) => state.setSearchQuery);

  const [presentHowTo, dismissHowTo] = useIonModal(HowToModal, {
    dismiss: () => dismissHowTo(),
  });

  const locale = i18n.language as 'fr' | 'en';

  const Footer = (props: FooterProps) => {
    return (
      <div>
        <div
          style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {props.context?.hasNextPage ? (
            <>{t('plot:loading_plots')}</>
          ) : (
            <>{t('plot:nothing_load')}</>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const trimmedSearchQuery = searchQuery.trim();
    if (trimmedSearchQuery && searchQuery !== previousSearchQuery) {
      setSearchQueryFilter(trimmedSearchQuery);
    }
  });

  return (
    <>
      <IonContent className={classNames(styles['container'], 'ion-padding')}>
        <p>{t('common:offline_select_parcel')}</p>
        <div
          style={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IonButton
            color="primary"
            expand="block"
            fill="solid"
            style={{ fontSize: '12px' }}
            onClick={() => {
              presentHowTo({
                cssClass: 'page-modal',
              });
            }}
          >
            {t('common:offline_select_parcel_message_title')}
          </IonButton>
        </div>
        <IonToolbar>
          <IonSearchbar
            className={classNames(styles.searchbar)}
            placeholder={t('common:searchbar_placeholder') as string}
            onIonInput={(e) => setSearchQuery(e.detail.value!)}
          ></IonSearchbar>
        </IonToolbar>
        <IonList className={classNames(styles['plots-list'])}>
          {props.plots ? (
            <Virtuoso
              components={{ Footer }}
              context={{
                hasNextPage: props.hasNextPage,
              }}
              data={props.plots}
              endReached={() => props.fetchNextPage()}
              itemContent={(index, { id, properties }) => {
                return (
                  <div style={{ height: '91px', marginBottom: 16 }}>
                    <PlotItem
                      key={id}
                      area={properties.area}
                      id={id as number}
                      name={properties.name}
                      ownerName={`${properties.farm.name} - ${
                        properties.variety[`name_${locale}`]
                      }`}
                      selected={props.selectedPlots.includes(id as number)}
                      species={
                        typeof properties.species === 'number'
                          ? ''
                          : properties.species[`name_${locale}`]
                      }
                      yieldStatus={
                        properties.analyzes[0]?.status || ZoningStatus.ToBeDone
                      }
                      selectable
                      onSelectChange={props.onSelectChange}
                    />
                  </div>
                );
              }}
              style={{ height: '100%' }}
              totalCount={50}
            />
          ) : null}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <IonButton
                  color="danger"
                  expand="block"
                  fill="solid"
                  onClick={props.onCancel}
                >
                  {t('common:cancel')}
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  expand="block"
                  fill="solid"
                  onClick={props.onConfirm}
                >
                  {t('common:validate')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default Step1;
