import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import classNames from 'classnames';
import { A11y, Navigation } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './select-image-slider.module.css';
// Import Swiper styles

/* eslint-disable-next-line */
export interface SelectImageSliderProps {
  options: {
    valueLabel: string;
    src: string;
    value: string;
  }[];
  onChange: (index: string) => void;
  value?: string;
}

export function SelectImageSlider(props: SelectImageSliderProps) {
  return (
    <div className={styles['container']}>
      <button
        className={`swiper-button-prev ${styles['swiper-button']} ${styles['swiper-button--prev']}`}
      ></button>
      <Swiper
        key={props.options.length}
        a11y={{
          slideRole: 'option',
        }}
        className={styles['swiper-container']}
        initialSlide={props.options.length - 1}
        modules={[Navigation, A11y]}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
          lockClass: 'lock',
        }}
        role="listbox"
        slidesPerView={'auto'}
        spaceBetween={0}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {props.options.map((option) => (
          <SwiperSlide
            key={option.valueLabel}
            aria-label={option.valueLabel}
            aria-labelledby={`image-${option.valueLabel.replace(' ', '-')}`}
            aria-selected={option.valueLabel === props.value}
            className={classNames(styles['slides'], {
              [styles['slides--active']]: option.value === props.value,
            })}
            onClick={() => props.onChange(option.value)}
          >
            <span id={`image-${option.value.replace(' ', '-')}`}>
              {option.valueLabel}
            </span>
            <img
              alt={`day ${option.valueLabel}`}
              // we need to set crossOrigin to anonymous to avoid opaque responses in sw cache
              crossOrigin="anonymous"
              src={option.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        className={`swiper-button-next ${styles['swiper-button']} ${styles['swiper-button--next']}`}
      ></button>
    </div>
  );
}

export default SelectImageSlider;
