import {useMemo} from "react"
import { SelectedFilters } from '@lidea/shared/data-access/core';
import { useQuery } from '@tanstack/react-query';

import { plotsApi } from '../../apis/plots-api';
import { plotKeys } from './../../keys/plot-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsePlotsInBoundsProps {
  bounds: mapboxgl.LngLatBounds | null;
  selectedFilters: SelectedFilters;
  cluster: boolean;
  zoom: number;
  type?: string;
  enabled?: boolean
}

export function usePlotsInBounds(props: UsePlotsInBoundsProps) {
  const isEnabled = useMemo(() => { 
    if (props.enabled === false) {
      return false
    } else if (props.enabled) {
      return true
    }

    return props.bounds !== null
  }, [props.bounds, props.enabled]);

  const query = useQuery({
    queryKey: plotKeys.inBounds(
      props.bounds!,
      props.cluster,
      props.selectedFilters,
      props.zoom,
      props.type || ''
    ),
    // queryKey: plotKeys.inBound(),
    queryFn: () =>
      plotsApi.getInBounds(
        props.selectedFilters,
        props.bounds!,
        props.cluster,
        props.zoom,
        props.type || ''
      ),
    // disable cache
    cacheTime: 0,
    enabled: isEnabled,
    keepPreviousData: true,
  });

  return query;
}

export default usePlotsInBounds;
