import { Network } from '@capacitor/network';
import { IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import { useMeasuresPage } from '@lidea/analysis/shared/data-access';
import { useAnalysis, usePoint } from '@lidea/shared/data-access/analysis';
import { MobilePage } from '@lidea/shared/ui/layout';
import { GeolocateControl, PlotMap, PlotMapType } from '@lidea/shared/ui/map';
import { MeasureSummary } from '@lidea/shared/util/types';
import { clsx } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import MeasureModal from '../../components/measure-modal/measure-modal';
import MeasuresToolbar from '../../components/measures-toolbar/measures-toolbar';
import PlotToolbar from '../../components/plot-toolbar/plot-toolbar';
import PointModal from '../../components/point-modal/point-modal';
import styles from './measures.module.css';

/* eslint-disable-next-line */
export interface MeasuresProps
  extends RouteComponentProps<{
    id: string;
    analysisId: string;
  }> {}

export function Measures(props: MeasuresProps) {
  const { id, analysisId } = props.match.params;
  const history = useHistory();
  const { t } = useTranslation(['common', 'plot', 'analysis'], {
    useSuspense: false,
  });

  const [networkConnected, setNetworkConnected] = useState(true);
  const [validatedPoints, setValidatedPoints] = useState<number[]>(() => {
    const savedPoints = localStorage.getItem('validatedPoints');
    return savedPoints ? JSON.parse(savedPoints) : [];
  });

  const {
    plot,
    zoning,
    updateMeasure,
    selectedPoint,
    setSelectedPoint,
    selectedMeasure,
    handlePointClick,
    handleSubmitMeasures,
    pointsReady,
  } = useMeasuresPage({
    plotId: Number(id),
    analysisId: Number(analysisId),
  });

  const analysis = useAnalysis({
    plotId: Number(id),
    analysisId: Number(analysisId),
  });
  const selectedPointFeature = usePoint({
    pointId: selectedPoint,
    analysisId: Number(analysisId),
  });

  const selectedMeasureSummary: MeasureSummary = useMemo(() => {
    if (!selectedMeasure) {
      return [];
    }
    const summary: MeasureSummary = [];

    selectedMeasure?.dynamic.forEach((measure) => {
      if (measure.summary) {
        summary.push({ ...measure.summary, label: measure.label });
      }
    });

    return summary;
  }, [selectedMeasure]);

  const [presentMeasureModal, dismissMeasureModal] = useIonModal(MeasureModal, {
    analysisId: Number(analysisId),
    plotId: Number(id),
    measure: selectedMeasure,
    zone: selectedPointFeature?.properties.zone,
    onSubmit: (data: any) => {
      handleSubmitMeasures(data);
      if (!networkConnected) {
        setValidatedPoints((prev) => {
          const updatedPoints = [...prev, selectedMeasure?.id];
          localStorage.setItem('validatedPoints', JSON.stringify(updatedPoints));
          return updatedPoints;
        });
      }
    },
    dismiss: (data: any, role: string) => dismissMeasureModal(data, role),
  });

  const [presentPointModal, dismissPointModal] = useIonModal(PointModal, {
    analysisId: Number(analysisId),
    pointNumber: selectedPointFeature?.properties.zone,
    summary: selectedMeasureSummary,
    onClickInput: () =>
      presentMeasureModal({
        cssClass: 'page-modal',
      }),
    dismiss: (data: any, role: string) => dismissPointModal(data, role),
  });

  const handleSelectPoint = (id: number) => {
    handlePointClick(id);
    presentPointModal({
      cssClass: clsx('page-modal', styles['point-modal']),
    });
  };

  const handleGoToPlots = () => {
    history.push(`/search/plots/${id}`);
  };

  useEffect(() => {
    // set initial network status
    Network.getStatus().then((status) => {
      setNetworkConnected(status.connected);
    });

    // listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });
  }, []);

  useEffect(() => {
    if (networkConnected) {
      setValidatedPoints([]);
    }
  }, [networkConnected]);

  useEffect(() => {
    const savedPoints = localStorage.getItem('validatedPoints');
    if (savedPoints) {
      setValidatedPoints(JSON.parse(savedPoints));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('validatedPoints', JSON.stringify(validatedPoints));
  }, [validatedPoints]);

  useEffect(() => {
    // If user if offline mutation is paused
    if (updateMeasure.isSuccess || updateMeasure.isPaused) {
      dismissMeasureModal(null, 'cancel');
      dismissPointModal(null, 'cancel');
      // reset mutation to ensure isPaused resets when offline
      updateMeasure.reset();
      setSelectedPoint(null);
  } 
  }, [
    updateMeasure.isSuccess,
    updateMeasure.isPaused,
    updateMeasure,
    setSelectedPoint,
    dismissPointModal,
    dismissMeasureModal,
  ]);
  return (
      <MobilePage
        defaultBackHref={`/search/plots/${id}`}
        headerExtension={
          <>
            <PlotToolbar plotId={Number(id)} />
            <MeasuresToolbar
              status={analysis?.analysis.status}
              title={t(`plot:yield_analysis`)}
            />
            {pointsReady ? (
              <IonToolbar
                color="success"
                style={{ '--min-height': 10, paddingBlock: 8 }}
                onClick={handleGoToPlots}
              >
                <IonTitle>{t('analysis:points_ready')}</IonTitle>
              </IonToolbar>
            ) : null}
          </>
        }
      >
        <PlotMap
          mobile={true}
          plot={plot.data}
          points={zoning.data?.points}
          selectedPointId={selectedPoint ? selectedPoint : undefined}
          type={PlotMapType.Zoning}
          validatedPoints={validatedPoints}
          zoning={zoning.data?.satzones}
          onPointClick={handleSelectPoint}
        >
          <GeolocateControl />
        </PlotMap>
      </MobilePage>
  );
}

export default Measures;
