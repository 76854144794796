import { Network } from '@capacitor/network';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import { useAuth } from '@lidea/shared/data-access/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Offline } from '@lidea/shared/feature-offline';
import { QueryLoadingBar } from '@lidea/shared/ui/core';
import { useDisclosure } from '@mantine/hooks';
import { alertCircleOutline, logOutOutline, wifiOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LangSelect } from './../lang-select/lang-select';
import styles from './mobile-header.module.css';

/* eslint-disable-next-line */
export interface MobileHeaderProps {
  headerExtension?: JSX.Element;
  defaultBackHref?: string;
}

export function MobileHeader(props: MobileHeaderProps) {
  const history = useHistory();
  const { i18n, t } = useTranslation(['common'], { useSuspense: false });
  const { logout } = useAuth();
  const toolbarRef = useRef<HTMLIonToolbarElement>(null);
  const [offlineOpened, offlineHandlers] = useDisclosure();
  const [successToastOpened, successToastHandlers] = useDisclosure();
  const [networkConnected, setNetworkConnected] = useState(true);
  const [presentAlert] = useIonAlert();
  const handleLangSelect = async (locale: string) => {
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    // set initial network status
    Network.getStatus().then((status) => {
      setNetworkConnected(status.connected);
    });
    // listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });
  }, []);

  const handleFinished = () => {
    offlineHandlers.close();
    localStorage.setItem('finishedSetupOffline', 'true');
    history.push('/map');
    document.location.reload();
  };

  const [presentOfflineModal, dismissOfflineModal] = useIonModal(Offline, {
    onFinished: handleFinished,
    dismiss: () => dismissOfflineModal(),
  });

  const handleNetworkClick = () => {
    presentOfflineModal({
      cssClass: 'page-modal',
    });
  };

  const handleLogout = () => {
    presentAlert({
      header: t('common:logout_alert_header'),
      message: t('common:logout_alert_message'),
      buttons: [
        {
          text: t('common:cancel'),
          role: 'cancel',
        },
        {
          text: t('common:confirm'),
          role: 'confirm',
          handler: () => {
            logout();
          },
        },
      ],
    });
  };

  useEffect(() => {
    const finishedSetupOffline = localStorage.getItem('finishedSetupOffline');
    if (finishedSetupOffline) {
      successToastHandlers.open();
      localStorage.removeItem('finishedSetupOffline');
    }
  }, []);

  return (
    <>
      <IonHeader>
        <IonToolbar
          ref={toolbarRef}
          style={{ overflow: 'visible', contain: 'none' }}
        >
          <IonButtons slot="start">
            <IonBackButton defaultHref={props.defaultBackHref}></IonBackButton>
          </IonButtons>
          <IonTitle>
            <div className={styles.logo}>
              <img
                alt="Lidea logo"
                src="/logo-250x150.jpg"
                style={{ height: 40 }}
              />
            </div>
          </IonTitle>
          <IonButtons slot="end">
            <LangSelect
              locale={i18n.language}
              showOptionLabel={false}
              showSelectedLabel={false}
              onSelect={handleLangSelect}
            />
            <IonButton
              color={!networkConnected ? 'danger' : undefined}
              onClick={handleNetworkClick}
            >
              <div
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonIcon
                  icon={networkConnected ? wifiOutline : alertCircleOutline}
                  slot="icon-only"
                />
              </div>
            </IonButton>
            {/* <IonButton>
              <IonIcon icon={settingsOutline} slot="icon-only"></IonIcon>
            </IonButton> */}
            <IonButton onClick={handleLogout}>
              <IonIcon icon={logOutOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
          <QueryLoadingBar />
        </IonToolbar>
        {props.headerExtension}
      </IonHeader>

      <IonToast
        duration={5000}
        isOpen={successToastOpened}
        message="Téléchargement des données hors ligne terminé avec succès !"
        onDidDismiss={successToastHandlers.close}
      ></IonToast>
    </>
  );
}

export default MobileHeader;
