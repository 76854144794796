import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { zoningApi } from '../../apis/zoning-api';
import { heatmapKeys } from './../../keys/heatmapKeys';

export function useImageryIndexes() {
  const imageryIndexes = useQuery({
    queryKey: heatmapKeys.imageryIndexes(),
    queryFn: () => zoningApi.getImageryIndexes(),
  });

  const imageryIndexesAsOptions = useMemo(() => {
    if (!imageryIndexes.data?.list_indexes) {
      return [];
    }
    return imageryIndexes.data.list_indexes.map((index) => {
      return {
        value: index,
        label: index,
      };
    });
  }, [imageryIndexes]);

  return {
    imageryIndexes,
    imageryIndexesAsOptions,
  };
}

export default useImageryIndexes;
