import { IonButton, IonContent, IonIcon, IonList, IonRippleEffect } from '@ionic/react';
import { AnalysisSummary } from '@lidea/plots/shared/ui';
import { useResetAnalysis } from '@lidea/shared/data-access/plots';
import { SheetModal } from '@lidea/shared/ui/core';
import { DeleteAlert } from '@lidea/shared/ui/core';
import { YieldAnalysis, ZoningStatus } from '@lidea/shared/util/types';
import { useDisclosure } from '@mantine/hooks';
import { listOutline, readerOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import SatelliteItem from '../satellite-item/satellite-item';
import AnalysisCard from './../analysis-card/analysis-card';
import styles from './plot-details-sheet.module.css';

/* eslint-disable-next-line */
export interface PlotDetailsSheetProps {
  plotId: number;
  yieldAnalysis?: YieldAnalysis;
  onProtocolEdit?: () => void;
  onOpenComments?: () => void;
  onOpenYieldDetails?: () => void;

  yieldDetails: {
    computed_yield?: number | null;
    estimatedYield?: number | null;
    yield_doses?: number | null;
    objYield?: number | null;
    percentYield?: number | null;
  };
}

export function PlotDetailsSheet(props: PlotDetailsSheetProps) {
  const { t } = useTranslation(['plot'], { useSuspense: false });
  const initialBreakpoint = 110 / window.innerHeight;
  const yieldReady = props.yieldAnalysis?.status === ZoningStatus.Ready;
  const [resetDialogOpened, resetDialogHandlers] = useDisclosure();
  const resetAnalysis = useResetAnalysis({ plotId: props.plotId ? props.plotId : 0});

  const handleReset = () => {
    if (props.yieldAnalysis?.id || 0) {
      resetAnalysis.mutate({ analysisId: props.yieldAnalysis?.id || 0 });
    }
    resetDialogHandlers.close();
  };

  return (
    <SheetModal
      breakpoints={[initialBreakpoint, 0.75]}
      data-testid="plot-details-sheet"
      initialBreakpoint={initialBreakpoint}
      locationRegex={/^\/search\/plots\/(\w+)$/}
    >
      <div className="ion-page">
        <IonContent>
          <div className={styles['sheet-buttons']}>
            <button
              className="ion-activatable ripple-parent"
              onClick={props.onOpenComments}
            >
              <IonIcon icon={readerOutline} size="large" />
              {t('plot:notes_comments')}
              <IonRippleEffect></IonRippleEffect>
            </button>
            <button
              className="ion-activatable ripple-parent"
              onClick={props.onProtocolEdit}
            >
              <IonIcon icon={listOutline} size="large" />
              {t('plot:edit_protocol')}
              <IonRippleEffect></IonRippleEffect>
            </button>
          </div>

          <IonList>
            <SatelliteItem
              plotId={props.plotId}
              yieldAnalysis={props.yieldAnalysis}
            />
          </IonList>

          <AnalysisCard
            analysisId={props.yieldAnalysis?.id || 0}
            dataTestid="yield-analysis"
            disabled={!props.yieldAnalysis}
            plotId={props.plotId}
            status={props.yieldAnalysis?.status || ZoningStatus.ToBeDone}
            title={t('plot:yield_analysis') as string}
          >
            <AnalysisSummary summary={props.yieldAnalysis?.summary || []} />
          </AnalysisCard>

          {props.yieldAnalysis?.status === ZoningStatus.Ready || 
          props.yieldAnalysis?.status === ZoningStatus.InProgress ? (
            <IonButton
              className={styles.resetButton}
              color="danger"
              // disabled={loading}
              size="small"
              onClick={resetDialogHandlers.open}
            >
              {t('common:reset')}
            </IonButton>
          ) : null}

        <DeleteAlert
          isOpen={resetDialogOpened}
          message={t('plot:alert_reset_analysis') as string}
          onCancel={resetDialogHandlers.close}
          onConfirm={handleReset}
        />

          {yieldReady ? (
            <button
              className={styles.yieldDetailsButton}
              onClick={props.onOpenYieldDetails}
            >
              <AnalysisCard 
                color="tertiary" 
                disabled
              >
                <AnalysisSummary
                  summary={[
                    {
                      label: {
                        fr: t('plot:yield_ref'),
                        en: t('plot:yield_ref'),
                      },
                      value: `${props.yieldDetails.objYield === null ? '- qx/ha' : props.yieldDetails.objYield} qx/ha` || null,
                    },
                    {
                      label: {
                        fr: t('plot:yield_doses'),
                        en: t('plot:yield_doses'),
                      },
                      value: `${props.yieldDetails.yield_doses === null ? '- doses/ha' : props.yieldDetails.yield_doses} doses/ha` || null,
                    },
                    {
                      label: {
                        fr: t('plot:estimated_yield'),
                        en: t('plot:estimated_yield'),
                      },
                      value: `${props.yieldDetails.computed_yield === null ? '- qx/ha' : props.yieldDetails.computed_yield} qx/ha` || null,
                    },
                    {
                      label: {
                        fr: t('plot:adjusted_yield'),
                        en: t('plot:adjusted_yield'),
                      },
                      value: `${props.yieldDetails.estimatedYield === null ? '- qx/ha' : props.yieldDetails.estimatedYield} qx/ha` || null,
                    },
                    {
                      label: {
                        fr: t('plot:pourcentage_reached'),
                        en: t('plot:pourcentage_reached'),
                      },
                      value: `${props.yieldDetails.percentYield === null ? '-' : props.yieldDetails.percentYield} %` || null,
                    },
                  ]}
                />
              </AnalysisCard>
            </button>
          ) : null}
        </IonContent>
      </div>
    </SheetModal>
  );
}

export default PlotDetailsSheet;
