import { IonContent, IonModal } from '@ionic/react';
import { LoginForm, LoginFormValues } from '@lidea/auth/shared/ui-login-form';
import { useAuth } from '@lidea/shared/data-access/core';
import { useEffect } from 'react';

import styles from './login-modal.module.css';

/* eslint-disable-next-line */
export interface LoginModalProps {
  isOpen: boolean;
  close: () => void;
}

export function LoginModal(props: LoginModalProps) {
  const { token, login } = useAuth();

  const handleSubmit = (event: LoginFormValues) => {
    login(event.email, event.password);
  };

  useEffect(() => {
    if (token) {
      props.close();
    }
  }, [token, props]);

  return (
    <IonModal canDismiss={!!token} isOpen={props.isOpen}>
      <IonContent className="ion-padding">
        <div className={styles['logo-wrapper']}>
          <img alt="Lidea logo" src="/logo-256x256.jpg" />
        </div>
        <LoginForm onSubmit={handleSubmit} />
      </IonContent>
    </IonModal>
  );
}

export default LoginModal;
