import 'dayjs/locale/fr';

import { IonButton } from '@ionic/react';
import { PfInput } from '@lidea/shared/ui/form';
import { YieldDetailsValues } from '@lidea/shared/util/types';
import { useForm } from '@mantine/form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './yield-details-form.module.css';

// const protocolFields = ['contract_area'];

/* eslint-disable-next-line */
export interface YieldDetailsFormProps {
  initialValues?: YieldDetailsValues;
  onSubmit: (event: YieldDetailsValues) => void;
  onCancel?: () => void;
  i18n: {
    confirm: string;
    cancel: string;
  };
}

export function YieldDetailsForm(props: YieldDetailsFormProps) {
  const { t } = useTranslation(['common', 'plot'], { useSuspense: false });
  const initialValues = useMemo(() => {
    if (props.initialValues) {
      return {
        ...props.initialValues,
      };
    }
    return {};
  }, [props.initialValues]);

  const form = useForm<YieldDetailsValues>({
    initialValues,
  });

  const handleSubmit = (values: YieldDetailsValues) => {
    const formattedValues = {
      ...values,
    };
    props.onSubmit(formattedValues);
  };

  return (
    <form
      className={styles['container']}
      onSubmit={form.onSubmit((values) => {
        handleSubmit(values);
      })}
    >
      <div className={styles['fields-container']}>
        <PfInput
          fill="outline"
          height="small"
          label={t(`plot:adjusted_yield`) as string}
          labelPlacement="stacked"
          placeholder={t(`plot:input_placeholder`) as string}
          {...form.getInputProps('estimatedYield')}
        />
      </div>

      <div className={styles['buttons-container']}>
        <input style={{ display: 'none' }} type="submit" />

        <IonButton color="danger" onClick={props.onCancel}>
          {props.i18n.cancel}
        </IonButton>
        <IonButton color="success" type="submit">
          {props.i18n.confirm}
        </IonButton>
      </div>
    </form>
  );
}

export default YieldDetailsForm;
