import { Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import styles from './analysis-loader.module.css';

/* eslint-disable-next-line */
export interface AnalysisLoaderProps {}

export function AnalysisLoader(props: AnalysisLoaderProps) {
  const { t } = useTranslation(['plot'], { useSuspense: false });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '30px 10px 5px, 10px',
        fontSize: '12px',
        gap: 8,
      }}
    >
      <span style={{ color: 'black' }}>{t('plot:preparing_analysis')}</span>
      <Loader size={'sm'}/>
    </div>
  );
}

export default AnalysisLoader;
