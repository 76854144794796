import {
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import { usePlot } from '@lidea/shared/data-access/plots';
import { useDisclosure } from '@mantine/hooks';
import { center } from '@turf/turf';
import {
  informationCircleOutline,
  mapOutline,
  pencilOutline,
} from 'ionicons/icons';
import { useMemo } from 'react';

import PlotInfoModal from '../../components/plot-info-modal-content/plot-info-modal-content';
import styles from './plot-toolbar.module.css';

/* eslint-disable-next-line */
export interface PlotToolbarProps {
  plotId: number;
}

export function PlotToolbar(props: PlotToolbarProps) {
  const plot = usePlot(props.plotId);
  const [presentInfo, dismissInfo] = useIonModal(PlotInfoModal, {
    plotId: props.plotId,
    dissmiss: () => dismissInfo(),
  });

  const geoUrl = useMemo(() => {
    if (!plot.data) {
      return '';
    }
    const plotCenter = center(plot.data).geometry.coordinates.reverse();
    return `geo:${plotCenter?.join(',')}`;
  }, [plot.data]);

  return (
    <IonToolbar data-testid="plot-header">
      <IonButtons slot="start">
        <IonButton data-testid="open-map" href={geoUrl}>
          <IonIcon icon={mapOutline} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonTitle className={styles.title}>{plot.data?.properties.name}</IonTitle>
      <IonButtons slot="end">
        <IonButton
          data-testid="change-boundarie"
          routerLink={`/search/plots/${props.plotId}/edit`}
        >
          <IonIcon
            aria-label="change boundarie"
            icon={pencilOutline}
            size="large"
            slot="icon-only"
          />
        </IonButton>
        <IonButton
          data-testid="open-info"
          onClick={() => {
            presentInfo({
              cssClass: 'page-modal',
            });
          }}
        >
          <IonIcon
            aria-label="open info"
            icon={informationCircleOutline}
            slot="icon-only"
          />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
}

export default PlotToolbar;
