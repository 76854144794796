import { SelectedFilters } from '../../filters-store/filters-store';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const cleanFilters = (filters: SelectedFilters) => {
  const cleanedFilters: any = {};

  (Object.entries(filters) as Entries<SelectedFilters>).forEach((entry) => {
    if (entry) {
      const [key, values] = entry;

      switch (key) {
        case 'search_query': {
          cleanedFilters[key] = values;
          break;
        }

        default: {
          if (values && values.length) {
            cleanedFilters[key] = `[${values.join(',')}]`;
          }
          break;
        }
      }
    }
  });

  return cleanedFilters;
};
