import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import {
  useCanMergePlots,
  useMergePlots,
} from '@lidea/shared/data-access/plots';
import { MergeAlert } from '@lidea/shared/ui/core';
import { PfSelect } from '@lidea/shared/ui/form';
import { ModalPage } from '@lidea/shared/ui/layout';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Checkbox } from '@mui/material';
import { arrowBackOutline } from 'ionicons/icons';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { text } from 'stream/consumers';

import ColorScale from '../color-scale/color-scale';
import ImageLayer from '../image-layer/image-layer';
import styles from './satellite-map-modal.module.css';

const root = import.meta.env.VITE_API_ROOT;

const typeMap = [
  {
    value: 'NDVI',
    label: 'NDVI',
  },
  {
    value: 'LAI',
    label: 'LAI',
  },
  {
    value: 'NDWI',
    label: 'NDWI',
  },
  {
    value: 'RGB',
    label: 'Photo satellitaire',
  },
];

/* eslint-disable-next-line */
export interface SatelliteMapModalProps {
  infoPlot: any[]; // Remplacez any par le type réel de vos données
  colorbar?: { min: number; max: number; color: string[] };
  imageryIndex: string;
  setImageryIndex: (value: string) => void;
  clusterData: any;
  selectedPlot: any;
  numberOfPlots: number;
  onCheckboxChange?: (id: number, checked: boolean) => void;
  dismiss: () => void;
  handleImageSatellite: (
    coordinates: number[][],
    id: number[],
    imageUrl: string[]
  ) => void;
  dateIndices: number[];
  setDateIndices: (value: number[]) => void;
  onDateChange: (id: number, direction: string) => void;
  allPlots: boolean;
  setAllPlots: (value: boolean) => void;
}

export function SatelliteMapModal(props: SatelliteMapModalProps & { imageryIndex: string }) {
  const { t } = useTranslation(['map', 'plot', 'common'], {useSuspense: false});

  const [mergeDialogOpened, mergeDialogHandlers] = useDisclosure();
  const [selectedPlots, setSelectedPlots] = useState<{
    all: boolean;
    plotsIds: number[];
  }>({ all: false, plotsIds: [] });

  const canMergePlots = useCanMergePlots({
    plotsIds: selectedPlots.all ? [] : selectedPlots.plotsIds,
    all: selectedPlots.all,
  });

  const mergePlots = useMergePlots();
  const [mergePlotId, setMergePlotId] = useState<number | null>(null);
  const allPlotsIds = props.numberOfPlots;

  // TODO: reset
  const resetSatelliteMap = () => {
    props.setImageryIndex('');
    setSelectedPlots({ all: false, plotsIds: [] });
  };

  // TODO: props control
  const handleSelectPlots = useCallback(
    (all: boolean, plotsIds: readonly number[]) => {
      setSelectedPlots({
        all,
        plotsIds: plotsIds as number[],
      });
    },
    []
  );

  const handleCancelMergePlot = () => {
    setMergePlotId(null);
    mergeDialogHandlers.close();
  };

  const handleMergePlot = () => {
    mergePlots.mutate({
      plotsIds: selectedPlots.plotsIds,
      all: selectedPlots.all,
      forceMerge: true,
    });

    notifications.show({
      id: 'merge-plot',
      message: t('map:message_merge'),
      color: 'green',
      withCloseButton: true,
      loading: false,
      autoClose: true,
    });
  };

  const handleClickMerge = () => {
    console.log('click');
    if (canMergePlots.data?.can_be_merged === 'yes_but') {
      mergeDialogHandlers.open();
    } else {
      mergePlots.mutate({
        plotsIds: selectedPlots.plotsIds,
        all: selectedPlots.all,
        forceMerge: true,
      });

      notifications.show({
        id: 'merge-plot',
        message: t('map:message_merge'),
        color: 'green',
        withCloseButton: true,
        loading: false,
        autoClose: true,
      });
    }
  };

  const handleDateChange = (id: number, direction: string) => {
    props.onDateChange(id, direction);
  };

  useEffect(() => {
    if (mergePlots.isSuccess) {
      setSelectedPlots({ all: false, plotsIds: [] });
    }
  }, [mergePlots.isSuccess]);

  // TODO: control
  useEffect(() => {
    if (props.selectedPlot) {
      const selectedIds = Object.keys(props.selectedPlot)
        .map(Number)
        .filter((id) => props.selectedPlot[id])
        .map(Number);

      handleSelectPlots(selectedIds.length === allPlotsIds, selectedIds);
    }
  }, [props.selectedPlot]);


  return (
    <ModalPage
      title={t('plot:satellite_map')}
      onClose={props.dismiss}
      // headerExtension={
      //   <IonToolbar>
      //     <IonButtons>
      //       <IonButton color="danger">Reset</IonButton>

      //       <IonButton>visualiser</IonButton>
      //     </IonButtons>
      //   </IonToolbar>
      // }
    >
      <IonContent>
        <div className={styles['container']}>
          <div className={styles['container-bottom']}>
            <div
              className={
                props.imageryIndex
                  ? styles['container-left']
                  : styles['full-width']
              }
            >
              <div className={`${styles['container']} mapboxgl-ctrl`}>
                {props.imageryIndex !== 'NDVI' &&
                props.imageryIndex !== 'LAI' &&
                props.imageryIndex !== 'NDWI' &&
                props.imageryIndex !== 'RGB' ? (
                  <div className={styles['withoutType']}>
                    <PfSelect
                      ariaLabel={
                        t('analysis:image_indexes_placeholder') as string
                      }
                      data-testid="select-satellite-map"
                      interface="popover"
                      options={typeMap}
                      placeholder={t('plot:select_satellite_map') as string}
                      wrapperClassName={styles['select-wrapper']}
                      onIonChange={(e) => {
                        props.setImageryIndex(e.detail.value);
                        setSelectedPlots({ all: false, plotsIds: [] });
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles['withType']}>
                    <div>
                      <IonButton
                        aria-label="back"
                        slot="icon-only"
                        onClick={resetSatelliteMap}
                        style={{ textTransform: 'none', marginTop: '10px' }}
                      >
                        {t('common:reset')}
                      </IonButton>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PfSelect
                        ariaLabel={
                          t('analysis:image_indexes_placeholder') as string
                        }
                        data-testid="select-satellite-map"
                        interface="popover"
                        options={typeMap}
                        placeholder={t('plot:select_satellite_map') as string}
                        value={props.imageryIndex}
                        wrapperClassName={styles['select-wrapper-type']}
                        onIonChange={(e) => {
                          props.setImageryIndex(e.detail.value);
                          setSelectedPlots({ all: false, plotsIds: [] });
                        }}
                      />
                    </div>

                    <div>
                      <p></p>
                    </div>
                  </div>
                )}

                <div className={styles['allParcel']}>
                  {props.imageryIndex ? (
                    <div key="all-plots" className={styles['ligne']}>
                      <div className={styles['listParcelAll']}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: '500',
                            fontSize: '18px',
                          }}
                        >
                          {t('plot:all_plots')}
                        </div>
                      </div>

                      <div className={styles['listChekbox']}>
                        <Checkbox
                          checked={props.allPlots}
                          onChange={() => {
                            props.setAllPlots(!selectedPlots.all);
                            handleSelectPlots(!selectedPlots.all, []);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}

                  {props.infoPlot?.map((item, id) => (
                    <React.Fragment key={id}>
                      {item.rasters !== null && props.imageryIndex ? (
                        <div key={id} className={styles['ligne']}>
                          {item.rasters && item.rasters.length > 0 && (
                            <div className={styles['buttonPrev']}>
                              <button onClick={() => handleDateChange(id, 'prev')}>
                                <ArrowBackIcon
                                  sx={{
                                    fontSize: 15,
                                    backgroundColor: 'white',
                                  }}
                                />
                              </button>
                            </div>
                          )}

                          <div
                            className={
                              item.rasters.length > 0
                                ? styles['listParcel']
                                : styles['listParcelNull']
                            }
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign:
                                  item.rasters && item.rasters.length === 0
                                    ? 'left'
                                    : 'center',
                                left: '50%',
                                fontWeight: '500',
                                color:
                                  item.rasters && item.rasters.length === 0
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {item.plotName ? item.plotName : null}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontStyle: 'italic',
                                fontSize: 'medium',
                              }}
                            >
                              {item.rasters !== null
                                ? item.rasters[props.dateIndices[id]]?.date
                                : null}
                            </div>
                            {props.imageryIndex !== 'RGB' &&
                              item.rasters !== null &&
                              item.rasters.length > 0 && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontStyle: 'italic',
                                    fontSize: '12px',
                                  }}
                                >
                                  {t('plot:heterogeneity_index')} :{' '}
                                  {item.rasters !== null
                                    ? item.rasters[props.dateIndices[id]]
                                        ?.heterogeneity
                                    : null}
                                </div>
                              )}
                          </div>

                          {item.rasters && item.rasters.length > 0 && (
                            <div className={styles['buttonNext']}>
                              <button onClick={() => handleDateChange(id, 'next')}>
                                <ArrowForwardIcon
                                  sx={{
                                    fontSize: 12,
                                    backgroundColor: 'white',
                                  }}
                                />
                              </button>
                            </div>
                          )}

                          <div className={styles['listChekbox']}>
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                props.onCheckboxChange?.(
                                  item.plotId,
                                  e.target.checked
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div key={id} className={styles['ligne']}>
                          <div className={styles['listParcelAll']}>
                            <div>{item.plotName ? item.plotName : null}</div>
                          </div>
                          <div className={styles['listChekbox']}>
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                props.onCheckboxChange?.(
                                  item.plotId,
                                  e.target.checked
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {selectedPlots.plotsIds.length > 1 ? (
                <div className={styles['button-merge']}>
                  <button
                    disabled={canMergePlots.data?.can_be_merged === 'no'}
                    style={{
                      backgroundColor:
                        canMergePlots.data?.can_be_merged === 'no'
                          ? 'grey'
                          : 'green',
                      color: 'white',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '12px',
                      margin: '5px',
                      borderRadius: '5px',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                    }}
                    onClick={handleClickMerge}
                  >
                    {t('plot:merge_plots')}
                  </button>
                </div>
              ) : null}
              <MergeAlert
                isOpen={mergeDialogOpened}
                onCancel={handleCancelMergePlot}
                onConfirm={handleMergePlot}
              />
            </div>
          </div>

          {props.imageryIndex ? (
            <div className={styles['container-bottom-2']}>
              {props.imageryIndex &&
              props.imageryIndex !== 'RGB' &&
              props.colorbar ? (
                <ColorScale
                  colorGradient={props.colorbar.color}
                  max={props.colorbar.max}
                  min={props.colorbar.min}
                  mobile={true}
                />
              ) : null}
              {props.imageryIndex == 'RGB' && (
                <p style={{ textAlign: 'center' }}>{t('plot:no_colorbar')}</p>
              )}
            </div>
          ) : null}
        </div>
      </IonContent>
    </ModalPage>
  );
}

export default SatelliteMapModal;
