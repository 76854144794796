import { zoomOnFeature } from '@lidea/shared/util/core';
import {
  PlotFeature,
  PointsFeatureCollection,
  SatzonesFeatureCollection,
} from '@lidea/shared/util/types';
import { bbox } from '@turf/turf';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef, ViewState, ViewStateChangeEvent } from 'react-map-gl';

import ImageLayer from '../image-layer/image-layer';
import LegendControl from '../legend-control/legend-control';
import PfMap from '../pf-map/pf-map';
import PlotsLayer from '../plots-layer/plots-layer';
import PointsMarkers from '../points-markers/points-markers';
import ZoningLayer from '../zoning-layer/zoning-layer';
import AnalysisLegend from './../analysis-legend/analysis-legend';
import styles from './plot-map.module.css';

export enum PlotMapType {
  Heatmap = 'heatmap',
  Zoning = 'zoning',
  Plot = 'plot',
}
export type ZoningType = 'densityAnalysis' | 'yieldAnalysis' | 'yield';

/* eslint-disable-next-line */
export interface MapHeatmapProps {
  type: PlotMapType.Heatmap;
  image?: string;
  coordinates?: number[][];
}

/* eslint-disable-next-line */
export interface MapZoningProps {
  type: PlotMapType.Zoning;
  selectedPointId?: number;
  zoningType?: ZoningType;
  onPointClick?: (id: number) => void;
}

/* eslint-disable-next-line */
export interface MapPlotProps {
  type: PlotMapType.Plot;
}

export type PlotMapProps = (MapHeatmapProps | MapZoningProps | MapPlotProps) & {
  children?: React.ReactNode;
  plot?: PlotFeature;
  zoning?: SatzonesFeatureCollection;
  points?: PointsFeatureCollection;
  validatedPoints?: number[];
  mobile?: boolean;
  viewState?: ViewState;
  onMoveStart?: (e: ViewStateChangeEvent) => void;
  onMove?: (e: ViewStateChangeEvent) => void;
};

export const PlotMap = memo((props: PlotMapProps) => {
  const { t } = useTranslation(['map'], { useSuspense: false });
  const mapRef = useRef<MapRef>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const legend: {
    zone: number;
    color: string;
    value?: number;
    percent?: number;
  }[] = useMemo(() => {
    if (props.type === PlotMapType.Zoning && props.zoning) {
      const l = props.zoning.features.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.properties.zone]: {
            zone: feature.properties.zone,
            color: feature.properties.color,
            value: feature.properties.value,
            percent: feature.properties.percent,
          },
        };
      }, {});

      return Object.values(l);
    }
    return [];
  }, [props.type, props.zoning]);

  useEffect(() => {
    zoomOnFeature({
      geojson: props.plot,
      map: mapRef.current?.getMap(),
      animate: false,
    });
  }, [props.plot, mapLoaded]);

  return (
    <PfMap
      {...props.viewState}
      ref={mapRef}
      onLoad={() => setMapLoaded(true)}
      onMove={props.onMove}
      onMoveStart={props.onMoveStart}
    >
      {props.type === PlotMapType.Zoning && props.zoning ? (
        <LegendControl>
          <AnalysisLegend legend={legend} zoningType={props.zoningType} />
        </LegendControl>
      ) : null}

      {props.plot ? (
        <PlotsLayer
          data={{ type: 'FeatureCollection', features: [props.plot] }}
          outline={props.type !== PlotMapType.Plot}
        />
      ) : null}

      {props.type === PlotMapType.Zoning && props.zoning ? (
        <ZoningLayer data={props.zoning} />
      ) : null}

      {props.type === PlotMapType.Zoning && props.points ? (
        <PointsMarkers
          mobile={props.mobile}
          points={props.points}
          selectedPointId={props.selectedPointId}
          validatedPoints={props.validatedPoints}
          onPointClick={props.onPointClick || (() => null)}
        />
      ) : null}

      {props.type === PlotMapType.Heatmap &&
      props.image &&
      props.coordinates ? (
        <ImageLayer coordinates={props.coordinates} imageUrl={props.image} />
      ) : null}

      {props.children}
    </PfMap>
  );
});

export default PlotMap;
