import { YieldDetailsForm } from '@lidea/plots/shared/ui';
import { ModalPage } from '@lidea/shared/ui/layout';
import { PlotFeature, YieldDetailsValues } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

interface EditProtocolModalProps {
  plot: PlotFeature;
  dismiss: (data: any, role: string) => void;
  onSubmit: (e: YieldDetailsValues) => void;
}

const YieldDetailsModal: React.FC<EditProtocolModalProps> = (props) => {
  const { t } = useTranslation(['common', 'plot'], { useSuspense: false });
  const values = {
    computed_yield: props.plot.properties.computed_yield,
    estimatedYield: props.plot.properties.estimatedYield,
  }
  return (
    <ModalPage
      title={t('plot:edit_estimated_yield')}
      onClose={() => props.dismiss(null, 'cancel')}
    >
      <YieldDetailsForm
        i18n={{
          confirm: t('common:validate'),
          cancel: t('common:cancel'),
        }}
        initialValues={values}
        onCancel={() => props.dismiss(null, 'cancel')}
        onSubmit={props.onSubmit}
      />
    </ModalPage>
  );
};

export default YieldDetailsModal;
