import { useId, useResizeObserver } from '@mantine/hooks';
import { Map as IMap } from 'mapbox-gl';
import { forwardRef, useEffect, useRef } from 'react';
import Map, { MapboxEvent, MapRef } from 'react-map-gl';

import styles from './pf-map.module.css';

const baseViewState = {
  longitude: 2.73457,
  latitude: 47.4138,
  zoom: 6,
};

type MapProps = React.ComponentProps<typeof Map>;

/* eslint-disable-next-line */
export interface PfMapProps extends MapProps {
  children?: React.ReactNode;
}

const isTest =
  typeof process !== 'undefined'
    ? process.env['NEXT_PUBLIC_CYPRESS'] === 'true'
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - import is used by vite
      import.meta.env.VITE_CYPRESS === 'true';

export const PfMap = forwardRef<MapRef, PfMapProps>((props, ref) => {
  const id = useId();
  const [containerRef, rect] = useResizeObserver();
  const mapRef = useRef<IMap>();
  const token = useRef(
    typeof process !== 'undefined'
      ? process.env['NEXT_PUBLIC_MAPBOX_TOKEN'] ||
          process.env['NX_MAPBOX_TOKEN'] ||
          process.env['STORYBOOK_MAPBOX_TOKEN']
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - import is used by vite
        import.meta.env.VITE_MAPBOX_TOKEN
  );

  const handleLoad = (e: MapboxEvent) => {
    e.target.resize();

    props.onLoad?.(e);
    mapRef.current = e.target;
    // add .map-loaded class to body
    if (typeof document !== 'undefined') {
      document.body.setAttribute('data-testid', 'map-loaded');
    }
  };

  const { initialViewState, children } = props;

  useEffect(() => {
    mapRef.current?.resize();
  }, [rect]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Map
        {...props}
        ref={ref}
        attributionControl={false}
        id={props.id || id}
        initialViewState={initialViewState || baseViewState}
        interactiveLayerIds={props.interactiveLayerIds}
        //logoPosition="bottom-right"
        mapboxAccessToken={!isTest ? token.current : undefined}
        mapStyle={
          !isTest
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : {
                version: 8,
                glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
                layers: [
                  {
                    id: 'background',
                    type: 'background',
                    paint: { 'background-color': 'rgb(4,7,14)' },
                  },
                ],
                sources: {},
              }
        }
        refreshExpiredTiles={false}
        style={{ width: '100%', height: '100%' }}
        testMode={isTest}
        reuseMaps
        onClick={props.onClick}
        onLoad={handleLoad}
      >
        {children}
      </Map>
    </div>
  );
});

export default PfMap;
