import { IonModal } from '@ionic/react';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './sheet-modal.module.css';

type IonModalProps = React.ComponentProps<typeof IonModal>;

export interface SheetModalProps extends IonModalProps {
  locationRegex?: RegExp;
  isScroll?: boolean;
  'data-testid'?: string;
}

export function SheetModal(props: SheetModalProps) {
  const modal = useRef<HTMLIonModalElement>(null);
  const location = useLocation();
  const isOpen = useMemo(() => {
    if (!props.locationRegex) {
      return true;
    }
    return !!location.pathname.match(props.locationRegex)?.length;
  }, [location, props.locationRegex]);

  return (
    <IonModal
      ref={modal}
      backdropBreakpoint={1}
      breakpoints={props.breakpoints}
      canDismiss={!isOpen}
      className={styles['sheet']}
      data-testid={props['data-testid'] || 'details-sheet'}
      handleBehavior="cycle"
      initialBreakpoint={props.initialBreakpoint}
      isOpen={isOpen}
      showBackdrop={false}
      style={{
        bottom: 75.5,
      }}
    >
      {props.children}
    </IonModal>
  );
}

export default SheetModal;
