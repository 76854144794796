import { IonList } from '@ionic/react';
import { PlotItem, SearchToolbar } from '@lidea/search/mobile/shared/ui';
import { FiltersModal } from '@lidea/search/shared/feature-filters-modal';
import {
  useFilterStore,
  useSelectedFilters,
} from '@lidea/shared/data-access/core';
import { plotQuery, useInfinitePlots } from '@lidea/shared/data-access/plots';
import { OFFLINE_PLOTS_STORAGE_KEY } from '@lidea/shared/feature-offline';
import { MobilePage } from '@lidea/shared/ui/layout';
import { PlotFeature, ZoningStatus } from '@lidea/shared/util/types';
import {
  useDebouncedState,
  useDisclosure,
  useLocalStorage,
  useNetwork,
  usePrevious,
} from '@mantine/hooks';
import { useQueries } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import styles from './list-tab.module.css';

interface FooterProps {
  context?: {
    hasNextPage?: boolean;
  };
}

/* eslint-disable-next-line */
export interface ListTabProps {}

export function ListTab(props: ListTabProps) {
  const { t, i18n } = useTranslation(['plot'], { useSuspense: false });
  const [isModalOpen, modalHandlers] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);
  const previousSearchQuery = usePrevious(searchQuery);
  const [offlinePlotsIds] = useLocalStorage<number[]>({
    key: OFFLINE_PLOTS_STORAGE_KEY,
    defaultValue: [],
  });
  const networkStatus = useNetwork();

  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const setSearchQueryFilter = useFilterStore((state) => state.setSearchQuery);
  const { selectedFiltersCount } = useSelectedFilters();
  const infinitePlots = useInfinitePlots({
    selectedFilters,
    enabled: networkStatus.online,
  });
  const offlinePlots = useQueries({
    queries: offlinePlotsIds.map((plotId) => {
      return {
        ...plotQuery(plotId),
        enabled: !networkStatus.online,
      };
    }),
  });

  const locale = i18n.language as 'fr' | 'en';

  const Footer = (props: FooterProps) => {
    return (
      <div
        style={{
          padding: '2rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {props.context?.hasNextPage ? (
          <>{t('plot:loading_plots')}</>
        ) : (
          <>{t('plot:nothing_load')}</>
        )}
      </div>
    );
  };
  // reduce plots pages to a single array
  // or return offline plots if offline
  const plots = useMemo(() => {
    if (!networkStatus.online) {
      return offlinePlots
        .map((q) => q.data)
        .filter((data) => data !== undefined)
        .filter((data) =>
          data.properties.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase().trim())
        );
    }

    if (!infinitePlots.data) {
      return [];
    }
    return infinitePlots.data.pages.reduce((acc, page) => {
      return [...acc, ...page.results.features];
    }, [] as PlotFeature[]);
  }, [infinitePlots.data, networkStatus.online, offlinePlots, searchQuery]);

  const handleSearchChange = (ev: any) => {
    setSearchQuery(ev.detail.value);
    if (!ev.detail.value) {
      setSearchQueryFilter('');
    }
  };

  const fetchNextPage = () => {
    if (networkStatus.online) {
      infinitePlots.fetchNextPage();
    }
  };

  useEffect(() => {
    const trimmedSearchQuery = searchQuery.trim();
    if (
      trimmedSearchQuery &&
      searchQuery !== previousSearchQuery &&
      networkStatus.online
    ) {
      setSearchQueryFilter(trimmedSearchQuery);
    }
  });

  return (
    <MobilePage
      headerExtension={
        <SearchToolbar
          filtersCount={selectedFiltersCount}
          onFilterClick={modalHandlers.open}
          onSearchTermChange={handleSearchChange}
        />
      }
    >
      <IonList className={classNames(styles['plot-list'], 'ion-padding')}>
        {infinitePlots.isSuccess ? (
          <Virtuoso
            components={{ Footer }}
            context={{
              hasNextPage: networkStatus.online && infinitePlots.hasNextPage,
            }}
            data={plots}
            endReached={fetchNextPage}
            itemContent={(index, { id, properties }) => {
              return (
                <div style={{ height: 'auto', marginBottom: 16, width: '98%' }}>
                  <PlotItem
                    key={id}
                    area={properties.area}
                    id={id as number}
                    name={properties.name}
                    ownerName={`${properties.farm.name} - ${
                      properties.variety[`name_${locale}`]
                    }`}
                    species={properties.species[`name_${locale}`]}
                    yieldStatus={
                      properties.analyzes[0]?.status || ZoningStatus.ToBeDone
                    }
                  />
                </div>
              );
            }}
            style={{ height: '100%' }}
            totalCount={50}
          />
        ) : null}
      </IonList>

      <FiltersModal
        confrmButtonColor="primary"
        isOpen={isModalOpen}
        modalClassName="page-modal"
        typeaheadModalClassName="page-modal"
        onWillDismiss={modalHandlers.close}
      />
    </MobilePage>
  );
}

export default ListTab;
