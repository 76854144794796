import { IonToggle } from '@ionic/react';
import { Portal } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import styles from './legend-control.module.css';

/* eslint-disable-next-line */
export interface LegendControlProps {
  children?: React.ReactNode;
}

export function LegendControl(props: LegendControlProps) {
  const { t } = useTranslation(['map'], { useSuspense: false });
  const [checked, setChecked] = useState(true);
  const map = useMap();
  const mapId = map.current?.getMap().getContainer().id;

  const handleChange = (event: CustomEvent) => {
    setChecked(event.detail.checked);
  };

  if (!mapId) {
    return null;
  }

  return (
    <>
      <Portal
        container={
          document &&
          document
            .getElementById(mapId)
            ?.getElementsByClassName('mapboxgl-ctrl-top-left')[0]
        }
      >
        <div className={`${styles['container']} mapboxgl-ctrl`}>
          <IonToggle
            checked={checked}
            labelPlacement="end"
            onIonChange={handleChange}
          >
            {t('map:legend')}
          </IonToggle>
        </div>
      </Portal>

      {checked ? (
        <Portal
          container={
            document &&
            document
              .getElementById(mapId)
              ?.getElementsByClassName('mapboxgl-ctrl-bottom-left')[0]
          }
        >
          <div className={`${styles['container']} mapboxgl-ctrl`}>
            {props.children}
          </div>
        </Portal>
      ) : null}
    </>
  );
}

export default LegendControl;
