import { MeasureDynamicField } from '@lidea/shared/util/types';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { MeasureFormValues } from '../measure-form';
import MeasuresInputsList from './../../measures-inputs-list/measures-inputs-list';
import styles from './dynamic-fields.module.css';

/* eslint-disable-next-line */
export interface DynamicFieldsProps {
  form: UseFormReturnType<MeasureFormValues>;
  fields: MeasureDynamicField[];

  fieldsLabel: string;
}

export function DynamicFields(props: DynamicFieldsProps) {
  const { t } = useTranslation(['analysis'], { useSuspense: false });

  const names = props.fields.map((field) => field.name);
  const validNames = names.filter((name) => props.form.values[name] !== null);

  const total = validNames.reduce((acc, name) => {
    const value = props.form.values[name];

    return acc + Number(value);
  }, 0);

  const average = (total / validNames.length).toFixed(2);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        {t('analysis:average')} : {average || '-'}{' '}
      </div>
      <MeasuresInputsList
        fields={props.fields}
        fieldsLabel={props.fieldsLabel}
        form={props.form}
        labelPlacement="floating"
      />
    </div>
  );
}

export default DynamicFields;
