import { IonButton } from '@ionic/react';
import { useFilters } from '@lidea/shared/data-access/core';
import { useFilterStore } from '@lidea/shared/data-access/core';
import { usePlot, useUpdatePlot } from '@lidea/shared/data-access/plots';
import {
  usePlots,
  usePlotsInBounds,
  usePlotsMaxBounds,
} from '@lidea/shared/data-access/plots';
import { MobilePage } from '@lidea/shared/ui/layout';
import {
  ClusterLayer,
  DrawControl,
  DrawControlToggle,
  GeolocateControl,
  PfMap,
  PlotsLayer,
  SatelliteMapControl,
  SatelliteMapModal,
  StyleControl,
} from '@lidea/shared/ui/map';
import {
  PlotFeatureCollection,
  PlotFormValues,
  PlotsClusterFeatureCollection,
} from '@lidea/shared/util/types';
import { notifications } from '@mantine/notifications';
import { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import { bbox } from '@turf/turf';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl';
import { useHistory, useParams } from 'react-router-dom';
import { stringify } from 'wellknown';

import styles from './edit-plot.module.css';

const PLOTS_LAYER_ID = 'plots-layer';
const CLUSTER_LAYER_ID = 'cluster-layer';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/* eslint-disable-next-line */
export interface EditPlotProps {}

export function EditPlot(props: EditPlotProps) {
  const { t } = useTranslation(['common', 'plot', 'map'], {
    useSuspense: false,
  });
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const mapRef = useRef<MapRef>(null);
  const [mapKey, setMapKey] = useState(0);
  const [isCheckedDraw, setIsCheckedDraw] = useState(false);
  const [isCheckedMap, setIsCheckedMap] = useState(false);
  const [imageryIndex, setImageryIndex] = useState('');
  const { filtersAsOptions } = useFilters();
  const plot = usePlot(Number(id));
  const updatePlot = useUpdatePlot();
  const [mapLoaded, setMapLoaded] = useState(false);

  const [line_string, setLine] =
    useState<GeoJSON.Feature<GeoJSON.LineString> | null>(null);

  const [mapBounds, setMapBounds] = useState<mapboxgl.LngLatBounds | null>(
    null
  );
  const [polygon, setPolygon] =
    useState<GeoJSON.Feature<GeoJSON.Polygon> | null>(null);

  const plotsMaxBounds = usePlotsMaxBounds();
  const [mapZoom, setMapZoom] = useState(0);
  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const { plotsAsOptions, ...plots } = usePlots({
    selectedFilters,
    page: 1,
  });

  const [checkboxValues, setCheckboxValues] = useState<Record<number, boolean>>(
    {}
  );

  const plotsInBounds = usePlotsInBounds({
    selectedFilters,
    bounds: mapBounds,
    cluster: mapZoom < 9,
    zoom: mapZoom,
    type: imageryIndex,
    enabled: !isCheckedDraw,
  });

  const handleDrawChange = (event: CustomEvent) => {
    setIsCheckedDraw(event.detail.checked);
  };

  const handleMapChange = (event: CustomEvent) => {
    setIsCheckedMap(event.detail.checked);
  };

  const handleCheckboxChange = (plotId: number) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [plotId]: !prevValues[plotId],
    }));
  };

  const handleMapIdle = (e: mapboxgl.MapboxEvent) => {
    setMapBounds(e.target.getBounds());
    setMapZoom(e.target.getZoom());
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const handleTypeMap = (value: string) => {
    setImageryIndex(value);
  };

  const formsInitialValues = useMemo(() => {
    if (!plot.data) {
      return null;
    }
    const {
      area,
      protocol,
      analyzes,
      estimatedYield,
      objYield,
      percentYield,
      is_merged,
      active,
      ...fields
    } = plot.data.properties;

    const fieldsValues: PlotFormValues = (
      Object.entries(fields) as Entries<typeof fields>
    ).reduce((acc, [key, value]) => {
      switch (key) {
        case 'name':
        case 'color': {
          return { ...acc, [key]: value };
        }
        default: {
          return { ...acc, [key]: value.id };
        }
      }
    }, {} as PlotFormValues);

    return { plot: fieldsValues, protocol };
  }, [plot]);

  const plotFormFields = useMemo(() => {
    if (!filtersAsOptions) {
      return null;
    }
    const { geosector, country, country_manager, ...fields } = filtersAsOptions;
    return fields;
  }, [filtersAsOptions]);

  const handleUpdatePolygon = (e: DrawUpdateEvent) => {
    if (e.features[0].geometry.type === 'MultiPolygon') {
      setPolygon(e.features[0] as GeoJSON.Feature<GeoJSON.Polygon>);
    }
    if (e.features[0].geometry.type === 'LineString') {
      setLine(e.features[0] as GeoJSON.Feature<GeoJSON.LineString>);
    }
  };

  const handleCreateLine = (e: DrawCreateEvent) => {
    if (e.features[0].geometry.type === 'LineString') {
      if (line_string) {
        setLine(e.features[0] as GeoJSON.Feature<GeoJSON.LineString>);
      } else {
        setLine(e.features[0] as GeoJSON.Feature<GeoJSON.LineString>);
      }
    }
  };

  const handleUpdatePlotPolygon = (e: mapboxgl.MapboxEvent) => {
    if (polygon) {
      const border = stringify(polygon as any);
      updatePlot.mutate({
        plotId: Number(id),
        data: { border },
      });
      notifications.show({
        color: 'green',
        message: t('plot:modified_polygon'),
      });
      setPolygon(null);
      setIsCheckedDraw(false);
    }
    if (line_string) {
      const line = stringify(line_string as any);
      updatePlot.mutate({
        plotId: Number(id),
        data: { line },
      });
      notifications.show({
        color: 'green',
        message: t('plot:modified_line'),
      });
      setLine(null);
      setIsCheckedDraw(false);
    }
    if (polygon && line_string) {
      const border = stringify(polygon as any);
      const line = stringify(line_string as any);
      updatePlot.mutate({
        plotId: Number(id),
        data: { border, line },
      });
      notifications.show({
        color: 'green',
        message: t('plot:modified_polygon_line'),
      });
      setPolygon(null);
      setLine(null);
      setIsCheckedDraw(false);
      setMapBounds(e.target.getBounds());
    }
  };

  const handleMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const feature = e.features?.[0];

    if (!isCheckedDraw) {
      handleCheckboxChange(feature?.id as number);
    }
    if (feature && feature.id && !isCheckedDraw) {
      history.push(`/search/plots/${feature.id}/edit`);
    }

    switch (feature?.layer.id) {
      case CLUSTER_LAYER_ID:
      case `${CLUSTER_LAYER_ID}_points`: {
        mapRef.current
          ?.getMap()
          .fitBounds(JSON.parse(feature.properties?.bbox) as any, {
            padding: 50,
            maxZoom: 30,
            animate: false,
          });
        break;
      }
    }
  };

  const [hasMapBeenFitted, setHasMapBeenFitted] = useState(false);

  // const [presentSatMap, dismissSatMap] = useIonModal(SatelliteMapModal, {
  //   clusterData: plotsInBounds.isSuccess ? mapZoom : undefined,
  //   colorbar: colorbar,
  //   infoPlot: infoPlot,
  //   numberOfPlots: numberOfPlots,
  //   selectedPlot: checkboxValues,
  //   selectedValue: selectedValue,
  //   setSelectedPlot: resetSelectedPlot,
  //   setSelectedValue: setSelectedValue,
  //   onCheckboxChange: handleCheckboxChange,
  //   eyeOff: true,
  //   isHidden: isHidden,
  //   setIsHidden: handleVisibleView,
  //   title: t('plot:satellite_map'),
  //   dismiss: () => dismissSatMap(),
  // });

  useEffect(() => {
    if (plot.data && mapRef.current && !hasMapBeenFitted) {
      const [x1, y1, x2, y2] = bbox(plot.data);

      mapRef.current.fitBounds([x1, y1, x2, y2], {
        animate: false,
        padding: 50,
      });
      setHasMapBeenFitted(true);
    }
  }, [plot, plot.data, hasMapBeenFitted]);

  useEffect(() => {
    if (plotsMaxBounds.data && mapLoaded && id === 'undefined') {
      mapRef.current?.getMap().fitBounds(plotsMaxBounds.data.bbox as any, {
        padding: 100,
        maxZoom: 20,
        animate: false,
      });
    }
  }, [mapLoaded, plotsMaxBounds.data]);

  useEffect(() => {
    const m = mapRef.current?.getMap();
    if (isCheckedMap) {
      m?.setStyle('mapbox://styles/mapbox/outdoors-v12');
    } else {
      m?.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
    }
  }, [isCheckedMap]);

  return (
    <MobilePage backHref={`/search`}>
      <p style={{ textAlign: 'center', alignItems: 'center' }}>
        {t('plot:click_edit')}
      </p>
      <h5 style={{ textAlign: 'center', alignItems: 'center' }}>
        {plot.data?.properties.name}
      </h5>

      <PfMap
        key={mapKey}
        ref={mapRef}
        interactiveLayerIds={[
          PLOTS_LAYER_ID,
          CLUSTER_LAYER_ID,
          `${CLUSTER_LAYER_ID}_points`,
        ]}
        onClick={handleMapClick}
        onIdle={handleMapIdle}
        onLoad={handleMapLoad}
        // TODO: remove this when we extract satmap button in a control component
        id="edit-plot-map"
      >
        <GeolocateControl position="top-right" />

        {mapZoom > 9 &&
          plotsInBounds.data?.plots?.features?.some(
            (feature) =>
              feature.properties?.name && feature.properties.name.trim() !== ''
          ) && (
            <>
              <StyleControl />
              <DrawControlToggle
                position="top-left"
                setIsChecked={setIsCheckedDraw}
              />

              {isCheckedDraw && (
                <DrawControl
                  controls={{ trash: false, polygon: false, line_string: true }}
                  initialPolygon={plot.data}
                  limit={2}
                  onCreate={handleCreateLine}
                  onUpdate={handleUpdatePolygon}
                />
              )}

              <SatelliteMapControl
                checkboxValues={checkboxValues}
                imageryIndex={imageryIndex}
                plotsInBounds={plotsInBounds.data}
                position="top-left"
                setImageryIndex={handleTypeMap}
                onCheckboxChange={handleCheckboxChange}
              />
            </>
          )}

        {plotsInBounds.isSuccess ? (
          <PlotsLayer
            colorPolygon="#E0E0E0"
            data={plotsInBounds.data.plots as unknown as PlotFeatureCollection}
            id={PLOTS_LAYER_ID}
            minZoom={9}
          />
        ) : null}

        {plotsInBounds.isSuccess ? (
          <ClusterLayer
            clusterColor="#ED7D31"
            clustersMaxZoom={9}
            data={plotsInBounds.data as PlotsClusterFeatureCollection}
            layerId={CLUSTER_LAYER_ID}
          />
        ) : null}

        <div className={styles['saveButton']}>
          {(polygon !== null || line_string !== null) && (
            <IonButton onClick={handleUpdatePlotPolygon}>
              {t('common:save_boundaries')}
            </IonButton>
          )}
        </div>
      </PfMap>
    </MobilePage>
  );
}

export default EditPlot;
