import { IonItem, IonLabel } from '@ionic/react';
import { YieldAnalysis, ZoningStatus } from '@lidea/shared/util/types';
import { useTranslation } from 'react-i18next';

interface SatelliteItemProps {
  plotId: number;
  yieldAnalysis?: YieldAnalysis;
}

const SatelliteItem = (props: SatelliteItemProps) => {
  const { t } = useTranslation(['plot'], { useSuspense: false });

  return (
    <IonItem
      color={
        props.yieldAnalysis?.status === ZoningStatus.ToBeDone
          ? 'danger'
          : 'success'
      }
      detail={true}
      routerLink={`/search/plots/${props.plotId}/analysis/${props.yieldAnalysis?.id}/zoning`}
      style={{
        marginInline: 10,
        borderRadius: 12,
        overflow: 'hidden',
      }}
      button
    >
      <IonLabel>
        <h2 style={{ fontSize: '1.25rem', textTransform: 'uppercase' }}>
          {t('plot:satellite_map')}
        </h2>
      </IonLabel>
    </IonItem>
  );
};

export default SatelliteItem;
