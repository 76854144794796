import { SelectedFilters } from '@lidea/shared/data-access/core';

export const plotKeys = {
  all: () => ['plots'],
  maxBounds: () => [...plotKeys.all(), 'maxBounds'],
  searches: () => [...plotKeys.all(), 'search'],
  search: (filters: SelectedFilters) => [...plotKeys.searches(), filters],
  paginated: ({
    page,
    filters,
    sort,
  }: {
    page: number;
    filters: SelectedFilters;
    sort?: string;
  }) => [
    ...plotKeys.search(filters),
    'paginated',
    {
      page,
      sort,
    },
    ],
  inBound: () => [...plotKeys.all(), 'inBounds'],
  inBounds: (
    bounds: mapboxgl.LngLatBoundsLike,
    cluster: boolean,
    filters: SelectedFilters,
    zoom: number,
    type?: string
  ) => [...plotKeys.inBound(), { bounds, cluster, filters, zoom, type }],
  details: () => [...plotKeys.all(), 'detail'],
  detail: (id: number) => [...plotKeys.details(), id],
  yieldMap: (id: number) => [...plotKeys.detail(id), 'yieldMap'],
  analysisStatuses: (id: number) => [...plotKeys.all(), 'analysisStatuses', id],
  canMerge: (ids: number[]) => [...plotKeys.all(), 'canMerge', ids],
  synthesis: (ids: number[]) => [...plotKeys.all(), 'synthesis', ids],
};
