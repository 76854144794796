import { getAccessToken } from '@lidea/shared/util/local-storage';
import ky from 'ky';

export const publicClient = ky.extend({
  prefixUrl:
    typeof process !== 'undefined'
      ? process.env['NEXT_PUBLIC_API_URL'] || process.env['NX_API_URL']
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - import is used by vite
        import.meta.env.VITE_API_URL,
});

export const privateClient = publicClient.extend({
  timeout: 180000, // 3 minutes
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = getAccessToken();

        if (accessToken) {
          request.headers.set('Authorization', `Token ${accessToken}`);
        }
      },
    ],
  },
});
