import { IonIcon } from '@ionic/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ModalPage } from '@lidea/shared/ui/layout';
import { airplaneOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface HowToModalProps {
  dismiss: () => void;
}

const HowToModal = (props: HowToModalProps) => {
  const { t } = useTranslation(['common', 'plot'], {
    useSuspense: false,
  });

  return (
    <ModalPage
      title={t('common:offline_select_parcel_message_title')}
      onClose={props.dismiss}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '10px',
        }}
      >
        <IonIcon
          aria-label="change boundarie"
          icon={airplaneOutline}
          size="large"
          slot="icon-only"
        />
      </div>
      <div>
        <p
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingBottom: '10px',
            lineHeight: '1.4',
          }}
        >
          {t('common:offline_select_parcel_message1')}
        </p>
        <p
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingBottom: '10px',
            lineHeight: '1.4',
          }}
        >
          {t('common:offline_select_parcel_message2')}
        </p>

        <p
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingBottom: '10px',
            lineHeight: '1.4',
          }}
        >
          {t('common:offline_select_parcel_message3')}
        </p>

        <ul
          style={{
            textAlign: 'left',
            fontSize: '18px',
            paddingRight: '10px',
            lineHeight: '1.4',
          }}
        >
          <li>{t('common:offline_step1')}</li>
          <li>{t('common:offline_step2')}</li>
          <li>{t('common:offline_step3')}</li>
          <li>{t('common:offline_step4')}</li>
          <li>{t('common:offline_step5')}</li>
          <li>{t('common:offline_step6')}</li>
        </ul>
      </div>
    </ModalPage>
  );
};

export default HowToModal;
