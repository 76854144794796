import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { zoningApi } from '../../apis/zoning-api';
import { heatmapKeys } from '../../keys/heatmapKeys';

export type useHeatmapsProps = {
  plotId: number;
  imageryIndex?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = import.meta.env.VITE_API_ROOT;

export const heatmapQueryOptions = (args: useHeatmapsProps) => {
  return {
    queryKey: heatmapKeys.plotHeatmaps(args),
    queryFn: () =>
      zoningApi.getHeatmaps({
        plotId: args.plotId,
        imageryIndex: args.imageryIndex || '',
      }),
  };
};

export function useHeatmaps(props: useHeatmapsProps) {
  const [selectedHeatmap, setSelectedHeatmap] = useState<string>();
  const heatmaps = useQuery({
    ...heatmapQueryOptions(props),
    keepPreviousData: true,
    enabled: !!props.imageryIndex,
  });

  const selectedHeatmapFeature = useMemo(() => {
    if (!heatmaps.data) return;

    return heatmaps.data.find((image) => image.date === selectedHeatmap);
  }, [heatmaps.data, selectedHeatmap]);

  const heatmapsAsOptions = useMemo(() => {
    if (!heatmaps.data) return [];

    return heatmaps.data?.map((feature) => ({
      value: feature.date,
      valueLabel: feature.date_label,
      src: root + feature.affine,
    }));
  }, [heatmaps.data]);

  const colorBar = useMemo(() => {
    if (!heatmaps.data) return [];

    return heatmaps.data?.map((feature) => ({
      legend: feature.colorbar,
    }));
  }, [heatmaps.data]);

  return {
    heatmaps,
    heatmapsAsOptions,
    selectedHeatmap,
    setSelectedHeatmap,
    selectedHeatmapFeature,
    colorBar,
  };
}

export default useHeatmaps;
