// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { PfMap } from '@lidea/shared/ui/map';
import { PlotFeatureCollection } from '@lidea/shared/util/types';
import { bbox } from '@turf/turf';
import { useCallback, useEffect, useState } from 'react';
import { MapboxEvent } from 'react-map-gl';

/* eslint-disable-next-line */
export interface Step2Props {
  plots: PlotFeatureCollection['plots'];
  onFinished: () => void;
}

export function Step2(props: Step2Props) {
  const [done, setDone] = useState(false);

  const onMapLoad = useCallback(
    (e: MapboxEvent) => {
      props.plots.features.forEach((plot, index) => {
        setTimeout(() => {
          const [x1, y1, x2, y2] = bbox(plot);

          e.target.fitBounds([x1, y1, x2, y2], { padding: 50, animate: false });
          if (index === props.plots.features.length - 1) {
            setTimeout(() => {
              setDone(true);
            }, 3000);
          }
        }, index * 3000);
      });
    },
    [props.plots.features]
  );

  useEffect(() => {
    if (done) {
      props.onFinished();
    }
  }, [done, props]);

  return <PfMap onLoad={onMapLoad} />;
}

export default Step2;
