import { useControl } from 'react-map-gl';

import MapboxGeolocateControl from './mapbox-geolocate-control';

/* eslint-disable-next-line */
export interface GeolocateControlProps {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export function GeolocateControl(props: GeolocateControlProps) {
  useControl(() => new MapboxGeolocateControl(), {
    position: props.position,
  });

  return null;
}

export default GeolocateControl;
