import { IonButton, IonIcon } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

import styles from './prev-next-buttons.module.css';

/* eslint-disable-next-line */
export interface PrevNextButtonsProps {
  onPrev?: () => void;
  onNext?: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
}

export function PrevNextButtons(props: PrevNextButtonsProps) {
  return (
    <div className={styles['container']}>
      <IonButton
        disabled={props.prevDisabled}
        fill="clear"
        style={{ color: 'black' }}
        onClick={props.onPrev}
      >
        <IonIcon icon={chevronBackOutline} slot="icon-only" />
      </IonButton>
      <IonButton
        disabled={props.nextDisabled}
        fill="clear"
        style={{ color: 'black' }}
        onClick={props.onNext}
      >
        <IonIcon icon={chevronForwardOutline} slot="icon-only" />
      </IonButton>
    </div>
  );
}

export default PrevNextButtons;
