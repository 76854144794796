import {
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { PfTypeahead } from '@lidea/search/shared/ui-pf-typeahead';
import { useDisclosure, useMediaQuery, useUncontrolled } from '@mantine/hooks';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './pf-select.module.css';

type IonSelectProps = React.ComponentProps<typeof IonSelect>;

const POPOVER_OPTIONS_LIMIT = 6;

/* eslint-disable-next-line */
export interface PfSelectProps
  extends Omit<IonSelectProps, 'onChange' | 'labelPlacement'> {
  options?: { value: string | number | boolean; label: string }[];
  onChange?: (value: string) => void;
  error?: string;
  wrapperClassName?: string;
  ariaLabel?: string;
}

export function PfSelect(props: PfSelectProps) {
  const { value, defaultValue, onChange, label, ariaLabel, ...selectProps } = props;
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });
  const [opened, handlers] = useDisclosure();
  const [popoverRemoved, setPopoverRemoved] = useState(false);
  const matches = useMediaQuery('(min-width: 48em)');

  const handleSelectClick = () => {
    if (
      props.options?.length &&
      props.options?.length > POPOVER_OPTIONS_LIMIT
    ) {
      handlers.open();
    }
  };

  const handleTypeheadChange = (items: string[]) => {
    handleChange(items[0]);
    handlers.close();
  };

  useEffect(() => {
    // We set popoverRemoved to true when removed because
    // we would enter an infinite loop when reoppening the select
    if (opened && !popoverRemoved) {
      // on mobile device an alert is shown instead of a popover
      const selector = matches ? '.select-popover' : '.select-alert';
      // recursive call to wait for popover to be rendered and remove element
      const hideSelectPopover = () => {
        if (document.querySelector(selector)) {
          document.querySelector(selector)?.remove();
          setPopoverRemoved(true);
        } else {
          setTimeout(hideSelectPopover, 100);
        } 
      };

      hideSelectPopover();
    }
  }, [matches, opened, popoverRemoved]);

  return (
    <>
      <IonItem
        className={classNames(props.wrapperClassName, styles['ion-item'])}
        lines="none"
      >
        {label ? <IonLabel position="stacked">{label}</IonLabel> : null}
        <IonSelect
          // color={props.error ? 'danger' : undefined}
          className={classNames(styles['ion-select'], {
            [styles['ion-select--error']]: props.error,
            [styles['ion-select--typeahead']]: opened,
          })}
          interface={matches ? 'popover' : 'alert'}
          value={_value}
          onIonChange={(e) => {
            handleChange(e.detail.value);
          }}
          {...selectProps}
          aria-label={label || ariaLabel}
          labelPlacement="stacked"
          onClick={handleSelectClick}
        >
          {props.options?.length
            ? props.options?.map((option) => (
                <IonSelectOption key={`${option.value}`} value={option.value}>
                  {option.label}
                </IonSelectOption>
              ))
            : null}
        </IonSelect>
        {props.error && (
          <div className={styles['ion-select--error']}>{props.error}</div>
        )}
      </IonItem>

      <IonModal isOpen={opened} onWillDismiss={handlers.close}>
        <PfTypeahead
          items={(props.options as any) || []}
          limit={1}
          selectedItems={[]}
          title={label}
          onSelectionCancel={handlers.close}
          onSelectionChange={handleTypeheadChange}
        />
      </IonModal>
    </>
  );
}

export default PfSelect;
