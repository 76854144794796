import { FallbackRender } from '@sentry/react';
import React, { Component, ErrorInfo } from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  goToMap = () => {
    window.location.href = '/map';
  };

  copyToClipboard = () => {
    const { error, errorInfo } = this.state;
    const errorText = `Error: ${error?.message}\n\nStack Trace:\n${errorInfo?.componentStack}`;
    navigator.clipboard.writeText(errorText).then(() => {
      console.log('Error copied to clipboard');
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.wrapper}>
          <div style={styles.container}>
            <h1 style={styles.header}>Something went wrong.</h1>
            <h5 style={styles.message}>{this.state.error?.message}</h5>
            <details style={styles.details} open>
              <summary>Stack Trace</summary>
              <pre style={styles.pre}>
                {this.state.errorInfo?.componentStack}
              </pre>
            </details>
            <div style={styles.buttonContainer}>
              <button style={styles.button} onClick={this.goToMap}>
                Go to map
              </button>
              <button style={styles.button} onClick={this.copyToClipboard}>
                Copy to Clipboard
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundaryComponent: FallbackRender = ({
  error,
  componentStack,
  resetError,
}) => {
  const goToMap = () => {
    resetError();
    window.location.href = '/map';
  };

  const copyToClipboard = () => {
    const errorText = `Error: ${error?.message}\n\nStack Trace:\n${componentStack}`;
    navigator.clipboard.writeText(errorText).then(() => {
      console.log('Error copied to clipboard');
    });
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <h1 style={styles.header}>Something went wrong.</h1>
        <h5 style={styles.message}>{error?.message}</h5>
        <details style={styles.details} open>
          <summary>Stack Trace</summary>
          <pre style={styles.pre}>{componentStack}</pre>
        </details>
        <div style={styles.buttonContainer}>
          <button style={styles.button} onClick={goToMap}>
            Go to map
          </button>
          <button style={styles.button} onClick={copyToClipboard}>
            Copy to Clipboard
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8d7da',
  },
  container: {
    padding: '20px',
    border: '1px solid red',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    color: '#721c24',
    fontFamily: 'Arial, sans-serif',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '90%',
    maxHeight: '90%',
    overflow: 'auto',
  },
  header: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  details: {
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f1f1f1',
    padding: '10px',
    borderRadius: '5px',
  },
  pre: {
    margin: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#FF0000',
    color: '#ffffff',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#FF3333',
  },
};

export default ErrorBoundary;
